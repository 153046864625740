import React, { useState, useEffect, useRef } from "react";
import store from "store";
import { withRouter } from "react-router";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
} from "@material-ui/core";

import http from "service/http";

import useLoading from "../loading/hook";

import ModalHeader from "./ModalHeader";

import queryString from "query-string";

import "./Styles.css";

import SLOTS from "./constant";

import { useDispatch } from "react-redux";

import { showLoginModal } from "../../store/modalSlice";

import GameAccount from "../../component/gameAccount";

import { PropagateLoader } from "react-spinners";

import { isEmpty } from "ramda";

import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    20: `evo888android://lobbyevoandroid?account=${account}&password=${password}`,
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: "",
    2: "",
    1: "",
  };

  return proxy[id];
};

function Header({ history, classes, title, rightSidebar = false }) {
  const {
    location: { search, pathname },
  } = history;

  const page = pathname ? pathname.toLowerCase() : '';

  const dispatch = useDispatch();

  const user = store.get("user");

  const [isShowMenu, setShowMenu] = useState(false);

  const myRef = useRef();

  const [loading, withLoading] = useLoading(false);

  const { game } = queryString.parse(search);

  const [gameSelected, updateGameSelected] = React.useState({});

  const [games, setGames] = React.useState([]);

  const { user_id: userId } = store.get("user") || { user_id: undefined };

  const gamesSetup = async () => {
    if (!userId) return;
    const gameList = await http.get({ path: "games" });
    setGames(gameList);
    return gameList;
  };

  const loginToGame = async (gameInfo, gameList = null) => {
    if (!userId) {
      alert("Please Login!");
      return;
    }
    const { src, label, route, id, redirect, target } = gameInfo;
    if (target === "_blank") {
      console.log("route", route);
      window.open(route);
    }
    if (redirect) {
      history.push(route);
      return;
    }
    console.log(
      id,
      games.find(({ id: gameId }) => id === gameId)
    );
    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    updateGameSelected({ label, src, route, id });
    const {
      balance,
      account,
      err,
      game_password: password,
    } = await withLoading(() =>
      http
        .post({
          path: `users/${userId}/game/logout`,
          payload: {
            gameLoginId: id,
          },
        })
        .catch((error) => {
          if (error.message !== "Not enough money !") {
            throw new Error({ message: error.message });
          }
          return true;
        })
        // .then(() => deeplink || [10,4, 14].includes(id) ? true : http.get({ path: `games/${id}/login` }))
        .then(() => http.get({ path: `users/${userId}/games/${id}/balance` }))
    ).catch((error) => {
      return { err: error.message };
    });

    const outDeeplink = generateDeeplink({ id, account, password });

    updateGameSelected({
      label,
      src,
      route,
      id,
      balance: balance ? balance.balance : 0,
      account: balance ? balance.account : "",
      err,
      deeplink: outDeeplink,
      password,
    });
    if (!outDeeplink && outDeeplink !== "") history.push(route);
  };

  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` });
    }
    async function fetchGame() {
      const gameList = await gamesSetup();
      // console.log(gameList)
      if (game) {
        const gameInfo = SLOTS.find((x) => x.code === game);
        loginToGame(gameInfo, gameList);
      }
    }
    fetchGame();
  }, []);

  const handleClose = () => {
    updateGameSelected({});
  };

  const handleClickOutside = (e) => {
    if (myRef && myRef.current && !myRef.current.contains(e.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // countdonw
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const formatNumber = (number) => {
    return number < 10 ? "0" + number : number;
  };

  return (
    <>
      <section className="header-sec">
        <div className="header-top">
          <div className="header-top-container">
            <button
              className="mobile-menu-btn cursor-pointer"
              data-toggle="modal"
              data-target="#mobile-menu"
              onClick={() => setShowMenu(!isShowMenu)}
            >
              <img
                className="fas fa-bars"
                src="./images/icon/menu2.png"
                alt=""
              ></img>
            </button>
            <div className="">
              <a href="/">
                <img
                  className="new_logo"
                  loading="lazy"
                  src="./images/icon/Logo_Kiss.png"
                  alt=""
                />
              </a>
            </div>
            <div className="header-right">
              <div className="datetime-row">
                <div className="date-top">
                  <span id="dtNow">
                    {`${formatNumber(currentDateTime.getDate())}/${formatNumber(
                      currentDateTime.getMonth() + 1
                    )}/${currentDateTime.getFullYear()} ${formatNumber(
                      currentDateTime.getHours()
                    )}:${formatNumber(
                      currentDateTime.getMinutes()
                    )}:${formatNumber(currentDateTime.getSeconds())}`}
                  </span>
                </div>

                {user && (
                  <div
                    className="cursor-pointer d-inline-flex ml-12"
                    onClick={() => {
                      store.clearAll();
                      history.push("/");
                    }}
                  >
                    Logout
                  </div>
                )}
              </div>
              <div className="btn-top">
                {user ? (
                  <>
                    <div className="userwelcome-wrap">
                      <button className="mail-btn-top" onClick={() => {}}>
                        <span
                          className="mail-redot"
                          style={{ display: "none" }}
                        ></span>
                        <img
                          className="w-24 h-24"
                          src="./images/home/email-outline.png"
                          alt="use"
                        />
                      </button>
                      <div className="welcome-user">
                        <a href="/account" style={{ color: "#ffe27f" }}>
                          <img
                            className="w-24 h-24"
                            src="./images/header/user.svg"
                            alt="use"
                          />
                          <span className="text-user">
                            {" "}
                            {user.user_account_no}
                          </span>
                        </a>
                      </div>
                    </div>
                    <button
                      className="join-btn"
                      onClick={() => history.push("/deposit")}
                    >
                      Wallet
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="join-btn cursor-pointer"
                      onClick={() => history.push("/register")}
                    >
                      Join Now
                    </button>
                    <button
                      className="login-btn  cursor-pointer"
                      data-toggle="modal"
                      data-target="#login"
                      onClick={() => dispatch(showLoginModal())}
                    >
                      Login
                    </button>
                  </>
                )}
                <div className="dropdown language">
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src="./images/home/EN.png"
                    alt="en"
                  />
                </div>
              </div>
            </div>
            <div className="mobile-mail-top">
              <button className="mail-btn-top" onClick={() => {}}>
                <span className="mail-redot" style={{ display: "none" }}></span>
                <img
                  className="w-24 h-24"
                  src="./images/home/email-outline.png"
                  alt="use"
                />
              </button>
            </div>
          </div>
        </div>

        <div className="header-btm">
          <div className="header-btm-container">
            <ul className="nav main-nav">
              <li className="nav-item">
                <a href="/" className="nav-link icon-link">
                  <img
                    className="w-24 h-24"
                    loading="lazy"
                    src="./images/icon/icon-home.svg"
                    alt=""
                  />
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/"
                  className="nav-link icon-link"
                  onClick={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                >
                  <img loading="lazy" src="./images/icon/iPhone.png" alt="" />
                </a>
              </li>
              <li className={page.includes('sports') ? "nav-item active" : 'nav-item' }>
                <a href="/sports" className={page.includes('sports') ? "nav-link active" : 'nav-link' }>
                  Sports
                </a>
                <div className="dropdown-container">
                  <div className="nav-dropdown-wrapper">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToGame(SLOTS.find((x) => x.id === 34));
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/sports/wbet.png"
                        alt=""
                      />
                      <div className="dname">M9Bet</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToGame(SLOTS.find((x) => x.id === 6));
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/sports/maxbet.png"
                        alt=""
                      />
                      <div className="dname">Maxbet</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToGame(SLOTS.find((x) => x.id === 27));
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/sports/sbobet.png"
                        alt=""
                      />
                      <div className="dname">SBOBET</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToGame(SLOTS.find((x) => x.id === 9));
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/sports/cmd.png"
                        alt=""
                      />
                      <div className="dname">CMD368</div>
                    </a>
                    {/* <a
                      href="/"
                      onClick={() => {
                        loginToGame(SLOTS.find((x) => x.id === 29));
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/sports/fb.png"
                        alt="/"
                      />
                      <div className="dname">CMD368</div>
                    </a> */}
                  </div>
                </div>
              </li>
              <li className={page.includes('casino') ? "nav-item active" : 'nav-item' }>
                <a href="/casino" className={page.includes('casino') ? "nav-link active" : 'nav-link' }>
                  Casino
                </a>
                <div className="dropdown-container">
                  <div className="nav-dropdown-wrapper">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToGame(SLOTS.find((x) => x.id === 15));
                      }}
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/evolution.png"
                        alt=""
                      />
                      <div className="dname">Evolution Gaming</div>
                    </a>

                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToGame(SLOTS.find((x) => x.id === 4));
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/playtech.png"
                        alt=""
                      />
                      <div className="dname">Playtech</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/pragmatic.png"
                        alt=""
                      />
                      <div className="dname">Pragmatic Play</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/vivo.png"
                        alt=""
                      />
                      <div className="dname">Vivo Gaming</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/allbet.png"
                        alt=""
                      />
                      <div className="dname">Allbet</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/gameplay.png"
                        alt=""
                      />
                      <div className="dname">Gameplay</div>
                    </a>

                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/pretty.png"
                        alt=""
                      />
                      <div className="dname">Pretty Gaming</div>
                    </a>
                    {/* <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        loginToGame(SLOTS.find((x) => x.id === 33));
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/wmcasino.png"
                        alt=""
                      />
                      <div className="dname">WM Casino</div>
                    </a> */}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/biggaming.png"
                        alt=""
                      />
                      <div className="dname">Big Gaming</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/dreamgaming.png"
                        alt=""
                      />
                      <div className="dname">Dream Gaming</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/microgaming.png"
                        alt=""
                      />
                      <div className="dname">Mircogaming</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/worldentertainment.png"
                        alt=""
                      />
                      <div className="dname">World Entertainment</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                      className="ml-4"
                    >
                      <img
                        loading="lazy"
                        className="w-auto"
                        src="images/dropdown/casino/sexy.png"
                        alt=""
                      />
                      <div className="dname">Sexy Baccarat</div>
                    </a>
                  </div>
                </div>
              </li>
              <li className={page.includes('slot') ? "nav-item active" : 'nav-item' }>
                <a href="/slot" className={page.includes('slot') ? "nav-link active" : 'nav-link' }>
                  Slots
                </a>
                <div className="dropdown-container">
                  <div className="nav-dropdown-wrapper">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/playtech.png"
                        alt=""
                      />
                      <div className="dname">Playtech</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/pragmatic.png"
                        alt=""
                      />
                      <div className="dname">Pragmatic Play</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <div className="hot-icon">Hot</div>
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/live22.png"
                        alt=""
                      />
                      <div className="dname">Live22</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <div className="hot-icon">Hot</div>
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/uuslots.png"
                        alt=""
                      />
                      <div className="dname">UU Slots</div>
                    </a>
                    {/* <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <div className="hot-icon">Hot</div>
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/megah5.png"
                        alt=""
                      />
                      <div className="dname">MEGA H5</div>
                    </a> */}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/cq9.png"
                        alt=""
                      />
                      <div className="dname">CQ9</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/jdb.png"
                        alt=""
                      />
                      <div className="dname">JDB</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/lgd.png"
                        alt=""
                      />
                      <div className="dname">LGD Gaming</div>
                    </a>
                    {/* <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/worldmatch.png"
                        alt=""
                      />
                      <div className="dname">WorldMatch</div>
                    </a> */}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/playstar.png"
                        alt=""
                      />
                      <div className="dname">Playstar</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/qtech.png"
                        alt=""
                      />
                      <div className="dname">Qtech</div>
                    </a>

                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/netent.png"
                        alt=""
                      />
                      <div className="dname">Netent</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/gameplay.png"
                        alt=""
                      />
                      <div className="dname">Gameplay</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/asiagaming.png"
                        alt=""
                      />
                      <div className="dname">AsiaGaming</div>
                    </a>

                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/genesis.png"
                        alt=""
                      />
                      <div className="dname">Genesis</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/simpleplay.png"
                        alt=""
                      />
                      <div className="dname">Simpleplay</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/gameart.png"
                        alt=""
                      />
                      <div className="dname">GameArt</div>
                    </a>

                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/playngo.png"
                        alt=""
                      />
                      <div className="dname">PlaynGo</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/jili.png"
                        alt=""
                      />
                      <div className="dname">Jili</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/hc-game.png"
                        alt=""
                      />
                      <div className="dname">HC Slot</div>
                    </a>

                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/mircogaming.png"
                        alt=""
                      />
                      <div className="dname">Microgaming</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/nextspin.png"
                        alt=""
                      />
                      <div className="dname">Nextspin</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/spadegaming.png"
                        alt=""
                      />
                      <div className="dname">Spadegaming</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/joker.png"
                        alt=""
                      />
                      <div className="dname">Joker</div>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Comming Soon");
                      }}
                    >
                      <img
                        loading="lazy"
                        src="images/dropdown/slots/wow.png"
                        alt=""
                      />
                      <div className="dname">Wow Gaming</div>
                    </a>
                  </div>
                </div>
              </li>
              <li className={page.includes('lottery') ? "nav-item active" : 'nav-item' }>
                <a href="/lottery" className={page.includes('lottery') ? "nav-link active" : 'nav-link' }>
                  Lottery
                </a>
              </li>
              <li className="nav-item">
                <a href="/promos" className={page.includes('promos') ? "nav-link active" : 'nav-link' }>
                  Promos
                </a>
              </li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
            </ul>
          </div>
        </div>
      </section>
      {isShowMenu && (
        <ModalHeader
          isShowMenu={isShowMenu}
          history={history}
          onShowMenu={setShowMenu}
          promotion={user ? user.promotion : undefined}
        />
      )}
      <Dialog
        fullScreen
        open={!isEmpty(gameSelected)}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>{gameSelected.label}</Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            background: "#23232A",
            padding: 25,
            marginTop: 70,
            height: "calc(100vh - 70px)",
            overflow: "auto",
          }}
        >
          <Card
            style={{
              background: "none",
              border: "1px solid #ffaf50",
              borderRadius: "16px",
              maxWidth: "400px",
              margin: "0 auto",
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                className={classes.media}
                image={gameSelected.src}
                title="Contemplative Reptile"
              />
              <CardContent style={{ textAlign: "center" }}>
                {gameSelected.err ? (
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ fontWeight: "bold", color: "#ffaf50" }}
                  >
                    {gameSelected.err}
                  </Typography>
                ) : loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 15,
                    }}
                  >
                    <PropagateLoader
                      sizeUnit="px"
                      size={20}
                      color="#f50057"
                      loading={loading}
                    />
                  </div>
                ) : (
                  <GameAccount
                    account={gameSelected.account}
                    password={gameSelected.password}
                    deeplink={gameSelected.deeplink}
                    route={gameSelected.route}
                    balance={gameSelected.balance}
                  ></GameAccount>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Dialog>
    </>
  );
}
export default withRouter(Header);
