import React, { useCallback, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button,
} from "@material-ui/core";
import { PropagateLoader } from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";
import { isEmpty } from "ramda";
import { useTranslation } from "react-i18next";
import store from "store";
import http from "service/http";
import useLoading from "../loading/hook";
import styles from "./style";
import SLOTS from "./constant";
import queryString from "query-string";
import component from "component";
import moment from "moment";
import { batch, useDispatch } from "react-redux";
import { useForm } from "react-final-form-hooks/dist/react-final-form-hooks.cjs";
import formCreateInputs from "service/form/create";
import DatePicker from "react-datepicker";
import { showLoginModal } from "../../store/modalSlice";

const { useDialog } = component;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: "",
    7: "",
    2: "",
    1: "",
  };

  return proxy[id];
};

// const listLottery = [
//   {
//     image: "./images/home/lottovip.png",
//     class: "pages-icon-coming",
//   },
//   {
//     image: "./images/home/lottokissvip.png",
//     class: "pages-icon-hot",
//   },
// ];

function home({ history, classes }) {
  const {
    location: { search },
  } = history;

  const { game } = queryString.parse(search);

  const [loading, withLoading] = useLoading(false);

  const [gameResults, setGameResults] = useState({});

  const [gameSelected, updateGameSelected] = React.useState({});

  const [games, setGames] = React.useState([]);

  const [activeTab, setActiveTab] = React.useState("r1");

  const dispatch = useDispatch();

  const { user_id: userId } = store.get("user") || { user_id: undefined };

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: "Warning",
    btnLabel: "Got it",
    type: "error",
  });

  const getGameResults = useCallback(async (toDate) => {
    const data = await http.get({
      path: "admin/report/lottery_thai/getGameResults",
      params: { toDate },
    });
    setGameResults(data);
  });

  const onSubmit = ({ toDate }) => {
    try {
      getGameResults(moment(toDate).format("YYYY-MM-DD"));
    } catch (error) {
      throw error;
    }
  };

  const gamesSetup = async () => {
    const gameList = await http.get({ path: "games" });
    setGames(gameList);
    return gameList;
  };

  const loginToGame = async (gameInfo, gameList = null) => {
    if (!userId) {
      alert("Please Login!");
      return;
    }

    const { src, label, route, id, redirect, target } = gameInfo;
    if (target === "_blank") {
      window.open(route);
    }
    if (redirect) {
      history.push(route);
      return;
    }

    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    updateGameSelected({ label, src, route, id });
    const {
      balance,
      account,
      err,
      game_password: password,
      status,
      message,
    } = await withLoading(
      () =>
        http
          .post({
            path: `users/${userId}/game/logout`,
            payload: {
              gameLoginId: id,
            },
          })
          .catch((error) => {
            if (error.message !== "Not enough money !") {
              throw new Error({ message: error.message });
            }
            return true;
          })
      // .then(() => deeplink || [10].includes(id) ? true : http.get({ path: `games/${id}/login` }))
      // .then(() => http.get({ path: `users/${userId}/games/${id}/balance` }))
    ).catch((error) => {
      return { err: error.message };
    });

    const outDeeplink = generateDeeplink({ id, account, password });
    updateGameSelected({
      label,
      src,
      route,
      id,
      balance: balance.balance,
      account: balance.account,
      err,
      deeplink: outDeeplink,
      password,
    });
    if (status && status === 0)
      showDialogErrorWithMessage(
        message === "credit not enough" ? t("CREDIT_NOT_ENOUGH") : message
      );
    else if (label !== "NewWin" && !outDeeplink && outDeeplink !== "")
      history.push(route);
  };

  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` });
    }
    async function fetchGame() {
      const gameList = await gamesSetup();
      if (game) {
        const gameInfo = SLOTS.find((x) => x.code === game);
        loginToGame(gameInfo, gameList);
      }
    }

    batch(() => {
      fetchGame();
      getGameResults(moment().format("YYYY-MM-DD"));
    });
  }, []);

  const handleClose = () => {
    updateGameSelected({});
  };

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: {
      toDate: new Date(),
    },
  });

  const [toDate] = formCreateInputs(["toDate"], form);

  const { t } = useTranslation();
  return (
    <>
      <section className="lottery-sec">
        <div className="max-container">
          <div className="lottery-top">
            <div className="ltfloat deco-1">
              <img
                className="w-auto"
                alt=""
                src="images/lottery/crystal-1.png"
              />
            </div>
            <div className="ltfloat deco-2">
              <img
                className="w-auto"
                alt=""
                src="images/lottery/crystal-2.png"
              />
            </div>
            <div className="ltfloat deco-3">
              <img className="w-auto" alt="" src="images/lottery/ball-1.png" />
            </div>
            <div className="ltfloat deco-4">
              <img className="w-auto" alt="" src="images/lottery/ball-2.png" />
            </div>
          </div>
          <div className="lottery-top-game">
            <div className="lotto-top-wrap">
              {/* <div className="lotto-logo">
                <img className="w-auto" alt="" src="images/lottery/28win.png" />
              </div> */}
              <div className="lotto-game-option">
                <button
                  className="lott-btn active curs or-pointer"
                  onClick={() => loginToGame(SLOTS.find((x) => x.id === 32))}
                >
                  <img alt="" src="images/home/lottovip.png" />
                </button>
                <button
                  className="lott-btn active cursor-pointer"
                  onClick={() => loginToGame(SLOTS.find((x) => x.id === 12))}
                >
                  <img alt="" src="images/home/Kiss-Lottery-Logo.svg" />
                </button>
              </div>
            </div>
          </div>
          <div className="lottery-container">
            <form onSubmit={handleSubmit}>
              <div className="date-search-wrap" style={{ maxWidth: "170px" }}>
                <div className="rf-wrap">
                  <DatePicker
                    showIcon={true}
                    className="history-new-styled-inputdate"
                    onChange={(date) => {
                      toDate.input.onChange({
                        target: { value: date },
                      });
                    }}
                    selected={toDate.input.value}
                  />
                </div>
                <button type="submit" className="search-btn cursor-pointer">
                  <img
                    className="w-auto"
                    src="images/icon/search-black.png"
                    alt=""
                  ></img>
                </button>
              </div>
            </form>
            <div className="lotto-divider"></div>

            <div className="lottery-result-wrapper">
              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/magnum.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">Thai Lotto</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th>หมายเลขที่ชนะ</th>
                      </tr>
                      <tr>
                        <td>
                          {gameResults &&
                          gameResults["Thai Lotto"] &&
                          gameResults["Thai Lotto"].length > 0
                            ? gameResults["Thai Lotto"][0].result
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" className="rs-btm">
                    <tbody>
                      <tr>
                        <th width="50%">3 Top</th>
                        <th width="50%">2 Top</th>
                      </tr>
                      <tr>
                        <th width="50%">
                          {gameResults &&
                          gameResults["Thai Lotto"] &&
                          gameResults["Thai Lotto"].length > 0
                            ? gameResults["Thai Lotto"][0].top3
                            : "-"}
                        </th>
                        <th width="50%">
                          {gameResults &&
                          gameResults["Thai Lotto"] &&
                          gameResults["Thai Lotto"].length > 0
                            ? gameResults["Thai Lotto"][0].bottom2
                            : "-"}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/damacai.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">Hanoi lotto</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th>Giải ĐB</th>
                      </tr>
                      <tr>
                        <td>
                          {gameResults &&
                          gameResults["Hanoi Lotto"] &&
                          gameResults["Hanoi Lotto"].length > 0
                            ? gameResults["Hanoi Lotto"][0].result
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" className="rs-btm">
                    <tbody>
                      <tr>
                        <th>Giải nhất</th>
                      </tr>
                      <tr>
                        <td>
                          {gameResults &&
                          gameResults["Hanoi Lotto"] &&
                          gameResults["Hanoi Lotto"].length > 0
                            ? gameResults["Hanoi Lotto"][0].result2
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/toto.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">Vip lotto</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th>Giải ĐB</th>
                      </tr>
                      <tr>
                        <td>
                          {gameResults &&
                          gameResults["Hanoi VIP Lotto"] &&
                          gameResults["Hanoi VIP Lotto"].length > 0
                            ? gameResults["Hanoi VIP Lotto"][0].result
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" className="rs-btm">
                    <tbody>
                      <tr>
                        <th>Giải nhất</th>
                      </tr>
                      <tr>
                        <td>
                          {gameResults &&
                          gameResults["Hanoi VIP Lotto"] &&
                          gameResults["Hanoi VIP Lotto"].length > 0
                            ? gameResults["Hanoi VIP Lotto"][0].result2
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/singapore-4d.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">Laos lotto</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th>หมายเลขที่ชนะ</th>
                      </tr>
                      <tr>
                        <td>
                          {gameResults &&
                          gameResults["Laos Lotto"] &&
                          gameResults["Laos Lotto"].length > 0
                            ? gameResults["Laos Lotto"][0].result
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* 
              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/sabah88.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">Sabah 88</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th width="33.33%">1st Prize</th>
                        <th width="33.33%">2nd Prize</th>
                        <th width="33.33%">3rd Prize</th>
                      </tr>
                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" className="rs-btm">
                    <tbody>
                      <tr>
                        <th width="50%">Special</th>
                        <th width="50%">Consolation</th>
                      </tr>
                      <tr>
                        <td>
                          <div className="consol-wrap">
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                          </div>
                        </td>
                        <td>
                          <div className="consol-wrap">
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/cash-sweep.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">Cash Sweep</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th width="33.33%">1st Prize</th>
                        <th width="33.33%">2nd Prize</th>
                        <th width="33.33%">3rd Prize</th>
                      </tr>
                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" className="rs-btm">
                    <tbody>
                      <tr>
                        <th width="50%">Special</th>
                        <th width="50%">Consolation</th>
                      </tr>
                      <tr>
                        <td>
                          <div className="consol-wrap">
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                          </div>
                        </td>
                        <td>
                          <div className="consol-wrap">
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/stc.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">STC 4D</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th width="33.33%">1st Prize</th>
                        <th width="33.33%">2nd Prize</th>
                        <th width="33.33%">3rd Prize</th>
                      </tr>
                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" className="rs-btm">
                    <tbody>
                      <tr>
                        <th width="50%">Special</th>
                        <th width="50%">Consolation</th>
                      </tr>
                      <tr>
                        <td>
                          <div className="consol-wrap">
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                          </div>
                        </td>
                        <td>
                          <div className="consol-wrap">
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                            <div>-</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/gd-lotto.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">GD Lotto</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th width="33.33%">1st Prize</th>
                        <th width="33.33%">2nd Prize</th>
                        <th width="33.33%">3rd Prize</th>
                      </tr>
                      <tr>
                        <td>5359</td>
                        <td>7485</td>
                        <td>8761</td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" className="rs-btm">
                    <tbody>
                      <tr>
                        <th width="50%">Special</th>
                        <th width="50%">Consolation</th>
                      </tr>
                      <tr>
                        <td>
                          <div className="consol-wrap">
                            <div>1465</div>
                            <div>8077</div>
                            <div>6629</div>
                            <div>5757</div>
                            <div>1315</div>
                            <div>4234</div>
                            <div>3500</div>
                            <div>2687</div>
                            <div>7822</div>
                            <div>6392</div>
                          </div>
                        </td>
                        <td>
                          <div className="consol-wrap">
                            <div>9867</div>
                            <div>1325</div>
                            <div>0769</div>
                            <div>2150</div>
                            <div>7167</div>
                            <div>5280</div>
                            <div>8979</div>
                            <div>7589</div>
                            <div>0271</div>
                            <div>8986</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="result-box">
                <div className="lotto-top">
                  <div className="lotto-img">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/lottery/9lotto.png"
                    />
                  </div>
                  <div className="lotto-title">
                    <div className="lottitle-1">9Lotto</div>
                    <div className="lottitle-2">
                      {moment(
                        gameResults ? gameResults.date : new Date()
                      ).format("YYYY-MM-DD (ddd)")}
                    </div>
                  </div>
                </div>
                <div className="table-responsive rs-table">
                  <table width="100%" className="rs-top">
                    <tbody>
                      <tr>
                        <th width="33.33%">1st Prize</th>
                        <th width="33.33%">2nd Prize</th>
                        <th width="33.33%">3rd Prize</th>
                      </tr>
                      <tr>
                        <td>0584</td>
                        <td>6220</td>
                        <td>6203</td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" className="rs-btm">
                    <tbody>
                      <tr>
                        <th width="50%">Special</th>
                        <th width="50%">Consolation</th>
                      </tr>
                      <tr>
                        <td>
                          <div className="consol-wrap">
                            <div>5845</div>
                            <div>4032</div>
                            <div>2433</div>
                            <div>4577</div>
                            <div>0291</div>
                            <div>9984</div>
                            <div>6204</div>
                            <div>1115</div>
                            <div>6642</div>
                            <div>6398</div>
                          </div>
                        </td>
                        <td>
                          <div className="consol-wrap">
                            <div>7499</div>
                            <div>2417</div>
                            <div>7418</div>
                            <div>4002</div>
                            <div>3648</div>
                            <div>9031</div>
                            <div>0504</div>
                            <div>7610</div>
                            <div>7405</div>
                            <div>0376</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
            </div>

            <div className="lottery-mobile">
              <div className="list-toto">
                <ul
                  className="nav nav-tabs lt-wrap win28"
                  id="lottoTab"
                  role="tablist"
                >
                  <li className="lt-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("r1");
                      }}
                      href="#r1"
                    >
                      <img
                        className="w-auto"
                        alt=""
                        src="images/lottery/mb-magnum.png"
                      />
                    </a>
                  </li>
                  <li className="lt-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("r2");
                      }}
                      href="#r2"
                    >
                      <img
                        className="w-auto"
                        alt=""
                        src="images/lottery/mb-damacai.png"
                      />
                    </a>
                  </li>
                  <li className="lt-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("r3");
                      }}
                      href="#r3"
                    >
                      <img
                        className="w-auto"
                        alt=""
                        src="images/lottery/mb-toto.png"
                      />
                    </a>
                  </li>
                  <li className="lt-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("r4");
                      }}
                      href="#r4"
                    >
                      <img
                        className="w-auto"
                        alt=""
                        src="images/lottery/mb-sg-4d.png"
                      />
                    </a>
                  </li>
                  {activeTab === "display" && (
                    <>
                      <li className="lt-item">
                        <a
                          style={{ display: "none" }}
                          className="nav-link"
                          data-toggle="tab"
                          href="#r5"
                        >
                          <img
                            className="w-auto"
                            alt=""
                            src="images/lottery/mb-sabah.png"
                          />
                        </a>
                      </li>
                      <li className="lt-item">
                        <a
                          style={{ display: "none" }}
                          className="nav-link"
                          data-toggle="tab"
                          href="#r6"
                        >
                          <img
                            className="w-auto"
                            alt=""
                            src="images/lottery/mb-bigsweep.png"
                          />
                        </a>
                      </li>
                      <li className="lt-item">
                        <a
                          style={{ display: "none" }}
                          className="nav-link"
                          data-toggle="tab"
                          href="#r7"
                        >
                          <img
                            className="w-auto"
                            alt=""
                            src="images/lottery/mb-stc4d.png"
                          />
                        </a>
                      </li>
                      <li className="lt-item">
                        <a
                          style={{ display: "none" }}
                          className="nav-link"
                          data-toggle="tab"
                          href="#r8"
                        >
                          <img
                            className="w-auto"
                            alt=""
                            src="images/lottery/mb-gd-lotto.png"
                          />
                        </a>
                      </li>
                      <li className="lt-item">
                        <a
                          style={{ display: "none" }}
                          className="nav-link"
                          data-toggle="tab"
                          href="#r9"
                        >
                          <img
                            className="w-auto"
                            alt=""
                            src="images/lottery/mb-9lotto.png"
                          />
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <div className="tab-content" id="lottoContent">
                {activeTab === 'r1' && 
                  <div className="tab-pane fade show active" id="r1">
                    <div className="date-box">
                      <div className="lottitle-2">
                        {moment(
                          gameResults ? gameResults.date : new Date()
                        ).format("YYYY-MM-DD (ddd)")}
                      </div>
                    </div>
                    <div className="table-responsive rs-table">
                      <table width="100%" className="rs-top">
                        <tbody>
                          <tr>
                            <th>หมายเลขที่ชนะ</th>
                          </tr>
                          <tr>
                            <td>
                              {gameResults &&
                              gameResults["Thai Lotto"] &&
                              gameResults["Thai Lotto"].length > 0
                                ? gameResults["Thai Lotto"][0].result
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table width="100%" className="rs-btm">
                        <tbody>
                          <tr>
                            <th width="50%">3 Top</th>
                            <th width="50%">2 Top</th>
                          </tr>
                          <tr>
                            <th width="50%">
                              {gameResults &&
                              gameResults["Thai Lotto"] &&
                              gameResults["Thai Lotto"].length > 0
                                ? gameResults["Thai Lotto"][0].top3
                                : "-"}
                            </th>
                            <th width="50%">
                              {gameResults &&
                              gameResults["Thai Lotto"] &&
                              gameResults["Thai Lotto"].length > 0
                                ? gameResults["Thai Lotto"][0].bottom2
                                : "-"}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
                {activeTab === 'r2' && 
                  <div className="tab-pane fade show active" id="r2">
                    <div className="date-box">
                      <div className="lottitle-2">
                        {moment(
                          gameResults ? gameResults.date : new Date()
                        ).format("YYYY-MM-DD (ddd)")}
                      </div>
                    </div>
                    <div className="table-responsive rs-table">
                      <table width="100%" className="rs-top">
                        <tbody>
                          <tr>
                            <th>Giải ĐB</th>
                          </tr>
                          <tr>
                            <td>
                              {gameResults &&
                              gameResults["Hanoi Lotto"] &&
                              gameResults["Hanoi Lotto"].length > 0
                                ? gameResults["Hanoi Lotto"][0].result
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table width="100%" className="rs-btm">
                        <tbody>
                          <tr>
                            <th>Giải nhất</th>
                          </tr>
                          <tr>
                            <td>
                              {gameResults &&
                              gameResults["Hanoi Lotto"] &&
                              gameResults["Hanoi Lotto"].length > 0
                                ? gameResults["Hanoi Lotto"][0].result2
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
                {activeTab === 'r3' && 
                  <div className="tab-pane fade show active" id="r3">
                    <div className="date-box">
                      <div className="lottitle-2">
                        {moment(
                          gameResults ? gameResults.date : new Date()
                        ).format("YYYY-MM-DD (ddd)")}
                      </div>
                    </div>
                    <div className="table-responsive rs-table">
                      <table width="100%" className="rs-top">
                        <tbody>
                          <tr>
                            <th>Giải ĐB</th>
                          </tr>
                          <tr>
                            <td>
                              {gameResults &&
                              gameResults["Hanoi VIP Lotto"] &&
                              gameResults["Hanoi VIP Lotto"].length > 0
                                ? gameResults["Hanoi VIP Lotto"][0].result
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table width="100%" className="rs-btm">
                        <tbody>
                          <tr>
                            <th>Giải nhất</th>
                          </tr>
                          <tr>
                            <td>
                              {gameResults &&
                              gameResults["Hanoi VIP Lotto"] &&
                              gameResults["Hanoi VIP Lotto"].length > 0
                                ? gameResults["Hanoi VIP Lotto"][0].result2
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
                {activeTab === 'r4' && 
                  <div className="tab-pane fade show active" id="r4">
                    <div className="date-box">
                      <div className="lottitle-2">
                        {moment(
                          gameResults ? gameResults.date : new Date()
                        ).format("YYYY-MM-DD (ddd)")}
                      </div>
                    </div>
                    <div className="table-responsive rs-table">
                      <table width="100%" className="rs-top">
                        <tbody>
                          <tr>
                            <th>หมายเลขที่ชนะ</th>
                          </tr>
                          <tr>
                            <td>
                              {gameResults &&
                              gameResults["Laos Lotto"] &&
                              gameResults["Laos Lotto"].length > 0
                                ? gameResults["Laos Lotto"][0].result
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
                {activeTab === 'display' &&
                  <> 
                    <div
                      style={{ display: "none" }}
                      className="tab-pane fade"
                      id="r5"
                    >
                      <div className="date-box">
                        <div className="lottitle-2">
                          {moment(
                            gameResults ? gameResults.date : new Date()
                          ).format("YYYY-MM-DD (ddd)")}
                        </div>
                      </div>
                      <div className="table-responsive rs-table">
                        <table width="100%" className="rs-top">
                          <tbody>
                            <tr>
                              <th width="33.33%">1st Prize</th>
                              <th width="33.33%">2nd Prize</th>
                              <th width="33.33%">3rd Prize</th>
                            </tr>
                            <tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="100%" className="rs-btm">
                          <tbody>
                            <tr>
                              <th width="50%">Special</th>
                              <th width="50%">Consolation</th>
                            </tr>
                            <tr>
                              <td>
                                <div className="consol-wrap">
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                </div>
                              </td>
                              <td>
                                <div className="consol-wrap">
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      style={{ display: "none" }}
                      className="tab-pane fade"
                      id="r6"
                    >
                      <div className="date-box">
                        <div className="lottitle-2">
                          {moment(
                            gameResults ? gameResults.date : new Date()
                          ).format("YYYY-MM-DD (ddd)")}
                        </div>
                      </div>
                      <div className="table-responsive rs-table">
                        <table width="100%" className="rs-top">
                          <tbody>
                            <tr>
                              <th width="33.33%">1st Prize</th>
                              <th width="33.33%">2nd Prize</th>
                              <th width="33.33%">3rd Prize</th>
                            </tr>
                            <tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="100%" className="rs-btm">
                          <tbody>
                            <tr>
                              <th width="50%">Special</th>
                              <th width="50%">Consolation</th>
                            </tr>
                            <tr>
                              <td>
                                <div className="consol-wrap">
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                </div>
                              </td>
                              <td>
                                <div className="consol-wrap">
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      style={{ display: "none" }}
                      className="tab-pane fade"
                      id="r7"
                    >
                      <div className="date-box">
                        <div className="lottitle-2">
                          {moment(
                            gameResults ? gameResults.date : new Date()
                          ).format("YYYY-MM-DD (ddd)")}
                        </div>
                      </div>
                      <div className="table-responsive rs-table">
                        <table width="100%" className="rs-top">
                          <tbody>
                            <tr>
                              <th width="33.33%">1st Prize</th>
                              <th width="33.33%">2nd Prize</th>
                              <th width="33.33%">3rd Prize</th>
                            </tr>
                            <tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="100%" className="rs-btm">
                          <tbody>
                            <tr>
                              <th width="50%">Special</th>
                              <th width="50%">Consolation</th>
                            </tr>
                            <tr>
                              <td>
                                <div className="consol-wrap">
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                </div>
                              </td>
                              <td>
                                <div className="consol-wrap">
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                  <div>-</div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      style={{ display: "none" }}
                      className="tab-pane fade"
                      id="r8"
                    >
                      <div className="date-box">
                        <div className="lottitle-2">
                          {moment(
                            gameResults ? gameResults.date : new Date()
                          ).format("YYYY-MM-DD (ddd)")}
                        </div>
                      </div>
                      <div className="table-responsive rs-table">
                        <table width="100%" className="rs-top">
                          <tbody>
                            <tr>
                              <th width="33.33%">1st Prize</th>
                              <th width="33.33%">2nd Prize</th>
                              <th width="33.33%">3rd Prize</th>
                            </tr>
                            <tr>
                              <td>5359</td>
                              <td>7485</td>
                              <td>8761</td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="100%" className="rs-btm">
                          <tbody>
                            <tr>
                              <th width="50%">Special</th>
                              <th width="50%">Consolation</th>
                            </tr>
                            <tr>
                              <td>
                                <div className="consol-wrap">
                                  <div>1465</div>
                                  <div>8077</div>
                                  <div>6629</div>
                                  <div>5757</div>
                                  <div>1315</div>
                                  <div>4234</div>
                                  <div>3500</div>
                                  <div>2687</div>
                                  <div>7822</div>
                                  <div>6392</div>
                                </div>
                              </td>
                              <td>
                                <div className="consol-wrap">
                                  <div>9867</div>
                                  <div>1325</div>
                                  <div>0769</div>
                                  <div>2150</div>
                                  <div>7167</div>
                                  <div>5280</div>
                                  <div>8979</div>
                                  <div>7589</div>
                                  <div>0271</div>
                                  <div>8986</div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      style={{ display: "none" }}
                      className="tab-pane fade"
                      id="r9"
                    >
                      <div className="date-box">
                        <div className="lottitle-2">
                          {moment(
                            gameResults ? gameResults.date : new Date()
                          ).format("YYYY-MM-DD (ddd)")}
                        </div>
                      </div>
                      <div className="table-responsive rs-table">
                        <table width="100%" className="rs-top">
                          <tbody>
                            <tr>
                              <th width="33.33%">1st Prize</th>
                              <th width="33.33%">2nd Prize</th>
                              <th width="33.33%">3rd Prize</th>
                            </tr>
                            <tr>
                              <td>0584</td>
                              <td>6220</td>
                              <td>6203</td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="100%" className="rs-btm">
                          <tbody>
                            <tr>
                              <th width="50%">Special</th>
                              <th width="50%">Consolation</th>
                            </tr>
                            <tr>
                              <td>
                                <div className="consol-wrap">
                                  <div>5845</div>
                                  <div>4032</div>
                                  <div>2433</div>
                                  <div>4577</div>
                                  <div>0291</div>
                                  <div>9984</div>
                                  <div>6204</div>
                                  <div>1115</div>
                                  <div>6642</div>
                                  <div>6398</div>
                                </div>
                              </td>
                              <td>
                                <div className="consol-wrap">
                                  <div>7499</div>
                                  <div>2417</div>
                                  <div>7418</div>
                                  <div>4002</div>
                                  <div>3648</div>
                                  <div>9031</div>
                                  <div>0504</div>
                                  <div>7610</div>
                                  <div>7405</div>
                                  <div>0376</div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dialog
        fullScreen
        open={!isEmpty(gameSelected)}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {gameSelected.label}
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            background: "#23232A",
            padding: 25,
            marginTop: 70,
            height: "calc(100vh - 70px)",
            overflow: "auto",
          }}
        >
          <Card
            style={{
              background: "none",
              border: "1px solid #ffaf50",
              borderRadius: "16px",
              maxWidth: "400px",
              margin: "0 auto",
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                className={classes.media}
                image={
                  gameSelected.label === "NewWin"
                    ? "/img/Untitled.png"
                    : gameSelected.src
                }
                title="Contemplative Reptile"
              />
              <CardContent style={{ textAlign: "center" }}>
                {gameSelected.err ? (
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ fontWeight: "bold", color: "#ffaf50" }}
                  >
                    {gameSelected.err}
                  </Typography>
                ) : loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 15,
                    }}
                  >
                    <PropagateLoader
                      sizeUnit="px"
                      size={20}
                      color="#f50057"
                      loading={loading}
                    />
                  </div>
                ) : (
                  <React.Fragment>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontWeight: "bold", color: "#ffaf50" }}
                    >
                      Account: {gameSelected.account}
                    </Typography>
                    {gameSelected.password ? (
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ fontWeight: "bold", color: "#ffaf50" }}
                      >
                        Password: {gameSelected.password}
                      </Typography>
                    ) : null}
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontWeight: "bold", color: "#ffaf50" }}
                    >
                      Balance: {gameSelected.balance}
                    </Typography>
                    {gameSelected.deeplink !== undefined ? (
                      <a
                        className="link-btn-default"
                        href={gameSelected.deeplink}
                      >
                        Play now
                      </a>
                    ) : (
                      <Button
                        onClick={() => history.push(gameSelected.route)}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        <strong>Play now</strong>
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Dialog>
      <section className="footer-sec pm-detail-wrap">
        <div className="max-container">
          <div className="footer-seo" style={{ color: "#787b8b" }}>
            <h1 style={{ color: "#787b8b" }}>
              Sport Lottery Slot Game Online for Mobile Thailand | Slot Judi
              Thailand | Thailand Online Casino Slot | Online Slot Thailand |
              Online Slots Games Thailand
            </h1>
            <p>
              If you're a fan of online gambling, you'll be pleased to know that
              we offer a wide range of Mobile Slot Game Thailand games that can
              be played on your mobile device.
            </p>
            <p>
              Our Slot Game Online for Mobile Thailand offers a seamless and
              user-friendly experience, making it easy for you to access your
              favorite Slots Game Thailand wherever you are.
            </p>{" "}
            <br />
            <p>
              Convenience: You can play Online Slot Thailand games on your
              mobile device from the comfort of your own home or on the go. All
              you need is an internet connection,and you're ready to start
              playing.
            </p>{" "}
            <br />
            <p>
              Variety: We offer a wide range of Slots Game Thailand, from
              classic three-reel slots to modern video slots. With so many games
              to choose from,you're sure to find one that meets your
              preferences.
            </p>
            <p>
              Bonuses and Promotions: We offer a range of Thailand Slots Casino
              bonuses and promotions for new and existing players, including
              welcome bonuses, free spins, and cashback offers.These promotions
              can help boost your bankroll and increase your chances of winning
              big.{" "}
            </p>{" "}
            <br />
            <h2>Thailand Online Casino Slot with Top Games</h2>
            <p>
              Our online Casino offers a variety of Online Slots Games Thailand
              games that are tailored to meet the preferences of
              Thailandplayers. From traditionalThailand-themed slots to modern
              video slots, we have something for everyone. VG online slot games
              are designed to provide a fun and exciting experience,with
              high-quality graphics and immersive sound effects.
            </p>{" "}
            <br />
            <h2 style={{ color: "#787b8b" }}>
              Play VG online Mobile Slot Game Thailand
            </h2>
            <p>
              Our mobile slot games are designed to be played on any mobile
              device, including smartphones and tablets. We use the latest
              technology to ensure that our games run smoothly on all Slot Judi
              Thailand devices, so you can enjoy a seamless gaming experience no
              matter where you are.
            </p>{" "}
            <br />
            <h2 style={{ color: "#787b8b" }}>
              Online Slot Thailand at VG online
            </h2>
            <p>
              Our online slot games are easy to play and offer a range of
              betting options to suit all budgets. Whether you're a beginner or
              an experienced player,you're sure to find a slot game that meets
              your preferences. With a variety of themes and features, our
              Thailand Online Slot Casino slot games are sure to keep you
              entertained for hours.
            </p>
            <br />
            <h2 style={{ color: "#787b8b" }}>FAQs</h2>
            <h3 style={{ color: "#787b8b" }}>Q: Are your slot games fair?</h3>
            <p>
              A: Yes, our Slot Game Online for Mobile Thailand games are
              regularly audited for fairness to ensure that all outcomes are
              completely random.We use a Random Number Generator (RNG) to
              determine the outcome of each spin, which ensures that the results
              are completely unbiased.
            </p>
            <h3 style={{ color: "#787b8b" }}>
              Q: How do I deposit and withdraw funds?
            </h3>
            <p>
              A: We offer a variety of payment methods, including credit cards,
              debit cards, and e-wallets. To make a deposit, simply select your
              preferred payment methodand follow the on-screen instructions.
              Withdrawals are processed quickly and securely, and funds are
              usually credited to your account within a few working days.
            </p>
            <h2 style={{ color: "#787b8b" }}>
              Online Casino Thailand | Live Casino Thailand | Online Sportsbook
              Thailand | Live Casino Thailand Promotion | Sports Toto Thailand
            </h2>
          </div>
        </div>
      </section>
      <ErrorDialog />
    </>
    // <div style={{ display: 'flex', flexDirection: 'column', background: "#fff" }}>
    //   <Head image={image} alt="bannerlottery" />
    //   <div className="pages">
    //     <button className="pages-btn-items">Lottery</button>
    // <LotteryComp history={history} />
    //     <img
    //       style={{ width: '100%', margin: '30px 0' }}
    //       src="./images/home/bannerlotteryplay.png"
    //       alt="bannerlottery"
    //     />
    //     <TotalPlay />
    //     <Testimonials />
    //   </div>
    // </div>
  );
}
export default withStyles(styles)(home);
