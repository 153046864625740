/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import http from 'service/http'
import { useTranslation } from 'react-i18next'
import './style.css'
import useLoading from '../loading/hook'
import store from 'store'
// import socket from 'service/socket'
import useDialog from '../dialog/hook'
import Resizer from 'react-image-file-resizer'
import {
  Checkbox,
  Button,
  Form,
  Modal,
  Select,
  Radio,
  Space,
  Upload
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import InputField from '../../component/textInput/InputField'
import { showNotification } from '../../utils/function'
import { ETypeNotification } from '../../constants/gameData'
import FormComponent from '../../component/form/FormComponent'
import moment from 'moment'
import MenuItem from '../../component/layout/MenuItem'
import BoxLeft from '../../component/layout/BoxLeft'
import BoxRight from '../../component/layout/BoxRight'
const { Option } = Select
const correctGameProps = ({
  name,
  id,
  bank_account_name: bankAccountName,
  bank_account_no: bankAccountNo
}) => ({
  title: name,
  value: {
    name,
    id,
    bankAccountName,
    bankAccountNo
  }
})
const arrbanks = [
  {
    image: 'images/icon/bankiconkrung.png',
    bank_account_name: 'Leagend club',
    bank_account_no: '1234567890',
    code: '',
    id: 45,
    name: 'Krungsri Bank',
    type: 'Deposit'
  },
  {
    image: 'images/icon/ThaibankRHB.png',
    bank_account_name: 'Leagend club',
    bank_account_no: '1234567890',
    code: '',
    id: 45,
    name: 'Rhb',
    type: 'Deposit'
  },
  {
    image: 'images/icon/ThaibankCIMB.png',
    bank_account_name: 'Leagend club',
    bank_account_no: '1234567890',
    code: '',
    id: 45,
    name: 'Cimb',
    type: 'Deposit'
  },
  {
    image: 'images/icon/bank_icon.png',
    bank_account_name: 'Leagend club',
    bank_account_no: '1234567890',
    code: '',
    id: 45,
    name: 'KrungThai Bank',
    type: 'Deposit'
  },
  {
    image: 'images/icon/bank_icon1.png',
    bank_account_name: 'Leagend club',
    bank_account_no: '1234567890',
    code: '',
    id: 46,
    name: 'Kasikorn Bank',
    type: 'Deposit'
  },
  {
    image: 'images/icon/bank_icon2.png',
    bank_account_name: 'Leagend club',
    bank_account_no: '1234567890',
    code: '',
    id: 47,
    name: 'SCB Bank',
    type: 'Deposit'
  }
]

function NewDeposit({ history }) {
  const { t } = useTranslation()
  const [banks, updateBanks] = React.useState([])
  const [userState, setUserState] = React.useState({})
  const [checkedBank, setCheckedBank] = useState(1)
  const [checkedBonus, setCheckedBonus] = useState(0)
  const [dataForm, setDataForm] = useState({})
  const user = store.get('user')
  const [bonus, setBonus] = useState([])
  const [bonusId, setBonusId] = useState(null)
  const [bankCode, setBankCode] = useState('')
  const [typeSubmit, setTypeSubmit] = useState(false)
  const [selectedBank, setSelectedBank] = useState('')
  const [showBank, setShowBank] = useState(true)
  const [loading, withLoading] = useLoading(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [typeBank, setTypeBank] = useState('')
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Deposit Successful'
  })
  const bankOnline = [
    {
      value: 'BKKB',
      title: 'Bangkok Bank'
    },
    {
      value: 'BAAC',
      title: 'Bank for Agriculture and Agricultural Cooperatives	'
    },
    {
      value: 'KSAB',
      title: 'Bank of Ayudhya (Krungsri)'
    },
    {
      value: 'CIMT',
      title: 'CIMB Thai Bank'
    },
    {
      value: 'GSBA',
      title: 'Government Savings Bank	'
    },
    {
      value: 'KSKB',
      title: 'Kasikorn Bank	'
    },
    {
      value: 'Kiatnakin Bank',
      title: 'Kiatnakin Bank'
    },
    {
      value: 'KTBA',
      title: 'Krung Thai Bank	'
    },
    {
      value: 'SCBB',
      title: 'Siam Commercial Bank'
    },
    {
      value: 'SCBT',
      title: 'Standard Chartered Bank (Thai)'
    }
  ]
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Deposit Error',
    btnLabel: 'Got it',
    type: 'error'
  })
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const fetchData = async () => {
    try {
      const [gamesResp] = await withLoading(() =>
        Promise.all([http.get({ path: 'banks' })])
      )
      const newBanks = gamesResp
        .filter(x => x.type === 'Deposit')
        .map(g => {
          const found = arrbanks.find(x => x.name == g.name)
          if (found) g.image = found.image
          return g
        })
      updateBanks(newBanks)
    } catch (error) {
      throw error
    }
  }
  const fetchAccount = async () => {
    const [userResp] = await withLoading(() =>
      Promise.all([http.get({ path: 'me' })])
    )
    store.set('user', userResp)
    setUserState(userResp)
  }
  const fileChangedHandler = () => {
    const {
      files: [file]
    } = document.querySelector('input[type=file]')
    let fileInput = false
    if (file) {
      fileInput = true
    }
    return new Promise(resolve => {
      if (fileInput) {
        Resizer.imageFileResizer(
          file,
          1900,
          900,
          'JPEG',
          50,
          0,
          uri => {
            resolve(uri)
          },
          'base64'
        )
      }
    })
  }
  const onSubmit = async payload => {
    try {
      const { user_id: userId, user_account_no: username } = store.get('user')
      // const proofOfDeposit = await fileToBase64()
      // console.log("payload", payload);
      console.log(payload.amount)
      const proofOfDeposit = await fileChangedHandler()
      const submited = {
        amount: payload.amount,
        currency: 'THB',
        to_bank_account_name: payload.bank_account_name,
        to_bank_account_number: payload.bank_account_no,
        to_bank: payload.name,
        to_bank_id: payload.id,
        from_bank: user.bankName,
        username,
        type: payload.type ? payload.type : 'Online Transfer',
        receipt_image: proofOfDeposit
      }
      if (bonusId) submited.bonusId = bonusId
      console.log(bonusId)
      await withLoading(() =>
        http.post({ path: `users/${userId}/deposit`, payload: submited })
      )
      // socket.emit('messages', {
      //   msgType: 'deposit.request',
      //   data: {
      //     message: `Request deposit from '${username}'`,
      //     title: 'Deposit Request'
      //   }
      // })
      showNotification(
        ETypeNotification.SUCCESS,
        'Your request submitted successfully'
      )
      // navigate('/account')
      // window.location.reload();
    } catch (error) {
      console.log('error.message', error)
      showNotification(ETypeNotification.ERROR, error.message)
    }
  }
  React.useEffect(() => {
    fetchData()
    fetchAccount()
    form.setFieldsValue({
      name: dataForm.name,
      bank_account_name: dataForm.bank_account_name,
      bank_account_no: dataForm.bank_account_no
    })
  }, [form, dataForm])
  const handleCheck = item => {
    setCheckedBank(item.id)
    setDataForm(item)
  }
  const handleCheckBonus = id => {
    // console.log(id)
    setBonusId(id)
    setCheckedBonus(checkedBonus == 1 ? 0 : 1)
  }
  const handlerSelected = item => {
    if (item !== '') {
      setSelectedBank(item)
    }
  }
  const handleSubmit = values => {
    if (typeSubmit) {
      onSubmitHandlerGetIdItem({
        ...values,
        bankCode: bankCode,
        selectedBank: selectedBank
      })
      // onSubmitVaderpay({...values})
    } else {
      onSubmit({
        ...values,
        id: dataForm.id,
        selectedBank: selectedBank,
        type: typeBank
      })
    }
  }
  const onSubmitHandlerGetIdItem = async payload => {
    try {
      const submited = {
        bankCode: payload.bankCode,
        amount: payload.amount
      }
      if (bonusId) submited.bonusId = bonusId
      const response = await withLoading(() =>
        http.post({ path: `payment-vaderpay`, payload: submited })
      )

      if (response) {
        const linkRedirect = response.redirect_to
        const link = linkRedirect.split('redirectlink=')
        window.open(link && link[1], '_blank')
      }
      window.location.reload()
    } catch (e) {
      console.log('e', e)
    }
  }
  const handlerSubmitChangeUser = values => {
    onSubmitFormUpdateUser({ ...values })
    fetchAccount()
    setIsModalVisible(false)
  }
  const onSubmitFormUpdateUser = async payload => {
    try {
      const { user_id: userId } = store.get('user')
      const submited = {
        bankName: payload.bankName,
        bankAccount: payload.bankAccount,
        bankAccountNumber: payload.bankAccountNumber
      }
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: submited })
      )
      showNotification(
        ETypeNotification.SUCCESS,
        'Update Your Bank Detail Success'
      )
    } catch (error) {
      showNotification(ETypeNotification.ERROR, error.message)
    }
  }
  const [form] = Form.useForm()
  const newItemBank = {}
  newItemBank.id = banks.length + 1
  newItemBank.name = userState.bankName
  newItemBank.bank_account_no = userState.bankAccountNumber
  const mergeBank = [{ ...newItemBank }]
  const handlerShowBankSelected = item => {
    if (item == 2) {
      setShowBank(false)
      setTypeSubmit(true)
      setTypeBank(item == 2 ? 'payment gateway' : '')
    } else {
      setShowBank(true)
      setTypeSubmit(false)
      setTypeBank(item == 1 ? 'banking' : '')
    }
  }
  const handlerSelectedBankTHB = item => {
    console.log('item', item)
    setBankCode(item)
  }
  const fetchBonus = async () => {
    // const depo = await http.get({ path: `users/${user.user_id}/deposit`, params: {
    //     fromDate: moment().format('YYYY-MM-DD 00:00:00'),
    //     toDate: moment().format('YYYY-MM-DD 23:59:59'),
    //   }}).then(rs => rs.filter(x => x.status == 'Success' && x.bonus_amount > 0))
    // // console.log(depo)
    // if(depo.length == 0) {
    const bonus = await http.get({ path: `admin/promotion-new` })
    const activeBonus = bonus.filter(x => x.active == 1)
    setBonus(activeBonus)
    // }
  }
  React.useEffect(() => {
    fetchBonus()
  }, [])
  return (
    <div className="containerActive">
      <MenuItem />
      <div className="styledContainer">
        <BoxLeft />
        <div style={{ width: '100%', background: '#fff', borderRadius: '8px' }}>
          <div
            className="show_mb btn-bank-trans"
            style={{
              margin: '57px 0 10px 0',
              height: 40,
              lineHeight: '16px'
            }}
          >
            ATM / Online Banking
          </div>
          <Form
            form={form}
            layout="vertical"
            className="form-deposit"
            onFinish={handleSubmit}
            style={{
              margin: 'auto'
            }}
          >
            <div className="show_mb">
              <Form.Item name="amount" label="Deposit Amount">
                <InputField
                  size="default"
                  placeholder="Amount MIN 30 / MAX 10,000"
                />
              </Form.Item>
            </div>
            <div className="hide_mb">
              <Form.Item name="amount" label="Deposit Amount">
                <InputField size="large" placeholder="Deposit Amount" />
              </Form.Item>
            </div>
            {bonus.length > 0 && (
              <>
                <p
                  style={{
                    color: 'hsl(42deg 100% 50%)',
                    fontStyle: 'italic',
                    fontWeight: 600
                  }}
                >
                  Take Bonus:
                </p>
                <Radio.Group onChange={e => handleCheckBonus(e.target.value)}>
                  <Space direction="vertical">
                    {bonus.map(b => (
                      <Radio key={b.id} value={b.id}>
                        {b.name}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </>
            )}

            {showBank == false && (
              <div className="input-deposit">
                <label>
                  Bank <span className="tick">*</span>
                </label>
                <div className=" input-select-channel d-flex">
                  <Select
                    size="large"
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={handlerSelectedBankTHB}
                    placeholder="Select bank..."
                    style={{ width: '100%' }}
                  >
                    <Option value="">{t('SELECT_BANK')}</Option>
                    {bankOnline &&
                      bankOnline.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.title}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            )}
            {showBank && (
              <>
                <p
                  style={{
                    marginTop: 20,
                    color: 'hsl(42deg 100% 50%)',
                    fontStyle: 'italic',
                    fontWeight: 600
                  }}
                >
                  Bank Account:
                </p>
                <div className="hide_mb input-deposit lst-radio-bank">
                  {banks &&
                    banks.map(item => {
                      return (
                        <label key={item.id} className="container">
                          <Checkbox
                            checked={checkedBank === item.id}
                            onChange={() => handleCheck(item)}
                          >
                            <img
                              style={{ maxWidth: 350 }}
                              src={item.image}
                              alt=""
                            />
                          </Checkbox>
                        </label>
                      )
                    })}
                </div>
                <div className="show_mb input-deposit lst-radio-bank">
                  {banks &&
                    banks.map(item => {
                      return (
                        <label key={item.id} className="">
                          <Checkbox
                            checked={checkedBank === item.id}
                            onChange={() => handleCheck(item)}
                          >
                            <img src={item.image} alt="" />
                          </Checkbox>
                        </label>
                      )
                    })}
                </div>
              </>
            )}
            {showBank && (
              <>
                <div className="show_mb">
                  <Form.Item name="name" label="Bank Name">
                    <InputField
                      disabled
                      size="default"
                      placeholder="Bank Name"
                    />
                  </Form.Item>
                </div>
                <div className="hide_mb">
                  <Form.Item name="name" label="Bank Name">
                    <InputField disabled size="large" placeholder="Bank Name" />
                  </Form.Item>
                </div>
                <div className="show_mb">
                  <Form.Item name="bank_account_name" label="Account Name">
                    <InputField
                      disabled
                      size="default"
                      placeholder="Account Name"
                    />
                  </Form.Item>
                </div>
                <div className="hide_mb">
                  <Form.Item name="bank_account_name" label="Account Name">
                    <InputField
                      disabled
                      size="large"
                      placeholder="Account Name"
                    />
                  </Form.Item>
                </div>
                <div className="hide_mb">
                  <Form.Item name="bank_account_no" label="Account No.">
                    <InputField
                      disabled
                      size="large"
                      placeholder="Account No."
                    />
                  </Form.Item>
                </div>
                <div className="show_mb">
                  <Form.Item name="bank_account_no" label="Account No.">
                    <InputField
                      disabled
                      size="default"
                      placeholder="Account No."
                    />
                  </Form.Item>
                </div>
              </>
            )}
            <div className="input-deposit">
              <label>
                Deposit Channel <span className="tick">*</span>
              </label>
              <Select
                size="large"
                onChange={handlerShowBankSelected}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder="Select chanel..."
                style={{ width: '100%' }}
              >
                <Option value="1">Online Transfers</Option>
                <Option value="2">Vaderpay</Option>
              </Select>
            </div>

            {showBank && (
              <div className="input-deposit">
                <label>{t('BANK_INFO')}:</label>
                <div className="input-select-channel d-flex ml-4">
                  <label>{user.bankName}</label>
                </div>
                <div className="input-select-channel d-flex ml-4">
                  <label>{user.bankAccount}</label>
                </div>
                <div className="input-select-channel d-flex ml-4">
                  <label>{user.bankAccountNumber}</label>
                </div>
              </div>
            )}

            <Modal
              footer={null}
              title="Update bank"
              visible={isModalVisible}
              onCancel={handleCancel}
            >
              <FormComponent handlerSubmit={handlerSubmitChangeUser}>
                <Form.Item
                  name="bankName"
                  label="Bank Name"
                  style={{ marginBottom: '10px' }}
                >
                  <InputField
                    type="text"
                    size="default"
                    placeholder="Bank Name..."
                  />
                </Form.Item>
                <Form.Item name="bankAccount" label="Bank Account">
                  <InputField
                    type="text"
                    size="default"
                    placeholder="Bank Account.."
                  />
                </Form.Item>
                <Form.Item name="bankAccountNumber" label="Account No">
                  <InputField
                    type="text"
                    size="default"
                    placeholder="Account No.."
                  />
                </Form.Item>
                <div className="text-center formGroup_btn">
                  <Button type="primary" loading={loading} htmlType="submit">
                    Update
                  </Button>
                </div>
              </FormComponent>
            </Modal>
            {showBank && (
              <>
                <input
                  style={{ display: 'none' }}
                  id="uploadBnb"
                  type="file"
                  className="input_block input_white"
                />
                <Button
                  onClick={() => {
                    document.getElementById('uploadBnb').click()
                  }}
                  style={{ background: 'red', color: '#fff' }}
                  icon={<UploadOutlined />}
                >
                  Up Load Receipt
                </Button>
              </>
            )}
            <Button
              style={{ marginLeft: 20 }}
              type="primary"
              id="custom-primary-button-deposit"
              loading={loading}
              htmlType="submit"
            >
              {t('DEPOSIT')}
            </Button>
          </Form>
        </div>
        <div className="showBoxright">
          <BoxRight />
        </div>
      </div>
      <div className="showBoxrightmb">
        <BoxRight />
      </div>
      <Dialog />
      <ErrorDialog />
    </div>
  )
}

export default NewDeposit
