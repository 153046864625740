import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
} from "@material-ui/core";
import { PropagateLoader } from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";
import { isEmpty } from "ramda";
import store from "store";
import http from "service/http";
import useLoading from "../loading/hook";
import styles from "./style";
import SLOTS from "./constant";
import queryString from "query-string";
import GameAccount from "../../component/gameAccount";
import { useDispatch } from "react-redux";
import { showLoginModal } from "../../store/modalSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: "",
    7: "",
    2: "",
    1: "",
  };

  return proxy[id];
};

function home({ history, classes }) {
  const {
    location: { search },
  } = history;
  const { game } = queryString.parse(search);

  const [loading, withLoading] = useLoading(false);

  const [gameSelected, updateGameSelected] = React.useState({});

  const [games, setGames] = React.useState([]);

  const dispatch = useDispatch();

  const { user_id: userId } = store.get("user") || { user_id: undefined };

  const gamesSetup = async () => {
    const gameList = await http.get({ path: "games" });
    setGames(gameList);
    return gameList;
  };

  const loginToGame = async (gameInfo, gameList = null) => {
    if (!userId) {
      alert("Please Login!");
      return;
    }
    const { src, label, route, id, redirect, target } = gameInfo;
    if (target === "_blank") {
      window.open(route);
    }
    if (redirect) {
      history.push(route);
      return;
    }
    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    updateGameSelected({ label, src, route, id });
    const {
      balance,
      account,
      err,
      game_password: password,
    } = await withLoading(() =>
      http
        .post({
          path: `users/${userId}/game/logout`,
          payload: {
            gameLoginId: id,
          },
        })
        .catch((error) => {
          if (error.message !== "Not enough money !") {
            throw new Error({ message: error.message });
          }
          return true;
        })
        .then(() => http.get({ path: `users/${userId}/games/${id}/balance` }))
    ).catch((error) => {
      console.log(error);
      return { err: error.message };
    });
    if (err) updateGameSelected({ label, src, err });
    const outDeeplink = generateDeeplink({ id, account, password });

    updateGameSelected({
      label,
      src,
      route,
      id,
      balance: balance ? balance.balance : 0,
      account: balance ? balance.account : "",
      err,
      deeplink: outDeeplink,
      password,
    });
    if (!outDeeplink && outDeeplink !== "") history.push(route);
  };

  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` });
    }
    async function fetchGame() {
      const gameList = await gamesSetup();
      if (game) {
        const gameInfo = SLOTS.find((x) => x.code === game);
        loginToGame(gameInfo, gameList);
      }
    }
    fetchGame();
  }, []);

  const handleClose = () => {
    updateGameSelected({});
  };

  return (
    <>
      <section className="sports-sec">
        <div className="max-container">
          <div className="sport-container">
            <div className="tab-content" id="sportContent">
              <div className="tab-pane show active" id="wbet">
                <div className="spfloat deco-1">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-1.png"
                  />
                </div>
                <div className="spfloat deco-2">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-2.png"
                  />
                </div>
                <div className="spfloat deco-3">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-3.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      alt=""
                      className="w-auto"
                      src="images/sports/wbet-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/sports/wbet.png"
                        alt="M9BET Sports Betting"
                        className="w-auto"
                      />
                    </div>
                    <div className="sport-text">
                      M9BET online sports betting provider gives your players a
                      huge variety of live sports with the best odds.
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => {
                          loginToGame(SLOTS.find((x) => x.id === 34));
                        }}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="sbobet">
                <div className="spfloat deco-1">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-1-1.png"
                  />
                </div>
                <div className="spfloat deco-2">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-1-2.png"
                  />
                </div>
                <div className="spfloat deco-3">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-1-3.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      alt=""
                      className="w-auto"
                      src="images/sports/sbobet-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/sports/sbobet.png"
                        alt="Sbobet Sports Betting"
                        className="w-auto"
                      />
                    </div>
                    <div className="sport-text">
                      SBOBET online sports betting provider gives your players a
                      huge variety of live sports with the best odds.
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => {
                          loginToGame(SLOTS.find((x) => x.id === 27));
                        }}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="maxbet">
                <div className="spfloat deco-1">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-2-1.png"
                  />
                </div>
                <div className="spfloat deco-2">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-2-2.png"
                  />
                </div>
                <div className="spfloat deco-3">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-2-3.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      alt=""
                      className="w-auto"
                      src="images/sports/maxbet-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/sports/maxbet.png"
                        alt="Maxbet Sports Betting"
                        className="w-auto"
                      />
                    </div>
                    <div className="sport-text">
                      MAXBET online sports betting provider gives your players a
                      huge variety of live sports with the best odds.
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => {
                          loginToGame(SLOTS.find((x) => x.id === 6));
                        }}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="cmd">
                <div className="spfloat deco-1">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-3-1.png"
                  />
                </div>
                <div className="spfloat deco-2">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-3-2.png"
                  />
                </div>
                <div className="spfloat deco-3">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-3-3.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      alt=""
                      className="w-auto"
                      src="images/sports/cmd-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/sports/cmd.png"
                        alt="CMD368 Sports Betting"
                        className="w-auto"
                      />
                    </div>
                    <div className="sport-text">
                      CMD368 online sports betting provider gives your players a
                      huge variety of live sports with the best odds.
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => {
                          loginToGame(SLOTS.find((x) => x.id === 9));
                        }}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="tab-pane fade" id="fb">
                <div className="spfloat deco-1">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-3-1.png"
                  />
                </div>
                <div className="spfloat deco-2">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-3-2.png"
                  />
                </div>
                <div className="spfloat deco-3">
                  <img
                    alt=""
                    className="w-auto"
                    src="images/sports/s-deco-set-3-3.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      alt=""
                      className="w-auto"
                      src="images/sports/fb-sports.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        className="w-auto"
                        src="images/sports/fb.png"
                        alt="FB Sports Betting"
                      />
                    </div>
                    <div className="sport-text">
                      FB Sports online sports betting provider gives your
                      players a huge variety of live sports with the best odds.
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => {
                          loginToGame(SLOTS.find((x) => x.id === 29));
                        }}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <ul className="nav nav-game" id="sportTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link sport-game-m9bet active"
                  data-toggle="tab"
                  href="#wbet"
                >
                  <img
                    alt=""
                    className="h-60 w-180 nmimg"
                    src="images/sports/wbet-sm.png"
                  />
                  <img
                    alt=""
                    className="h-60 w-180 acvimg"
                    src="images/sports/wbet-gold.png"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#sbobet">
                  <img
                    alt=""
                    className="w-auto nmimg"
                    src="images/sports/sbobet-gold.png"
                  />
                  <img
                    alt=""
                    className="w-auto acvimg"
                    src="images/sports/sbobet-sm.png"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#maxbet">
                  <img
                    alt=""
                    className="w-auto nmimg"
                    src="images/sports/maxbet-gold.png"
                  />
                  <img
                    alt=""
                    className="w-auto acvimg"
                    src="images/sports/maxbet-sm.png"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#cmd">
                  <img
                    alt=""
                    className="w-auto nmimg"
                    src="images/sports/cmd-gold.png"
                  />
                  <img
                    alt=""
                    className="w-auto acvimg"
                    src="images/sports/cmd-sm.png"
                  />
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#fb">
                  <img
                    alt=""
                    className="w-aut nmimg"
                    src="images/sports/fb-gold.png"
                  />
                  <img
                    alt=""
                    className="w-auto acvimg"
                    src="images/sports/fb-sm.png"
                  />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <Dialog
          fullScreen
          open={!isEmpty(gameSelected)}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {gameSelected.label}
              </Typography>
            </Toolbar>
          </AppBar>
          <div
            style={{
              background: "#23232A",
              padding: 25,
              marginTop: 70,
              height: "calc(100vh - 70px)",
              overflow: "auto",
            }}
          >
            <Card
              style={{
                background: "none",
                border: "1px solid #ffaf50",
                borderRadius: "16px",
                maxWidth: "400px",
                margin: "0 auto",
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Contemplative Reptile"
                  className={classes.media}
                  image={gameSelected.src}
                  title="Contemplative Reptile"
                />
                <CardContent style={{ textAlign: "center" }}>
                  {gameSelected.err ? (
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontWeight: "bold", color: "#ffaf50" }}
                    >
                      {gameSelected.err}
                    </Typography>
                  ) : loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                      }}
                    >
                      <PropagateLoader
                        sizeUnit="px"
                        size={20}
                        color="#f50057"
                        loading={loading}
                      />
                    </div>
                  ) : (
                    <GameAccount
                      account={gameSelected.account}
                      password={gameSelected.password}
                      deeplink={gameSelected.deeplink}
                      route={gameSelected.route}
                      balance={gameSelected.balance}
                    ></GameAccount>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </Dialog>
      </section>
      <section className="footer-sec pm-detail-wrap">
        <div className="max-container">
          <div className="footer-seo">
            <h1 style={{ color: "#787b8b" }}>
              Online Sportsbook Thailand | Trusted Online Sports Betting Bookie
            </h1>

            <p style={{ color: "#787b8b" }}>
              VG Sportsbook is an online sports betting platform that offers a
              wide range of sports events and markets for users to bet on. We
              will take a closer look at the various features and aspects of
              Online Sportsbook Thailand. We are a website that offers sports
              betting services to users in Thailand. The website features a
              variety of sports events and games that users can bet on,
              including soccer, basketball, tennis, and more.
            </p>

            <h2 style={{ color: "#787b8b" }}>Sports Events and Markets:</h2>

            <p style={{ color: "#787b8b" }}>
              VG Sportsbook offers a vast selection of sports events and markets
              for users to bet on, including popular sports such as football,
              basketball, tennis, and many more. Online Sports Betting Thailand
              platform also offers a variety of bet types, including money line,
              point spread, and over/under.
            </p>

            <h2 style={{ color: "#787b8b" }}>User Interface and Experience:</h2>

            <strong style={{ color: "#787b8b" }}>
              Sbobet Login Thailand:-
            </strong>
            <p style={{ color: "#787b8b" }}>
              Has a user-friendly interface, making it easy for users to
              navigate and place their bets. The platform is available on both
              desktop and mobile devices, allowing users to bet on their
              favourite sports events on the go.
            </p>
            <strong style={{ color: "#787b8b" }}>
              Bonuses and Promotions:-
            </strong>
            <p style={{ color: "#787b8b" }}>
              VG Sportsbook offers various bonuses and promotions to its users.
              M9BET Thailand users can avail themselves of a welcome bonus upon
              signing up, and there are also regular promotions such as cashback
              offers and free bets.
            </p>
            <strong style={{ color: "#787b8b" }}>Security and Safety:</strong>
            <p style={{ color: "#787b8b" }}>
              VG Sportsbook takes the safety and security of its users
              seriously. The platform uses the latest encryption technology to
              ensure that all user information and transactions are secure. The
              website also offers bonuses and promotions to new and existing
              users, such as welcome bonuses and cashback offers.
            </p>
            <strong style={{ color: "#787b8b" }}>Customer Support:</strong>
            <p style={{ color: "#787b8b" }}>
              <strong>Online Football Betting Thailand</strong> offers customer
              support 24/7, and users can reach out to the support team through
              various channels, including live chat, email, and phone.
            </p>

            <h3 style={{ color: "#787b8b" }}>Q&amp;A:</h3>

            <h3 style={{ color: "#787b8b" }}>
              Q: Is VGsoft Sportsbook available in all countries?
            </h3>
            <p style={{ color: "#787b8b" }}>
              A: No, No, VGsoft Sportsbook is not available in all countries. It
              is important to check if the platform is available in your country
              before signing up.
            </p>

            <h3 style={{ color: "#787b8b" }}>
              Q: What payment methods are accepted by VGsoft Sportsbook?
            </h3>
            <p style={{ color: "#787b8b" }}>
              A: VGsoft Sportsbook accepts various payment methods, including
              credit/debit cards, e-wallets, and bank transfers.
            </p>
            <p style={{ color: "#787b8b" }}>
              We are a platform for sports betting enthusiasts in Thailand to
              place bets on their favorite teams and games, with a range of
              options and features designed to enhance the user experience.
            </p>
            <p style={{ color: "#787b8b" }}>
              Casino Online Mobile Thailand | Thailand Live Casino Online |
              Online Casino Thailand Promotion | Online Slot Thailand | 4d
              Betting Thailand
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
export default withStyles(styles)(home);
