import React, { useEffect, useState } from "react";
import config from "config";
import http from "service/http";
import store from "store";
import { useTranslation } from "react-i18next";
import useLoading from "../loading/hook";
import useDialog from "../dialog/hook";
import { Form } from "antd";
import numeral from "numeral";
import MenuItemBoxLeft from "../../component/layout/BoxLeft/MenuItemBoxLeft";
import MenuItemBoxLeftMobile from "../../component/layout/BoxLeft/MenuItemBoxLeftMobile";
import MainWallet from "../../component/layout/BoxLeft/MainWallet";

const bankList = [
  {
    value: "BKKB",
    label: "Bangkok Bank",
  },
  {
    value: "BAAC",
    label: "Bank for Agriculture and Agricultural Cooperatives	",
  },
  {
    value: "KSAB",
    label: "Bank of Ayudhya (Krungsri)",
  },
  {
    value: "CIMT",
    label: "CIMB Thai Bank",
  },
  {
    value: "GSBA",
    label: "Government Savings Bank	",
  },
  {
    value: "KSKB",
    label: "Kasikorn Bank	",
  },
  {
    value: "Kiatnakin Bank",
    label: "Kiatnakin Bank",
  },
  {
    value: "KTBA",
    label: "Krung Thai Bank	",
  },
  {
    value: "SCBB",
    label: "Siam Commercial Bank",
  },
  {
    value: "SCBT",
    label: "Standard Chartered Bank (Thai)",
  },
];

function withdrawNewTest({ history }) {
  const { t } = useTranslation();

  const user = store.get("user") || {};

  const [gameBalances, updateGameBalances] = useState([]);

  const [userInfo, setUserInfo] = useState(user);

  const [balance, setBalance] = useState(0);

  const [formAmount, setFormAmount] = useState(0);

  const [activeTab, setActiveTab] = useState("Withdraw");

  const [loading, withLoading] = useLoading(false);

  const [checKissBank, setChecKissBank] = useState("");

  const [kissvipBanks, setKissvipbanks] = useState([]);

  const [withdrawConfig, setWithdrawConfig] = useState([]);

  const [Dialog, showDialogWithMessage] = useDialog({
    title: t("WITH_SUC"),
  });

  const [depositOption, setDepositOption] = useState("");

  const [typeBank, setTypeBank] = useState("banking");

  const handlerShowBankSelected = (item) => {
    const id = item.id.toLocaleLowerCase() ===
    "bankTransfer".toLocaleLowerCase()
    ? 1
    : 2
    setDepositOption(item.id);
    setTypeBank(id === 2 ? "kissvippay" : "banking");
  };

  const handlerSelected = (item) => {
    const f = kissvipBanks.find((x) => x.id === item);
    setChecKissBank(f);
  };

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: t("WITH_ERR"),
    btnLabel: t("GOT_IT"),
    type: "error",
  });
  const onSubmit = async (payload) => {
    if (typeBank === "kissvippay" && !checKissBank.id)
      showDialogErrorWithMessage("Please select to bank");
    else
      try {
        const { user_id: userId, user_account_no: username } = userInfo;

        const submited = {
          amount: payload.amount,
          bank_name: payload.bank_name.replace("\t", ""),
          bankCode: payload.bankCode,
          to_bank_id: checKissBank.id,
          bank_account_name: payload.bank_account_name,
          bank_account_number: payload.bank_account_no,
          currency: config.currency,
          payment_method: "transaction",
          username,
        };
        console.log("~", submited);
        await withLoading(() =>
          http.post({ path: `users/${userId}/withdraw`, payload: submited })
        );
        // socket.emit('messages', {
        //   msgType: 'withdraw.request',
        //   data: {
        //     message: `Request withdraw from '${username}'`,
        //     title: 'Withdraw Request'
        //   }
        // })
        showDialogWithMessage(t("WITH_DONE"));
      } catch (error) {
        showDialogErrorWithMessage(error.message);
      }
  };

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      name: "",
      bank_account_name: userInfo.bank_account_name,
      bank_account_no: userInfo.bank_account_no,
      amount: 0,
    });
  }, [form, userInfo]);

  const handleSubmit = (values) => {
    if (Number(formAmount) < 50.0) return;
    if (Number(formAmount) > Number(balance)) return;
    onSubmit({
      ...values,
      bank_name: userInfo.bankName,
      bankCode: userInfo.bankCode,
      bank_account_name: userInfo.bankAccount,
      bank_account_no: userInfo.bankAccountNumber,
      amount: formAmount,
    });
  };

  const fetchBAnks = async () => {
    const [b, config] = await withLoading(() =>
      Promise.all([
        http.get({ path: `payment-kissvip/banks` }),
        http.get({ path: "config" }),
      ])
    );
    const _banks = b.accounts.filter((x) => x.status === "A");
    setKissvipbanks(_banks);
    setChecKissBank(_banks && _banks.length > 0 ? _banks[0] : "");

    const withdraw = JSON.parse(config.withdrawal).filter((x) => x.enabled);
    setWithdrawConfig(withdraw);
    if (withdraw && withdraw.length > 0) {
      setDepositOption(withdraw[0].id);
    }
  };

  React.useEffect(() => {
    fetchBAnks();
  }, []);

  const fetchAccount = async () => {
    const { user_id: userId } = user;
    const [userResp, gameBalancesResp] = await withLoading(() =>
      Promise.all([
        http.get({ path: "me" }),
        http.get({ path: `users/${userId}/balance-games` }),
      ])
    );
    updateGameBalances(
      gameBalancesResp
        .filter((i) => i)
        .filter(({ game_code: gameCode }) =>
          config.gamesAvailable.includes(gameCode)
        )
    );
    setUserInfo(userResp);

    store.set("user", userResp);

    let bl = userResp.user_money || 0;
    if (bl === 0) {
      gameBalancesResp
        .filter((x) => x.balance.game_code !== "asiagaming")
        .forEach((r) => {
          bl += Number(r.balance.balance || 0);
        });
    }

    store.set("userBalance", bl);

    store.set("userBalanceTime", Date.now());

    setBalance(bl);
  };

  const onPullOut = async () => {
    const { user_id: userId } = store.get("user");
    const tasks = [];
    gameBalances.forEach(({ gameId, balance, game_code }) => {
      if (balance > 1 && game_code !== "asiagaming") {
        const providerCode = game_code === "ag_galaxy" ? "?ProviderId=AG" : "";
        tasks.push(
          http.post({
            path: `users/${userId}/game/${gameId}/withdraw${providerCode}`,
            payload: {
              amount: balance,
            },
          })
        );
      }
    });
    await withLoading(() => Promise.all(tasks));
    fetchAccount();
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  return (
    <div className="content-body">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "180px",
            minHeight: "180px",
            textAlign: "center",
            marginTop: "80px",
          }}
          className="pro_title"
        >
          {t("LOADING")} ...
        </div>
      ) : (
        <div className="deposit-sec">
          <MenuItemBoxLeftMobile history={history} _pageName={"Withdrawal"} />
          <div className="af-container">
            <MenuItemBoxLeft
              history={history}
              _pageName={"Withdrawal"}
              promotion={userInfo.promotion}
            />
            <div className="afcolumn-right">
              <div className="afinner">
                <MainWallet
                  userInfo={userInfo}
                  balance={balance}
                  onPullOut={onPullOut}
                />
                <div className="frame-container">
                  <div className="aform-wrapper">
                    <div className="af-submenu-wrap">
                      <a
                        className={`afsub-btn ${
                          activeTab === "Withdraw" ? "active" : ""
                        }`}
                        href="/"
                        onClick={(e) => {
                          setActiveTab("Withdraw");
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <span className="aficon">
                          <img
                            className="w-24 h-24"
                            src="./images/icon/af-withdraw.png"
                            alt=""
                          />
                        </span>
                        <span>Withdraw</span>
                      </a>
                      {/* <a
                        href="/"
                        className={`afsub-btn ${
                          activeTab === "AddBank" ? "active" : ""
                        }`}
                        onClick={(e) => {
                          setActiveTab("AddBank");
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <span className="aficon">
                          <img
                            className="w-24 h-24"
                            src="images/icon/bank-transfer.png"
                          />
                        </span>
                        <span>Add Bank</span>
                      </a> */}
                    </div>

                    {activeTab === "Withdraw" &&
                      (withdrawConfig && withdrawConfig.length > 0 ? (
                        <>
                          <div className="af-submenu-wrap">
                            {withdrawConfig.map((item) => {
                              if (item.enabled) {
                                return (
                                  <a
                                    className={`afsub-btn ${
                                      depositOption.toLocaleLowerCase() === item.id.toLocaleLowerCase() ? "active" : ""
                                    }`}
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlerShowBankSelected(item);
                                      return false;
                                    }}
                                  >
                                    {item.id.toLocaleLowerCase() !==
                                      "bankTransfer".toLocaleLowerCase() && (
                                      <span className="aficon">
                                        <img
                                          className="w-24 h-24"
                                          src="images/icon/bank-transfer.png"
                                          alt=""
                                        />
                                      </span>
                                    )}
                                    <span>{item.name}</span>
                                  </a>
                                );
                              } else {
                                return <></>;
                              }
                            })}
                            {/* <a
                            className={`afsub-btn ${
                              depositOption === 1 ? "active" : ""
                            }`}
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              handlerShowBankSelected(1);
                              return false;
                            }}
                          >
                            <span>BANK TRANSFER</span>
                          </a>
                          <a
                            href="/"
                            className={`afsub-btn ${
                              depositOption === 2 ? "active" : ""
                            }`}
                            onClick={(e) => {
                              handlerShowBankSelected(2);
                              e.preventDefault();
                              return false;
                            }}
                          >
                            <span className="aficon">
                              <img
                                className="w-24 h-24"
                                src="images/icon/bank-transfer.png"
                                alt=""
                              />
                            </span>
                            <span>KISS PAY</span>
                          </a> */}
                          </div>
                          <Form form={form} onFinish={handleSubmit}>
                            <div className="depo-form-wrapper">
                              <div className="table-responsive wbank-table">
                                <table
                                  width="100%"
                                  className="history-table w-100p"
                                >
                                  {depositOption === 1 ? (
                                    <tbody>
                                      <tr className="hstop mt-2">
                                        <div
                                          className={
                                            userInfo.bankName === "Kbank"
                                              ? "styled-item-bank"
                                              : "styled-item-bank-active"
                                          }
                                        >
                                          <img
                                            className="style-bank"
                                            src={
                                              userInfo.bankName === "Kbank"
                                                ? "./images/logo/kbankactive.png"
                                                : "./images/logo/kbankunactive.png"
                                            }
                                            alt="bank"
                                          />
                                          <p className="withdraw-text">Kbank</p>
                                        </div>
                                      </tr>
                                      <tr className="hstop mt-2">
                                        <div
                                          className={
                                            userInfo.bankName === "Krungthai"
                                              ? "styled-item-bank"
                                              : "styled-item-bank-active"
                                          }
                                        >
                                          <img
                                            className="style-bank"
                                            src={
                                              userInfo.bankName === "Krungthai"
                                                ? "./images/logo/krungactive.png"
                                                : "./images/logo/krungunactive.png"
                                            }
                                            alt="bank"
                                          />
                                          <p className="withdraw-text">
                                            Krungthai
                                          </p>
                                        </div>
                                      </tr>
                                      <tr className="hstop mt-2">
                                        <div
                                          className={
                                            userInfo.bankName === "SCB"
                                              ? "styled-item-bank"
                                              : "styled-item-bank-active"
                                          }
                                        >
                                          <img
                                            className="style-bank"
                                            src={
                                              userInfo.bankName === "SCB"
                                                ? "./images/logo/acbactive.png"
                                                : "./images/logo/acbunactive.png"
                                            }
                                            alt="bank"
                                          />
                                          <p className="withdraw-text">SCB</p>
                                        </div>
                                      </tr>
                                      <tr className="hstop mt-2">
                                        <div
                                          className={
                                            userInfo.bankName === "Krungsri"
                                              ? "styled-item-bank"
                                              : "styled-item-bank-active"
                                          }
                                        >
                                          <img
                                            className="style-bank"
                                            src={
                                              userInfo.bankName === "Krungsri"
                                                ? "./images/logo/krungsriactive.png"
                                                : "./images/logo/krungsriunactive.png"
                                            }
                                            alt="bank"
                                          />
                                          <p className="withdraw-text">
                                            Krungsri
                                          </p>
                                        </div>
                                      </tr>
                                      <tr className="hstop mt-2">
                                        <div
                                          className={
                                            userInfo.bankName === "Bangkok Bank"
                                              ? "styled-item-bank"
                                              : "styled-item-bank-active"
                                          }
                                        >
                                          <img
                                            className="style-bank"
                                            src="./images/logo/bankokbank.png"
                                            alt="bank"
                                          />
                                          <p className="withdraw-text">
                                            Bangkok Bank
                                          </p>
                                        </div>
                                      </tr>
                                      <tr className="hstop mt-2">
                                        <div
                                          className={
                                            userInfo.bankName === "TTB"
                                              ? "styled-item-bank"
                                              : "styled-item-bank-active"
                                          }
                                        >
                                          <img
                                            className="style-bank"
                                            src={
                                              userInfo.bankName === "TTB"
                                                ? "./images/logo/ttbactive.png"
                                                : "./images/logo/ttbunactive.png"
                                            }
                                            alt="bank"
                                          />
                                          <p className="withdraw-text">TTB</p>
                                        </div>
                                      </tr>
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      {kissvipBanks.map((bank, ix) => (
                                        <tr className="hstop">
                                          <div
                                            key={ix}
                                            className={
                                              checKissBank.name === bank.name
                                                ? "styled-item-bank"
                                                : "styled-item-bank-active"
                                            }
                                            onClick={() =>
                                              handlerSelected(bank.id)
                                            }
                                          >
                                            <img
                                              className="style-bank"
                                              src={bank.logo_url}
                                              alt="bank"
                                            />
                                            <p className="withdraw-text">
                                              {bank.name}
                                            </p>
                                          </div>
                                        </tr>
                                      ))}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                              <div className="af-field-wrap">
                                <div className="label-col">
                                  Account Name
                                  <span className="redtext">*</span>
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <input
                                      type="text"
                                      className="afinput"
                                      value={userInfo.bankAccount}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="af-field-wrap">
                                <div className="label-col">
                                  Account No
                                  <span className="redtext">*</span>
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <input
                                      type="text"
                                      className="afinput"
                                      value={userInfo.bankAccountNumber}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="af-field-wrap">
                                <div className="label-col">
                                  Main Wallet Balance
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <input
                                      type="text"
                                      className="afinput"
                                      value={numeral(balance).format("0,0.00")}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="af-field-wrap">
                                <div className="label-col">
                                  Withdraw Amount
                                  <span className="redtext">*</span>
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <Form.Item name="amount">
                                      <input
                                        type="number"
                                        className="afinput allownumericwithdecimal"
                                        id="txt_amount"
                                        value={formAmount}
                                        placeholder="MIN 50.00"
                                        maxlength="6"
                                        autocomplete="off"
                                        onChange={(e) => {
                                          const amount = e.target.value;
                                          setFormAmount(amount);
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>

                              <div className="af-field-wrap">
                                <div className="label-col">
                                  Withdraw From
                                  <span className="redtext">*</span>
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <select
                                      disabled
                                      className="afinput"
                                      id="withdrawFrom"
                                    >
                                      <option
                                        value="wallet"
                                        selected="selected"
                                      >
                                        Main Wallet
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="af-field-submit">
                                <span
                                  className="xerrMsg"
                                  style={{ color: "lawngreen" }}
                                >
                                  *Daily Limit Balance:50,000/Daily Count
                                  Balance:10
                                  <br />
                                  <br />
                                </span>

                                <button
                                  type="submit"
                                  className="join-reg-btn btnwithdrawal cursor-pointer"
                                  disabled={
                                    Number(formAmount) < 50 ||
                                    Number(formAmount) > Number(balance)
                                  }
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </Form>
                        </>
                      ) : (
                        <div>System maintenance</div>
                      ))}

                    {activeTab === "AddBank" && (
                      <>
                        <div className="af-submenu-wrap">
                          <a
                            className="afsub-btn active"
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          >
                            <span>Bank</span>
                          </a>
                        </div>
                        <div className="depo-form-wrapper">
                          <div className="af-field-wrap">
                            <div className="label-col">
                              Bank Name<span className="redtext">*</span>
                            </div>
                            <div className="input-col">
                              <div className="af-field">
                                <select className="afinput" id="txt_bankname">
                                  {bankList.map((bank, index) => (
                                    <option key={index} value={bank.value}>
                                      {bank.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="af-field-wrap">
                            <div className="label-col">
                              Bank Branch<span className="redtext">*</span>
                            </div>
                            <div className="input-col">
                              <div className="af-field">
                                <input
                                  type="text"
                                  className="afinput"
                                  id="txt_bankbranch"
                                  value=""
                                  autocomplete="off"
                                  maxlength="50"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="af-field-wrap">
                            <div className="label-col">
                              Acc Holder<span className="redtext">*</span>
                            </div>
                            <div className="input-col">
                              <div className="af-field">
                                <input
                                  type="text"
                                  className="afinput"
                                  id="txt_accountholder"
                                  value="9797"
                                  readonly="readonly"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="af-field-wrap">
                            <div className="label-col">
                              Acc No.<span className="redtext">*</span>
                            </div>
                            <div className="input-col">
                              <div className="af-field">
                                <input
                                  type="text"
                                  className="afinput"
                                  id="txt_accountnumber"
                                  onkeydown="return numbersOnly(event);"
                                  onpaste="return false;"
                                  autocomplete="off"
                                  maxlength="30"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="af-field-submit" id="waddbank">
                            <button
                              type="submit"
                              className="join-reg-btn btnaddbank"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog />
      <ErrorDialog />
    </div>
  );
}

export default withdrawNewTest;
