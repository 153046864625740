import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import config from "config";
import store from "store";
import CompCommon from "feature/CompCommon";
import NewAccountMb from "feature/NewAccountMb";
import NewBankingMb from "feature/NewBankingMb";

import gameData from "../../constants/gameData";

const BottomNavigation = ({ history }) => {
  const { t } = useTranslation();
  const drawer = useRef(null);
  const menuGameElm = useRef(null);
  const [openMenu, updateOpenMenu] = useState(false);

  const [isShowCompBanking, setShowCompCommonBanking] = useState(false);

  const [isShowCompAcc, setShowCompCommonAcc] = useState(false);

  const user = store.get("user");

  const handleCloseDropdown = (event) => {
    const gameElm = menuGameElm.current;
    const drawerElm = drawer.current;
    if (
      gameElm &&
      !gameElm.contains(event.target) &&
      drawerElm &&
      !drawerElm.contains(event.target)
    ) {
      updateOpenMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdown);
    document.addEventListener("touchstart", handleCloseDropdown);
    return () => {
      document.addEventListener("click", handleCloseDropdown);
      document.addEventListener("touchstart", handleCloseDropdown);
    };
  }, []);

  const handleRedirect = (url) => {
    updateOpenMenu(false);
    window.location.href = url;
  };

  return (
    <>
    <section className="mobile-btm-menu">
    <div className="border-menu"></div>
    <div className="btm-menu">
        <a href="/" className="mb-menulink">
            <img className="w-auto" src="images/icon/home-nav-icon.png" alt=""/>
            <div>Home</div>
        </a>
        <a href="/" className="mb-menulink"  onClick={(e) => { e.preventDefault(); alert("Comming Soon")}}>
            <img className="w-auto" src="images/icon/mb-promo.png" alt=""/>
            <div>Promo</div>
        </a>
        
        
		<a href="/" className="mb-menulink" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}}>
        
			<div className="wheel-icon w-auto"><img src="images/reward-center/rewardcenter-icon.png" alt=""/></div>
			<div>Reward Center</div>
		</a>
        <a href="/" className="mb-menulink" target="_blank" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}}>
            <img className="w-auto" src="images/icon/mb-whatsapp.png" alt=""/>
            <div>Whatsapp</div>
        </a>
        <a href="/" className="mb-menulink" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}}>
            <img className="w-auto" src="images/icon/mb-livechat.png" alt=""/>
            <div>Live Chat</div>
        </a>
    </div>
</section>
      {/* <div
        ref={drawer}
        className={`show_ipad menuMb-game ${openMenu ? 'show' : ''}`}
      >
        <div className="menu_sb2">
          <span className="menu_sb2_title">{t('GAME')}</span>
          {gameData.bodyContents
            .filter(({ ACL }) => config.market.includes(ACL))
            .map(({ title, route, icon }) => (
              <a
                key={title}
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handleRedirect(route);
                  return false;
                }}
                className="acc-pointerive text-white"
              >
                <span className={icon}></span>
                {t(title)}
              </a>
            ))}
          <a
            href="/download"
            className="acc-pointerive text-white"
          >
            <span className="icon_menu8"></span>
            {t('DOWNLOAD')}
          </a>
        </div>
      </div>
      <div className="show_ipad menuMb">
        <div className="w_1220">
          <div
            className="menu_mb display_flex"
            style={{ position: 'relative' }}
          >
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                if (isShowCompAcc) setShowCompCommonAcc(!isShowCompAcc)
                if (isShowCompBanking)
                  setShowCompCommonBanking(!isShowCompBanking)
                history.push('/home')
                return false
              }}
              className="menu_item menu_item3 text-white"
            >
              {!isShowCompAcc && !isShowCompBanking ? (
                <img src="images/icon/homee.png" alt="" />
              ) : (
                <img src="images/icon/homeblack.png" alt="" />
              )}

              <p style={{ margin: 0 }}>{t('HOME')}</p>
            </a>
            <a
              style={{ marginRight: 20 }}
              href="/"
              onClick={(e) => {
                e.preventDefault()
                user && user.user_id
                  ? history.push('/histories')
                  : // ? onSetShow(true, 'banking')
                    history.push('/home')
                return false;
              }}
              className="menu_item menu_item3 text-white"
            >
              {!isShowCompBanking && (
                <img src="images/icon/historynavi.png" alt="" />
              )}
              {isShowCompBanking && (
                <img src="images/icon/history.png" alt="" />
              )}
              <p style={{ margin: 0 }}>{t('HISTORY')}</p>{' '}
            </a>
            <a
              style={{
                position: 'absolute',
                left: '40%',
                bottom: 0.5
              }}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                user && user.user_id
                  ? history.push('/deposit')
                  : history.push('/home')
                return false;
              }}
              className="menu_item menu_item3 text-white"
            >
              <img
                style={{ width: 70 }}
                src="images/icon/deposit1.png"
                alt=""
                className="filter-unset"
              />
            </a>
            <a
              style={{ marginLeft: 20 }}
              href={"/account"}
              className="menu_item menu_item3 text-white"
              id="myInfo"
            >
              {!isShowCompAcc && <img src="images/icon/accBlack.png" alt="" />}
              {isShowCompAcc && <img src="images/icon/myInfo.png" alt="" />}
              <p style={{ margin: 0 }}>{t('ACCOUNT')}</p>{' '}
            </a>
            <a
              href="/"
              className="menu_item menu_item3"
            >
              <img src="images/icon/chat.png" alt="" />
              <p style={{ margin: 0 }}>{t('Live Chat')}</p>{' '}
            </a>
          </div>
        </div>
      </div>
      <CompCommon
        title={'My Accounts'}
        show={isShowCompAcc}
        close={() => {
          setShowCompCommonAcc(!isShowCompAcc)
        }}
      >
        <NewAccountMb />
      </CompCommon>
      <CompCommon
        title={'Banking'}
        show={isShowCompBanking}
        close={() => {
          setShowCompCommonBanking(!isShowCompBanking)
        }}
      >
        <NewBankingMb />
      </CompCommon> */}
    </>
  );
};

export default withRouter(BottomNavigation);
