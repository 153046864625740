import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
// import { useTranslation } from 'react-i18next'
import styles from "./style";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import copy from "copy-to-clipboard";
import numeral from "numeral";
import http from 'service/http'
import component from 'component'
import queryString from 'query-string'


const { useDialog, useLoading, Header } = component

function home({
  account,
  password,
  balance,
  deeplink,
  route,
  classes,
  history,
  html5,
  game
}) {
  const [openGame, setOpenGame] = React.useState(0);
  const [Dialog, showDialog] = useDialog({
    title: 'Casino',
    type: 'error'
  })
  const [loading, withLoading] = useLoading()
  const copy2Clipboard = (text) => {
    copy(text);
    // alert('Has been copied: \n' + text)
  };
  const login = async () => {
    try {
      const gameList = await http.get({ path: 'games' })
      if (gameList.find(({ id }) => id === 33)) {
        let Platform = 'D'
        if( navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
        ){
          Platform = 'M'
        }
        const { url } = await withLoading(() => http.get({ path: `games/33/login`, params: { Platform, GameType: 'Casino' } }))
        window.location = url
        return 1
        // return updateLoginUrl(url)
      }
      return showDialog('Casino is comming')
    } catch (error) {
      showDialog(error.message)
    }
  }
  React.useEffect(() => {
    setTimeout(() => {
      if (deeplink !== "") {
        const e = document.getElementById("deeplink");

        if (e && openGame === 0) {
          console.log(e);
          e.click();
          setOpenGame(1);
        }
      }
    }, 2000);
  }, []);
  return (
    <React.Fragment>
      <Typography
        gutterBottom
        variant="h5"
        component="h2"
        style={{ fontWeight: "bold", color: "#ffaf50" }}
      >
        Account: {account}
        <FileCopyOutlined
          className={classes.filecoppy}
          onClick={() => copy2Clipboard(account)}
        />
      </Typography>
      {/* <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold', color: '#ffaf50' }}>
          Password: {password}
        <FileCopyOutlined className={classes.filecoppy} onClick={() => copy2Clipboard(password)}
        />
      </Typography> */}

      <Typography
        gutterBottom
        variant="h5"
        component="h2"
        style={{ fontWeight: "bold", color: "#ffaf50" }}
      >
        Balance: {numeral(balance).format("0,0.00")}
      </Typography>
      {!html5 && deeplink ? (
        <btn className="link-btn-default" id="deeplink" onClick={login}>
          Play now
        </btn>
      ) : !html5 && !deeplink ? (
        <Button
          onClick={login}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          <strong>Play now</strong>
        </Button>
      ) : (
        <div>
          <btn style={{ margin: 3 }} className="link-btn-default" onClick={login}>
            Play HTML5
          </btn>
          <btn
            style={{ margin: 3 }}
            className="link-btn-default"
            id="deeplink"
            onClick={login}
          >
            Play now
          </btn>
        </div>
      )}
    </React.Fragment>
  );
}
export default withStyles(styles)(home);
