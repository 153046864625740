import React from 'react'
import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom'

import Layout from './component/layout'
import Home from './feature/NewHome'
import Transfer from './feature/transfer'
import Comm from './feature/Comm'
import Topup from './feature/topup'
import Casino from './feature/casino'
import Fishing from './feature/fishing'
import Slot from './feature/slot'
import Lottery from './feature/Lottery'
import Sports from './feature/sports'
import VirtualSports from './feature/VirtualSports'
import Withdraw from './feature/withdraw'
import Register from './feature/register'
import Account from './feature/account'
import Download from './feature/download'
import Histories from './feature/histories'
import Report from './feature/report'
import Playtech from './feature/Playtech/login'
import PlaytechApp from './feature/Playtech/app'
import Maxbet from './feature/maxbet'
import M8 from './feature/m8'
import M9 from './feature/m9'
import WE from './feature/we'
import Ssports from './feature/Ssports'
import Msports from './feature/Msports'
import Spade from './feature/spade'
import SpadeFishing from './feature/spade/fishing'
import Live22 from './feature/Live22'
import BG from './feature/BG'
import CG from './feature/CG'
import Rich88 from './feature/Rich88'
import BGFH from './feature/BG/FH'
import SEXY from './feature/Sexy'
import ESPORTS from './feature/Sexy/Esports'
import JILISLOT from './feature/Sexy/JiliSlot'
import JILITABLE from './feature/Sexy/JiliTable'
import JILIFISH from './feature/Sexy/JiliFish'
import AWS from './feature/Sexy/AWS'
import AWSSLOT from './feature/Sexy/AWSSLOT'
import JDB from './feature/Sexy/JDB/slot'
import JDBFISH from './feature/Sexy/JDB'
import VENUS from './feature/Sexy/Venus'
import BIGGAMING from './feature/Sexy/BIGGAMING'
import PG from './feature/Sexy/PG'
import PGSLOT from './feature/Sexy/PG/slot'
import AGSlot from './feature/asiangaming'
import AGCS from './feature/asiangamingCS'
import MUAYSTEP2 from './feature/ESportsComp/MuayStep2'
import SboGalaxy from './feature/SBOGalaxy'
import AGGalaxy from './feature/AGGalaxy'
import CMD from './feature/CMD'
import Ace333 from './feature/Ace333'
import SboGames from './feature/SboGames'
import Joker from './feature/joker'
import JokerFish from './feature/jokerFishing'
import Allbet from './feature/allbet'
import Newwin from './feature/Newwin'
import Evolution from './feature/Evolution'
import Lotto97 from './feature/97lotto'
import NagaID from './feature/97lotto/nagaid'
import http from 'service/http'
import store from 'store'
import NewAccountMb from './feature/NewAccountMb'
import NewDeposit from './feature/NewDeposit'
import './i18n'
import './App.css'
import 'antd/dist/antd.css'
import withdrawNewTest from './feature/withdrawNewTest'
import NewHistoriesNewTest from './feature/NewHistoriesNewTest'
import NewDepositNewTest from './feature/NewDepositNewTest'
import Promos from './feature/Promos'
import { useDispatch, useSelector } from 'react-redux'
import { showLoginModal } from './store/modalSlice'

function Auth() {
  return (
    // <MuiThemeProvider theme={theme}>
    <Router>
      <Layout>
        <Route path="/login" component={Home} />
        <Route path="/promos" component={Promos} />
        <PrivateRoute path="/slot" component={Slot} />
        <Route path="/ace333" component={Ace333} />
        <PrivateRoute path="/casino" component={Casino} />
        <Route path="/fishing" component={Fishing} />
        <PrivateRoute path="/lottery" component={Lottery} />
        <Route path="/muaystep2" component={MUAYSTEP2} />
        <PrivateRoute path="/sports" component={Sports} />
        <Route path="/virtual-sports" component={VirtualSports} />
        <Route path="/register" component={Register} />
        <Route path="/signplaytech" component={PlaytechApp} />
        <PrivateRoute path="/playtech/login" component={Playtech} />
        <PrivateRoute
          path="/authenticate/playtech/:code"
          component={Playtech}
        />
        <PrivateRoute path="/authenticate/spade/:code" component={Spade} />
        <PrivateRoute path="/spadegaming" component={Spade} />
        <PrivateRoute path="/spadegaming-fishing" component={SpadeFishing} />
        <PrivateRoute path="/allbet" component={Allbet} />
        <PrivateRoute path="/newwin" component={Newwin} />
        <PrivateRoute path="/evolution-games" component={Evolution} />
        <PrivateRoute path="/97lotto" component={Lotto97} />
        <PrivateRoute path="/nagaid" component={NagaID} />
        <PrivateRoute path="/maxbet" component={Maxbet} />
        <PrivateRoute path="/live22" component={Live22} />
        <PrivateRoute path="/jdb" component={JDB} />
        <PrivateRoute path="/bg" component={BG} />
        <PrivateRoute path="/creative-gaming" component={CG} />
        <PrivateRoute path="/rich88" component={Rich88} />
        <PrivateRoute path="/bg-fh" component={BGFH} />
        <PrivateRoute path="/asiagaming-cs" component={AGCS} />
        <PrivateRoute path="/asiagaming-slot" component={AGSlot} />
        <PrivateRoute path="/asiagaming" component={AGGalaxy} />
        <PrivateRoute path="/cmd" component={CMD} />
        <PrivateRoute path="/sbo" component={SboGalaxy} />
        <PrivateRoute path="/sbo-games" component={SboGames} />
        <PrivateRoute path="/m9" component={M9} />
        <PrivateRoute path="/m8" component={M8} />
        <PrivateRoute path="/we" component={WE} />
        <PrivateRoute path="/ssports" component={Ssports} />
        <PrivateRoute path="/msports" component={Msports} />
        <PrivateRoute path="/joker" component={Joker} />
        <PrivateRoute path="/joker-fishing" component={JokerFish} />
        <PrivateRoute path="/sexy-baccarat" component={SEXY} />
        <PrivateRoute path="/authenticate/sexy/:code" component={SEXY} />
        <PrivateRoute path="/venus" component={VENUS} />
        <PrivateRoute path="/big-gaming" component={BIGGAMING} />
        <PrivateRoute path="/esports" component={ESPORTS} />
        <PrivateRoute path="/jili-slot" component={JILISLOT} />
        <PrivateRoute path="/jili-table" component={JILITABLE} />
        <PrivateRoute path="/jili-fish" component={JILIFISH} />
        <PrivateRoute path="/jdbfish" component={JDBFISH} />
        <PrivateRoute path="/aws" component={AWS} />
        <PrivateRoute path="/aws-slot" component={AWSSLOT} />
        <PrivateRoute path="/pg" component={PG} />
        <PrivateRoute path="/authenticate/pg/:code" component={PG} />
        <PrivateRoute path="/pg-slot" component={PGSLOT} />
        <PrivateRoute path="/authenticate/pg-slot/:code" component={PGSLOT} />
        <PrivateRoute path="/awc/:provider/:type" component={SEXY} />
        <Route path="/home" component={Home} />
        <PrivateRoute path="/topup" component={Topup} />
        {/*<PrivateRoute path="/deposit" component={Home} />*/}
        {/* <PrivateRoute path="/deposit" component={Deposit} /> */}
        <PrivateRoute path="/deposit-new-test" component={NewDeposit} />
        <PrivateRoute path="/deposit" component={NewDepositNewTest} />
        <PrivateRoute path="/transfer" component={Transfer} />
        <PrivateRoute path="/comm" component={Comm} />
        <PrivateRoute path="/withdraw-new-test" component={Withdraw} />
        <PrivateRoute path="/withdraw" component={withdrawNewTest} />
        <Route exact path="/" component={Home} />
        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute path="/new-acc-mb" component={NewAccountMb} />
        <PrivateRoute path="/download" component={Download} />
        <PrivateRoute path="/histories-new-test" component={Histories} />
        <PrivateRoute path="/histories" component={NewHistoriesNewTest} />
        <PrivateRoute path="/reports" component={Report} />
        <PrivateRoute path="/deposit-mb" component={NewDeposit} />
      </Layout>
    </Router>
    // </MuiThemeProvider>
  )
}

function PrivateRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();

  const token = store.get('token')

  if (token) {
    http.setJwtToken(token)
  }

  const userStorage = store.get('user')

  const isVisible = useSelector((state) => state.modal.loginModalVisible);
  
  return (
    <Route
      {...rest}
      render={props => {
          if((!token || !userStorage) && !isVisible)  dispatch(showLoginModal());
          return (
            token && userStorage ? (
              <Component {...props} />
            ) : (
              <Redirect to="/home" />
            )
          )
        }
      }
    />
  )
}

export default Auth
