import React from "react";
import "./Styles.css";
import store from "store";
import { Modal } from "antd";
import { showLoginModal } from "../../store/modalSlice";
import { useDispatch } from "react-redux";

const ModalHeader = ({ isShowMenu, history, onShowMenu, promotion }) => {
  const dispatch = useDispatch();
  const user = store.get("user") || {};

  return (
    <Modal
      open={isShowMenu}
      onCancel={() => {
        onShowMenu(false);
      }}
      footer={null}
      closable={false}
      closeIcon=""
      centered
      className="modal-menu"
    >
      <div
        className={`modal fade ${isShowMenu ? "show" : ""}`}
        id="mobile-menu"
        style={{ zIndex: "1040", display: isShowMenu ? "block" : "none" }}
        aria-modal="true"
      >
        <div className="modal-dialog mobile-menu">
          <div className="modal-content mobile-menu">
            <button
              type="button"
              className="close-menu cursor-pointer"
              data-dismiss="modal"
              onClick={() => onShowMenu(false)}
            >
              <svg
                fill-rule="evenodd"
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="close"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
              </svg>
            </button>
            <div className="menu-top-wrapper">
              <div className="dropdown language mobile">
                <button className="flag-btn">
                  <img src="./images/home/EN.png" alt="" />
                </button>
              </div>
              <div className="menu-logo">
                <img src="./images/icon/Logo_Kiss.png" alt="" />
              </div>
            </div>
            {user && user.user_account_no ? (
              <div className="welcome-player">
                <div className="mb-welcome-user">{user.user_account_no}</div>
                <div className="btn-top mobile">
                  <button className="join-btn cursor-pointer" onClick={() => {
                    onShowMenu(false);
                    history.push("/deposit")}
                  }>
                    Wallet
                  </button>
                </div>

                <div className="player-vip-m">
                  <div className="vip-user-badge">
                    <img src="./images/menu/Normal.png" alt="" />
                  </div>
                  <div className="vip-user-text">
                    <div className="hiuser">
                      <div className="mblvl-text-1">
                        Member Level{" "}
                        <a href="/" onClick={(e) => { e.preventDefault(); history("/account")}} className="info-vip">
                          <i className="fas fa-info-circle"></i>
                        </a>
                      </div>
                      <div className="mblvl-text-2">NORMAL</div>
                      {promotion && (
                        <div className="mblvl-text-1">{promotion.current}/{promotion.targetTurnover}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="welcome-player">
                <div className="btn-top mobile">
                  <button className="join-btn cursor-pointer" onClick={() => {
                    onShowMenu(false);
                    history.push("/register")}
                  }>
                    Join Now
                  </button>
                  <button
                    className="login-btn cursor-pointer"
                    data-toggle="modal"
                    data-target="#login"
                    onClick={() => dispatch(showLoginModal())}
                  >
                    Login
                  </button>
                </div>
              </div>
            )}
            <div className="menu-box-wrapper">
              <a href="/" className="mb-menu-link">
                <img
                  className="w-30 h-30"
                  src="images/icon/mobile-home.png"
                  alt=""
                />
                <div className="menubtext">Home</div>
              </a>
              <a href="/sports" className="mb-menu-link">
                <img
                  className="w-30 h-30"
                  src="images/icon/mobile-sports.png"
                  alt=""
                />
                <div className="menubtext">Sports</div>
              </a>
              <a href="/casino" className="mb-menu-link">
                <img
                  className="w-30 h-30"
                  src="images/icon/mobile-casino.png"
                  alt=""
                />
                <div className="menubtext">Casino</div>
              </a>
              <a href="/slot" className="mb-menu-link">
                <img
                  className="w-30 h-30"
                  src="images/icon/mobile-slots.png"
                  alt=""
                />
                <div className="menubtext">SLOT</div>
              </a>
              <a href="/lottery" className="mb-menu-link">
                <img
                  className="w-30 h-30"
                  src="images/icon/mobile-lottery.png"
                  alt=""
                />
                <div className="menubtext">Lottery</div>
              </a>
              <a href="/promos" className="mb-menu-link">
                <img className="w-auto" src="images/icon/mobile-promo.png" alt=""/>
                <div className="menubtext">Promos</div>
              </a>
            </div>
            <div className="mobileside-nav-wrapper">
              <ul className="nav mobileside-nav">
                <li className="nav-item">
                  <a
                    className="nav-mlink expand-arrow collapsed"
                    data-toggle="collapse"
                    href="#infoCenter"
                  >
                    <span className="ms-icon">
                      <img className="w-30 h-30" src="images/icon/mobile-info.png" alt="" />
                    </span>
                    <span>Info Center</span>
                  </a>
                  <div className="collapse" id="infoCenter">
                    <div className="submenu-box">
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        Terms &amp; Conditions
                      </a>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        Privacy Policy
                      </a>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        Banking
                      </a>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        Contact Us{" "}
                      </a>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        target="_blank"
                      >
                        Help
                      </a>
                    </div>
                  </div>
                </li>
                {user && user.user_account_no && (
                  <li className="nav-item">
                    <a
                      className="nav-mlink"
                      href="/"
                      alt=""
                      onClick={(e) => {
                        e.preventDefault();
                        store.clearAll();
                        history.push("/");
                        return false;
                      }}
                    >
                      <span className="ms-icon">
                        <img className="w-30 h-30" src="images/icon/mobile-logout.png"  alt=""/>
                      </span>
                      <span>Logout</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="social-side">
              <div className="qbtitle-1">Connect with Us :</div>
              <div className="social-wrap">
                <a
                  href="https://www.facebook.com/"
                  className="social-link"
                  target="_blank"
                  rel="noopener_noreferrer"
                >
                  <div className="round-border">
                    <img src="./images/footer/facebook.svg" alt="Facebook" />
                  </div>
                </a>
                <a
                  // href="https://www.youtube.com/@EUWINOfficial"
                  href="/"
                  className="social-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="round-border">
                    <img src="./images/footer/youtube.svg" alt="YouTube" />
                  </div>
                </a>
                <a
                  // href="http://EUWIN2.wasap.my"
                  href="/"
                  target="_blank"
                  className="social-link"
                  rel="noreferrer"
                >
                  <div className="round-border">
                    <img src="./images/footer/instagram.svg" alt="WeChat" />
                  </div>
                </a>
                <a
                  // href="weixin://dl/chat?euwin_csd"
                  href="/"
                  className="social-link"
                  target="_blank"
                >
                  <div className="round-border">
                    <img src="./images/footer/twitter.svg" alt="Email" />
                  </div>
                </a>
                <a
                  // href="mailto:cs@euwin.com"
                  className="social-link"
                  target="_blank"
                  href="/"
                >
                  <div className="round-border">
                    <img src="./images/footer/twitter.svg" alt="Email" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalHeader;
