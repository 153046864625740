/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button,
} from "@material-ui/core";
import { PropagateLoader } from "react-spinners";
import Header from "component/header";
import CloseIcon from "@material-ui/icons/Close";
import { map, isEmpty } from "ramda";
import Bottom from "component/bottom";
import { useTranslation } from "react-i18next";
import store from "store";
import http from "service/http";
import useLoading from "../loading/hook";
import styles from "./style";
import SLOTS from "./constant";
import config from "config";
import queryString from "query-string";
import GameAccount from "../../component/gameAccount";
import TotalPlay from "../../component/layout/TotalPlay";
import Head from "../../component/layout/Head";
import Testimonials from "../../component/layout/Testimonials";
import LiveCasinoComp from "feature/LiveCasinoComp";
import { useDispatch } from "react-redux";
import { showLoginModal } from "../../store/modalSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    20: `evo888android://lobbyevoandroid?account=${account}&password=${password}`,
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: "",
    2: "",
    1: "",
  };

  return proxy[id];
};

function home({ history, classes }) {
  const dispatch = useDispatch();

  const {
    location: { search },
  } = history;

  const [loading, withLoading] = useLoading(false);

  const [gameSelected, updateGameSelected] = React.useState({});
  
  const { game } = queryString.parse(search);

  const [games, setGames] = React.useState([]);

  const { user_id: userId } = store.get("user") || { user_id: undefined };

  const gamesSetup = async () => {
    const gameList = await http.get({ path: "games" });
    setGames(gameList);
    return gameList;
  };

  const loginToGame = async (gameInfo, gameList = null) => {
    if (!userId) {
      alert("Please Login!");
      return;
    }
    const { src, label, route, id, redirect, target } = gameInfo;
    if (target === "_blank") {
      console.log("route", route);
      window.open(route);
    }
    if (redirect) {
      history.push(route);
      return;
    }
    console.log(
      id,
      games.find(({ id: gameId }) => id === gameId)
    );
    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    updateGameSelected({ label, src, route, id });
    const {
      balance,
      account,
      err,
      game_password: password,
    } = await withLoading(() =>
      http
        .post({
          path: `users/${userId}/game/logout`,
          payload: {
            gameLoginId: id,
          },
        })
        .catch((error) => {
          if (error.message !== "Not enough money !") {
            throw new Error({ message: error.message });
          }
          return true;
        })
        // .then(() => deeplink || [10,4, 14].includes(id) ? true : http.get({ path: `games/${id}/login` }))
        .then(() => http.get({ path: `users/${userId}/games/${id}/balance` }))
    ).catch((error) => {
      return { err: error.message };
    });

    const outDeeplink = generateDeeplink({ id, account, password });

    updateGameSelected({
      label,
      src,
      route,
      id,
      balance: balance ? balance.balance : 0,
      account: balance ? balance.account : "",
      err,
      deeplink: outDeeplink,
      password,
    });
    if (!outDeeplink && outDeeplink !== "") history.push(route);
  };

  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` });
    }
    async function fetchGame() {
      const gameList = await gamesSetup();
      // console.log(gameList)
      if (game) {
        const gameInfo = SLOTS.find((x) => x.code === game);
        loginToGame(gameInfo, gameList);
      }
    }
    fetchGame();
  }, []);

  const handleClose = () => {
    updateGameSelected({});
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="casino-sec">
        <div className="max-container">
          <div className="sport-container">
            <div className="tab-content" id="casinoContent">
              <div className="tab-pane show active" id="evolution">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/evolution-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/evolution.png"
                        alt="Evolution Gaming Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() =>
                          loginToGame(SLOTS.find((x) => x.id === 15))
                        }
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="playtech">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-1-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-1-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/playtech-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/playtech.png"
                        alt="Playtech Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() =>
                          loginToGame(SLOTS.find((x) => x.id === 4))
                        }
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="pragmatic">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/pragmatic-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/pragmatic.png"
                        alt="Pragmatic Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="vivo">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/vivo-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/vivo.png"
                        alt="Vivo Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="allbet">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-1-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-1-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/allbet-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/allbet.png"
                        alt="Allbet Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="gameplay">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/gameplay-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/gameplay.png"
                        alt="Gameplay Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="pretty">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-1-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-1-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/pretty-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/pretty.png"
                        alt="Pretty Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="wmcasino">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/wmcasino-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/wmcasino.png"
                        alt="Wm Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() =>
                          loginToGame(SLOTS.find((x) => x.id === 33))
                        }
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="biggaming">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/biggaming-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/biggaming.png"
                        alt="Biggaming Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="dreamgaming">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-1-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-1-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/dreamgaming-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/dreamgaming.png"
                        alt="Dreamgaming Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        classNam
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="worldentertainment">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/worldentertainment-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/worldentertainment.png"
                        alt="Worldentertainment Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="microgaming">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/microgaming-img.png"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        src="images/casino/microgaming.png"
                        alt="Microgaming Live Casino"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="sexybaccarat">
                <div className="lcfloat deco-1">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-1.png"
                  />
                </div>
                <div className="lcfloat deco-2">
                  <img
                    className="w-auto"
                    alt=""
                    src="images/casino/c-deco-set-2-2.png"
                  />
                </div>
                <div className="game-inner-wrap">
                  <div className="spimg">
                    <img
                      className="w-auto"
                      alt=""
                      src="images/casino/sexy-img.png?3"
                    />
                  </div>
                  <div className="spcolumn">
                    <div className="pvdlogo">
                      <img
                        className="w-auto"
                        alt=""
                        src="images/casino/sexy-logo.png?3"
                      />
                    </div>
                    <div className="sport-text">
                      Make it easy for your players to enjoy the world’s
                      favourite Live Casino games!
                    </div>
                    <div className="play-btn-wrap">
                      <button
                        className="play-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Bet Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ul className="nav nav-game casino" id="casinoTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#evolution"
                >
                  <img
                    alt=""
                    src="images/casino/evolution-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/evolution-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#playtech">
                  <img
                    alt=""
                    src="images/casino/playtech-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/playtech-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#pragmatic">
                  <img
                    alt=""
                    src="images/casino/pragmatic-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/pragmatic-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#vivo">
                  <img
                    alt=""
                    src="images/casino/vivo-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/vivo-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#allbet">
                  <img
                    alt=""
                    src="images/casino/allbet-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/allbet-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#gameplay">
                  <img
                    alt=""
                    src="images/casino/gameplay-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/gameplay-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#pretty">
                  <img
                    alt=""
                    src="images/casino/pretty-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/pretty-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#wmcasino">
                  <img
                    alt=""
                    src="images/casino/wmcasino-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/wmcasino-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#biggaming">
                  <img
                    alt=""
                    src="images/casino/biggaming-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/biggaming-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#dreamgaming">
                  <img
                    alt=""
                    src="images/casino/dreamgaming-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/dreamgaming-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#microgaming">
                  <img
                    alt=""
                    src="images/casino/microgaming-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/microgaming-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="tab"
                  href="#worldentertainment"
                >
                  <img
                    src="images/casino/worldentertainment-gold.png"
                    className="nmimg w-auto"
                    alt=""
                  />
                  <img
                    src="images/casino/worldentertainment-sm.png"
                    className="acvimg w-auto"
                    alt=""
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="tab" href="#sexybaccarat">
                  <img
                    alt=""
                    src="images/casino/sexy-gold.png"
                    className="nmimg w-auto"
                  />
                  <img
                    alt=""
                    src="images/casino/sexy-sm.png"
                    className="acvimg w-auto"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Dialog
        fullScreen
        open={!isEmpty(gameSelected)}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {gameSelected.label}
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            background: "#23232A",
            padding: 25,
            marginTop: 70,
            height: "calc(100vh - 70px)",
            overflow: "auto",
          }}
        >
          <Card
            style={{
              background: "none",
              border: "1px solid #ffaf50",
              borderRadius: "16px",
              maxWidth: "400px",
              margin: "0 auto",
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                className={classes.media}
                image={gameSelected.src}
                title="Contemplative Reptile"
              />
              <CardContent style={{ textAlign: "center" }}>
                {gameSelected.err ? (
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ fontWeight: "bold", color: "#ffaf50" }}
                  >
                    {gameSelected.err}
                  </Typography>
                ) : loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 15,
                    }}
                  >
                    <PropagateLoader
                      sizeUnit="px"
                      size={20}
                      color="#f50057"
                      loading={loading}
                    />
                  </div>
                ) : (
                  <GameAccount
                    account={gameSelected.account}
                    password={gameSelected.password}
                    deeplink={gameSelected.deeplink}
                    route={gameSelected.route}
                    balance={gameSelected.balance}
                  ></GameAccount>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Dialog>
      <section className="footer-sec pm-detail-wrap">
        <div className="max-container">
          <div className="footer-seo">
            <h1 style={{color: '#787b8b'}}>
              Play Best Live Casino Games Thailand- Pragmatic Play, Evolution
              Gaming, Live Roulette
            </h1>
            <p style={{color: '#787b8b'}}>
              Thailand best live dealer casino, play live roulette, live
              blackjack, live baccarat, live poker and much more here. Whatever
              you want is here!
            </p>
            <p style={{color: '#787b8b'}}>
              In recent years, the popularity of Online Live Casino Thailand
              games has skyrocketed, especially in Thailand. The convenience of
              being able to play at your favorite slot games on your mobile
              phone has made it even more accessible to avid players. If you as
              a casino player are a fan of Live Casino Games Thailand and reside
              in Thailand, you are in luck. In this article, we will be
              discussing the top mobile slot games available in Thailand.
            </p>
            <h2 style={{color: '#787b8b'}}>Thailand Live Casino Online</h2>
            <p style={{color: '#787b8b'}}>
              Thailand Live Casino Online are readily available for anyone who
              wants to play. Online casinos offer the best slot game titles,
              with excellent graphics and a variety of themes to choose from.
              Players can access these games on their desktop, laptop, or mobile
              devices, making it more convenient to play anywhere and anytime.
            </p>
            <h2 style={{color: '#787b8b'}}>Live Roulette Online Thailand</h2>
            <p style={{color: '#787b8b'}}>
              Live roulette online in Thailand offers players an immersive
              gaming experience that replicates the thrill of playing at a
              physical casino. The live stream is high quality and the interface
              is easy to use, making it a great option for both new and
              experienced players.
            </p>
            <h2 style={{color: '#787b8b'}}>Playtech Casino Thailand</h2>
            <p style={{color: '#787b8b'}}>
              Playtech is a leading provider of online gaming software and has a
              strong presence in the Thailandn market. Playtech offers a range
              of exciting online roulette games with different variations and
              features to suit every player's preference. Some of their popular
              titles include Age of the Gods Roulette, Premium Roulette Pro, and
              European Roulette.
            </p>
            <h2 style={{color: '#787b8b'}}>Pragmatic Play Thailand</h2>
            <p style={{color: '#787b8b'}}>
              Pragmatic Play is another top software provider in the online
              gaming industry and is known for its innovative and high-quality
              games. Their online roulette games feature modern graphics and
              user-friendly interfaces, making them a popular choice among
              players in Thailand. Some of their top roulette titles include
              Roulette Crystal, Roulette Azure, and Roulette Titanium.
            </p>
            <h2 style={{color: '#787b8b'}}>Mobile Thailand Live Online Casino</h2>
            <p style={{color: '#787b8b'}}>
              Mobile <strong>Thailand Live Online Casino</strong> are the most
              popular way to play slots online. It is incredibly convenient and
              easy to access on your mobile device, and there are many different
              types of mobile slot games available in Thailand. Some of the
              popular games include Pussy888, to name a
              few.
            </p>
            <h2 style={{color: '#787b8b'}}>Evolution Gaming Live Casino Thailand</h2>
            Online <strong>Evolution Gaming Live Casino Thailand</strong> are an
            excellent way to enjoy the excitement of a casino game from the
            comfort of your home. Casino Live Thailand offer players the chance
            to win big jackpots while enjoying the best graphics and gameplay
            available. With a vast selection of{" "}
            <strong>Live Casino Online Thailand</strong> games to choose from,
            players can easily find a slot game that fits their taste.
            <h3 style={{color: '#787b8b'}}>Q: Is it legal to play online casino games in Thailand?</h3>
            <p style={{color: '#787b8b'}}>
              A: Gambling is illegal in Thailand, but players can still access
              online casinos and slot games through offshore websites. Players
              should always make sure to gamble responsibly and only play with a
              trusted online casino.
            </p>
            <h3 style={{color: '#787b8b'}}>
              Q: How can I ensure the safety of my personal information while
              playing online casino games in Thailand?
            </h3>
            <p style={{color: '#787b8b'}}>
              A: It is essential to choose a reputable online casino that is
              licensed and regulated. These online casinos ensure the safety and
              security of their players' personal and financial information.
            </p>
            <h3 style={{color: '#787b8b'}}>
              Q: What are the most popular mobile live casino games in Thailand?
            </h3>
            <p style={{color: '#787b8b'}}>
              A: Some of the most popular mobile slot games in Thailand include
              Pussy888. These games offer excellent
              graphics, exciting gameplay, and the chance to win big jackpots.
            </p>
            <p style={{color: '#787b8b'}}>
              Casino Online Mobile Thailand | Online Sports Betting Thailand |
              Online Casino Thailand Promotion | Online Slot Thailand | 4d
              Betting Thailand
            </p>
          </div>
        </div>
      </section>
    </>
    // <div style={{ display: 'flex', flexDirection: 'column', background: "#fff" }}>
    //   <Head image={image} alt="bannercasino" />
    //   <div className="pages">
    //     <button className="pages-btn-items">Casino</button>
    //     <LiveCasinoComp history={history} />
    //     <img
    //       style={{ width: '100%', margin: '30px 0' }}
    //       src="./images/home/bannercasinoplay.png"
    //       alt="bannercasino"
    //     />
    //     <TotalPlay />
    //     <Testimonials />
    //   </div>
    // </div>
  );
}
export default withStyles(styles)(home);
