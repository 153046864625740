export default  [
  {
    value: "+44",
    title: "+44"
  },
  {
    value: "+62",
    title: "+62"
  },
  {
    value: "+95",
    title: "+95"
  },
  {
    value: "+66",
    title: "+66"
  },
  {
    value: "+60",
    title: "+60"
  },
  {
    value: "+855",
    title: "+855"
  },
  {
    value: "+84",
    title: "+84"
  },
  {
    value: "+880",
    title: "+880"
  },
  {
    value: "+61",
    title: "+61"
  },
  {
    value: "+65",
    title: "+65"
  }
]