import React, { useState } from "react";
import { Modal, Input, Button, Checkbox } from "antd";
import {
  LockOutlined,
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import "./LoginModal.css";
import { useDispatch, useSelector } from "react-redux";
import useLoading from "../../feature/loading/hook";
import http from "service/http";
import store from "store";
import { useTranslation } from "react-i18next";
import { hideLoginModal } from "../../store/modalSlice";

const LoginModal = () => {
  const { t } = useTranslation();
  
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [captcha, setCaptcha] = useState(null);

  const [rememberMe, setRememberMe] = useState(false);

  const [loading, withLoading] = useLoading(false);

  const [loadingbtn, setLoading] = useState(false);

  const isVisible = useSelector((state) => state.modal.loginModalVisible);

  if (!isVisible) return null;

  const onClose = () => {
    if (loading || loadingbtn) return;
    dispatch(hideLoginModal());
  };

  const handleLogin = async () => {
    if (!captcha) {
      setErrorMessage("Please complete the CAPTCHA.");
      return;
    }
    try {
      setLoading(true);
      const { id_token: token } = await withLoading(() =>
        http.post({
          path: "authenticate",
          payload: {
            username,
            password,
          },
        })
      );

      http.setJwtToken(token);
      store.set("token", token);

      const me = await http.get({ path: "me" });
      store.set("user", me);

      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      setErrorMessage(t("INCORRECT_USERNAME_PASSWORD"));
    }
  };

  return (
    <Modal
      title={
        <img
          className="modal-title"
          src="./images/icon/Logo_Kiss.png"
          alt=""
        ></img>
      }
      open={isVisible}
      onCancel={onClose}
      footer={null}
      centered
      className="login-modal"
    >
      <div className="login-form">
        <Input
          prefix={<UserOutlined />}
          placeholder="User Name"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          iconRender={(visible) =>
            !visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
          }
        />
        {errorMessage && <p className="error-text">{errorMessage}</p>}
        <div className="captcha-section">
          <ReCAPTCHA
            sitekey="6LdaEd0qAAAAAAHhODIa_ZDYneEJGybsmgsX_8R6"
            onChange={setCaptcha}
            theme="dark"
          />
        </div>
        <Button
          className="login-button"
          disabled={!captcha || !username || !password || loading || loadingbtn}
          onClick={handleLogin}
        >
          LOGIN
        </Button>
        <div className="options" style={{display: 'none'}}>
          <Checkbox
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          >
            Remember Me
          </Checkbox>
          <a href="/" className="forgot-password" onClick={(e) => { e.preventDefault(); }}>
            Forgot Password?
          </a>
        </div>
        <p className="register-text">
          Do not have an account yet?{" "}
          <span>
            Click{" "}
            <a href="/register" className="highlight">
              Here
            </a>{" "}
            to register now!
          </span>
        </p>
        <p className="footer-text">
          If you encounter any issues while logging in, please contact our
          <a href="/" className="customer-service" onClick={(e) => { e.preventDefault(); }}>
            {" "}
            Customer Service
          </a>{" "}
          for further assistance.
        </p>
      </div>
    </Modal>
  );
};

export default LoginModal;
