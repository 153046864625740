export default [
  {
    src: "./images/home/casinovip.png",
    classshow: "",
    code: "VipCasino",
    label: "VipCasino",
    route: "/casino",
    idrActive: 0,
    id: 33,
  },
  {
    src: "./images/home/casinowebet.png",
    classshow: "pages-icon-hot",
    code: "WE",
    label: "WE",
    route: "we",
    idrActive: 0,
    id: 26,
  },

  {
    src: "./images/home/casinosexy.png",
    classshow: "",
    code: "sexy_baccarat",
    label: "Sexy Baccarat",
    route: "awc/SEXYBCRT/LIVE",
    idrActive: 0,
    // game: ,
    id: 23,
  },
  {
    src: "./images/home/casinopp.png",
    classshow: "",
    code: "sexy_baccarat",
    label: "PP",
    route: "awc/PP/LIVE",
    idrActive: 0,
    id: 23,
  },
  {
    src: "./images/home/casinobiggaming.png",
    classshow: "",
    code: "BGAWS",
    label: "Big gaming",
    route: "big-gaming",
    idrActive: 0,
    id: 28,
  },
  {
    src: "./images/home/casinovenus.png",
    classshow: "",
    code: "sexy_baccarat",
    label: "Venus Casino",
    route: "venus",
    idrActive: 0,
    id: 23,
  },
  {
    src: "images/icon/evoicon.png",
    classshow: "",
    code: "evolution",
    label: "EVOLUTION",
    route: "/evolution-games",
    idrActive: 0,
    id: 15,
  },
  {
    src: "images/icon/playtechicon.png",
    classshow: "",
    code: "playtech",
    label: "Playtech",
    route: "/authenticate/playtech/7bal",
    idrActive: 0,
    id: 4,
  },
  // {
  //   src: 'images/icon/AGicon.png',
  //   code: 'asiagaming',
  //   label: 'Asia Gaming',
  //   route: '/asiagaming?GameType=LC',
  //   id: 21
  // }, {
  //   src: 'images/icon/evoicon.png',
  //   code: 'evolution',
  //   label: 'EVOLUTION',
  //   route: '/evolution-games',
  //   id: 15
  // }, {
  //   src: 'images/icon/playtechicon.png',
  //   code: 'playtech',
  //   label: 'Playtech',
  //   route: '/authenticate/playtech/7bal',
  //   idrActive: 1,
  //   id: 4,
  // },
  // {
  //   src: '/img/mega.png',
  //   code: 'mega',
  //   label: 'Mega888',
  //   route: '/',
  //   id: 11,
  //   deeplink: true
  // },
];
