import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import store from "store";
import http from "service/http";
import "./style.css";
import useLoading from "feature/loading/hook";
import Modal from "antd/lib/modal/Modal";
import { Button, Form, Input } from "antd";
import InputField from "../../component/textInput/InputField";
import { showNotification } from "../../utils/function";
import { ETypeNotification } from "../../constants/gameData";
import Howto from "./components/HowtoRegister";
import GameDescription from "./components/GameDescription";
import Head from "../../component/layout/Head";
import { useSelector } from "react-redux";

// import CompCommon from "feature/CompCommon";
// import NewDeposit from "feature/NewDeposit";
// import NewHistoriesMb from "feature/NewHistoriesMb";
// import WithdrawNew from "feature/WithdrawNew";

// const dataTitle = {
//   title1: 'Popular Game We Love',
//   title2: 'Best live Casino Games',
//   title3: 'Best Slot Games',
//   title4: 'Best Sportsbook'
// }
// const dataMore = {
//   more1: '/lottery',
//   more2: '/casino',
//   more3: '/slot',
//   more4: '/sports'
// }
// const generateDeeplink = ({ id, account, password }) => {
//   const proxy = {
//     3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
//     11: '',
//     1: ''
//   }

//   return proxy[id]
// }
// const gameInit = {
//   hot: false,
//   casino: false,
//   lottery: false,
//   slot: false,
//   sport: false,
// }
function home({ history, classes }) {
  const { t } = useTranslation();

  const user = store.get("user");

  const isVisible = useSelector((state) => state.modal.loginModalVisible);

  useEffect(() => {

  }, [isVisible])

  const {
    user_id: userId,
    user_account_no,
    bankName,
    bankCode,
    bankAccount,
    bankAccountNumber,
  } = user || { user_id: undefined };

  window.username = user_account_no;

  const [loading, withLoading] = useLoading(true);

  // const [isShowDeposit, setShowDeposit] = useState(false);

  // const [isShowWithdraw, setShowWithdraw] = useState(false);

  // const [isShowHistory, setShowHistory] = useState(false);

  // const [histories, setHistory] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankInfo, setBankInfo] = useState({
    bankName,
    bankCode,
    bankAccount,
    bankAccountNumber,
  });
  const imageBanner = "./images/banner/Banners.jpg";

  React.useEffect(() => {
    // fetchAccount()
    const loadgame = localStorage.loadgame;
    if (!loadgame || loadgame === 1) {
      localStorage.loadgame = 0;
    }
    (function() {
      var widget_id = "WokNIyleR2";
      var d = document;
      var w = window;

      function l() {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "//code.jivosite.com/script/widget/" + widget_id;
        var ss = document.getElementsByTagName("script")[0];
        ss.parentNode.insertBefore(s, ss);
      }

      if (d.readyState === "complete") {
        l();
      } else {
        if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
    withLoading(() => http.get({ path: "banks-support" }).then(setBanks));

    if (userId) {
      http.post({ path: `users/${userId}/sync-game` });
      withLoading(() =>
        http.get({ path: "me" }).then((rs) => {
          if(!rs.bankCode || rs.bankCode === '')
            setIsModalVisible(true);
        })
      );
    }
  }, []);

  // const fetchAccount = () => {
  //   const { user_id: userId } = store.get('user')
  //   setTimeout(async () => {
  //     const [userResp] = await withLoading(() =>
  //       Promise.all([http.get({ path: 'me' })])
  //     )
  //     // if(userResp.bankCode === '') setIsModalVisible(true)
  //     store.set('user', userResp)
  //     updateUser(userResp)
  //     // console.log(config.gamesAvailable)
  //     updateGameBalances(
  //       userResp.balanceGame
  //         .filter(x => config.gamesAvailable.includes(x.balance.game_code))
  //         .map(t => {
  //           return t.balance
  //         })
  //     )
  //   }, 2000)
  // }

  // const onPullOut = async () => {
  //   const { user_id: userId } = store.get('user')
  //   const tasks = []
  //   gameBalances.forEach(({ gameId, balance, game_code }) => {
  //     if (balance > 1 && game_code !== 'asiagaming') {
  //       const providerCode = game_code === 'ag_galaxy' ? '?ProviderId=AG' : ''
  //       tasks.push(
  //         http.post({
  //           path: `users/${userId}/game/${gameId}/withdraw${providerCode}`,
  //           payload: {
  //             amount: balance
  //           }
  //         })
  //       )
  //     }
  //   })
  //   await withLoading(() => Promise.all(tasks))
  //   showDialogWithMessage('Pull out done!')
  //   fetchAccount()
  // }

  // const onRedeemPoint = async () => {
  //   await withLoading(() => http.put({ path: `users/redeem/point`}))
  //   showDialogWithMessage('Redeem success!')
  //   fetchAccount()
  // }
  // const onUpdateShow = (value, type) => {
  //   if (type === 'deposit') {
  //     setShowDeposit(true)
  //   }
  //   if (type === 'withdraw') {
  //     setShowWithdraw(true)
  //   }
  //   if (type === 'history') {
  //     setShowHistory(true)
  //   }
  // }

  // const getListHistory = (value) => {
  //   setHistory(value);
  // };

  const onSubmit = async (payload) => {
    try {
      const { user_id: userId } = store.get("user");
      const submited = {
        bankName: payload.bankName,
        bankAccount: payload.bankAccount,
        bankAccountNumber: payload.bankAccountNumber,
        bankCode: bankInfo.bankCode,
      };
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: submited })
      );

      showNotification(
        ETypeNotification.SUCCESS,
        "Update Your Bank Detail Success"
      );
      window.location.reload();
    } catch (error) {
      showNotification(ETypeNotification.ERROR, error.message);
    }
  };
  const [form] = Form.useForm();
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          background: "#fff",
        }}
      >
        <Head image={imageBanner} user={user} history={history} />
        <div className="">
          <Howto user={user} history={history} />
          <GameDescription />
          {isModalVisible && (
            <div className="" style={{ margin: "0 auto" }} id="home">
              <Modal
                footer={null}
                title={<h2 className="text-white">{t("BANK_INFO")}</h2>}
                open={isModalVisible}
                className="login-modal"
              >
                <Form
                  form={form}
                  layout="vertical"
                  className="form-deposit login-form"
                  onFinish={(values) => onSubmit({ ...values })}
                >
                  <div className="label-col">
                    Bank Name<span className="redtext">*</span>
                  </div>
                  <Form.Item name="bankName">
                    <select
                      style={{ background: "#0b2e41", color: "#fff" }}
                      type="text"
                      className="login-input text-white"
                      placeholder={t("BANK_NAME")}
                      value={bankInfo.bankCode}
                      onChange={(event) => {
                        setBankInfo({
                          ...bankInfo,
                          bankCode: event.target.value,
                          bankName: banks.find(
                            (x) => x.value === event.target.value
                          ).title,
                        });
                      }}
                    >
                      <option value="">{t("SELECT_BANK")}</option>
                      {banks.map((b) => {
                        return <option value={b.value}>{b.title}</option>;
                      })}
                    </select>
                  </Form.Item>
                  <div className="label-col">
                    Bank Account<span className="redtext">*</span>
                  </div>
                  <Form.Item name="bankAccount">
                    <Input
                      type="text"
                      size="default"
                      placeholder="Bank Account.."
                    />
                  </Form.Item>
                  <div className="label-col">
                    Account No<span className="redtext">*</span>
                  </div>
                  <Form.Item name="bankAccountNumber">
                    <Input
                      type="number"
                      min={0}
                      size="default"
                      placeholder="Account No.."
                    />
                  </Form.Item>
                  <div className="af-field-submit">
                    <button
                      type="submit"
                      className="join-reg-btn updateprofilepassword cursor-pointer"
                      disabled={loading}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </Modal>
            </div>
          )}
        </div>
      </div>
      {/* <CompCommon
        title={"Deposit"}
        className="custom-mobile-deposit"
        show={isShowDeposit}
        close={() => {
          setShowDeposit(!isShowDeposit);
        }}
      >
        <NewDeposit />
      </CompCommon>
      <CompCommon
        title={"Withdraw"}
        show={isShowWithdraw}
        close={() => {
          setShowWithdraw(!isShowWithdraw);
        }}
      >
        <WithdrawNew />
      </CompCommon>
      <CompCommon
        title={"History"}
        show={isShowHistory}
        icon={"images/icon/Untitled-2.png"}
        close={() => {
          setShowHistory(!isShowHistory);
        }}
        getListHistory={getListHistory}
      >
        <NewHistoriesMb histories={histories} />
      </CompCommon> */}
    </>
  );
}

export default home;
