import React from "react";
import "./boxstyle.css";

const MenuItemBoxLeftMobile = ({ history, _pageName }) => {
  const pageName = _pageName || "Deposit";
  return (
    <div className="max-container mobile-quickmenu">
      <div className="quick-menu-wrapper">
        <div className="topmain-btn">
          <a
            href="/deposit"
            className={`tpm-btn ${pageName === "Deposit" ? "active" : ""}`}
            id="m-menu-deposit-page-redirect"
          >
            <div className="tpm-inner">
              <img className="w-auto" src="images/icon/af-deposit.png" alt="" />
              <div>Deposit</div>
            </div>
          </a>

          <a
            className={`tpm-btn ${pageName === "Withdrawal" ? "active" : ""}`}
            href="/withdraw"
            id="m-menu-withdrawal"
          >
            <div className="tpm-inner">
              <img className="w-auto" src="images/icon/af-withdraw.png" alt="" />
              <div>Withdrawal</div>
            </div>
          </a>

          {/* <a
            href="/"
            className={`tpm-btn ${pageName === "Transfer" ? "active" : ""}`}
            id="m-menu-transfer"
          >
            <div className="tpm-inner">
              <img className="w-auto" src="images/icon/af-transfer.png" alt="" />
              <div>Transfer</div>
            </div>
          </a> */}
          <a
            href="/histories"
            className={`tpm-btn ${pageName === "History" ? "active" : ""}`}
            id="m-menu-history"
          >
            <div className="tpm-inner">
              <img className="w-auto" src="images/icon/af-statement.png" alt="" />
              <div>History</div>
            </div>
          </a>
          <a
            href="/account"
            className={`tpm-btn ${pageName === "Profile" ? "active" : ""}`}
            id="m-menu-profile"
          >
            <div className="tpm-inner">
              <img className="w-auto" src="images/icon/af-profile.png" alt="" />
              <div>Profile</div>
            </div>
          </a>
          {/* <a
            className={`tpm-btn ${pageName === "CashRebate" ? "active" : ""}`}
            href="/"
            id="m-menu-cashrebate"
          >
            <div className="tpm-inner">
              <img className="w-auto" src="images/icon/af-rebate.png?1" alt="" />
              <div>Cash Rebate</div>
            </div>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default MenuItemBoxLeftMobile;
