import React from "react";
import "../style.css";

const FeaturedSlots = ({ user, history }) => {
  return (
    <div className="max-container mt-4">
      <div className="home-column-wrap"></div>
      <div className="featured-container">
        <div className="title-wrap">
          <div className="title-text">
            <img alt="" src="images/home/featuredslot.png" />
            FEATURED SLOTS
          </div>
          <img
            alt=""
            className="image-featured-slots fadebase"
            src="images/title-fade-bg.png"
          />
        </div>
        <div className="moregame-btn-wrap">
          <button
            className="moregmames-btn"
            onClick={() => {}}
          >
            MORE GAMES{" "}
            <img
              className="ml-1 w-15 h-15"
              src="./images/icon/icon-arrow.png"
              alt="Arrow"
            />
          </button>
        </div>
        <div className="feature-tabs">
          <ul className="nav nav-featured">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#recom">
                RECOMMEND GAMES
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#hot">
                HOT GAMES
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="recom">
              <div className="games-feature-wrapper">
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PT/Buffalo-Blitz-Megaways.png"
                    />
                  </div>
                  <div className="fg-gname">Buffalo Blitz Megaways</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PT/Chilli-Xtreme-PowerPlay-Jackpot.png"
                    />
                  </div>
                  <div className="fg-gname">Chilli Xtreme PowerPlay Jackpot</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PT/Fire-Blaze-Green-Wizard.png"
                    />
                  </div>
                  <div className="fg-gname">Fire Blaze Green Wizard</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PT/Football-Cash-Collect.png"
                    />
                  </div>
                  <div className="fg-gname">Football Cash Collect</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PT/Grand-Junction-Golden-Guns.png"
                    />
                  </div>
                  <div className="fg-gname">Grand Junction Golden Guns</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PT/Sahara-Riches-MegaWays-Cash-Collect.png"
                    />
                  </div>
                  <div className="fg-gname">
                    Sahara Riches MegaWays Cash Collect
                  </div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PT/Yu-Tu-Jin-Cai-Cash-Collect.png"
                    />
                  </div>
                  <div className="fg-gname">YU Tu Jin Cai Cash Collect</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PP/Candy-Village.png"
                    />
                  </div>
                  <div className="fg-gname">Candy Village</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PP/Gates-of-Olympus.png"
                    />
                  </div>
                  <div className="fg-gname">Gates of Olympus</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PP/Starlight-Christmas.png"
                    />
                  </div>
                  <div className="fg-gname">Starlight Christmas</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PP/Starlight-Princess.png"
                    />
                  </div>
                  <div className="fg-gname">Starlight Princess</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img alt="" src="images/home/RECOMMEND-PP/Sugar-Rush.png" />
                  </div>
                  <div className="fg-gname">Sugar Rush</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PP/Sweet-Bonanza-Xmas.png"
                    />
                  </div>
                  <div className="fg-gname">Sweet Bonanza Xmas</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/RECOMMEND-PP/Sweet-Bonanza.png"
                    />
                  </div>
                  <div className="fg-gname">Sweet Bonanza</div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="hot">
              <div className="games-feature-wrapper">
                <div className="fgbox">
                  <div className="fg-img">
                    <img alt="" src="images/home/HOT-GAMES-PT/Archer.png" />
                  </div>
                  <div className="fg-gname">Archer</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PT/Captains-Treasure.png"
                    />
                  </div>
                  <div className="fg-gname">Captains Treasure</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PT/Dolphin-Reef.png"
                    />
                  </div>
                  <div className="fg-gname">Dolphin Reef</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PT/Epic-Ape-II.png"
                    />
                  </div>
                  <div className="fg-gname">Epic Ape II</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PT/Great-Blue-Jackpot.png"
                    />
                  </div>
                  <div className="fg-gname">Great Blue Jackpot</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img alt="" src="images/home/HOT-GAMES-PT/Super-Lion.png" />
                  </div>
                  <div className="fg-gname">Super Lion</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PT/Zhao-Cai-Jin-Bao.png"
                    />
                  </div>
                  <div className="fg-gname">Zhao Cai Jin Bao</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img alt="" src="images/home/HOT-GAMES-PP/Aztec-Gems.png" />
                  </div>
                  <div className="fg-gname">Aztec Gems</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PP/Bonanza-Gold.png"
                    />
                  </div>
                  <div className="fg-gname">Bonanza Gold</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PP/Five-Lions-Megaways.png"
                    />
                  </div>
                  <div className="fg-gname">Five Lions Megaways</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PP/Great-Rhino-Megaways.jpg.png"
                    />
                  </div>
                  <div className="fg-gname">Great Rhino Megaways</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PP/Jokers-Jewels.png"
                    />
                  </div>
                  <div className="fg-gname">Jokers Jewels</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PP/Pyramid-Bonanza.jpg.png"
                    />
                  </div>
                  <div className="fg-gname">Pyramid Bonanza</div>
                </div>
                <div className="fgbox">
                  <div className="fg-img">
                    <img
                      alt=""
                      src="images/home/HOT-GAMES-PP/Wild-West-Gold.jpg.png"
                    />
                  </div>
                  <div className="fg-gname">Wild West Gold</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="featured-container">
        <div className="title-wrap">
          <div className="title-text">
            <img src="./images/home/livecasino.png" alt="" />
            Live Casino
          </div>
          <img
            className="image-featured-slots fadebase"
            alt=""
            src="images/title-fade-bg.png"
          />
        </div>
        <div className="seemore-btn-wrap">
          <button className="moregmames-btn"onClick={() => { history.push('/casino') }}>
            See More{" "}
            <img
              className="ml-1 ml-1 w-15 h-15"
              src="./images/icon/icon-arrow.png"
              alt="Arrow"
            />
          </button>
        </div>
        <div className="games-feature-wrapper">
          <div className="fgbox">
            <div className="fg-img">
              <img alt="" src="images/home/live_casino01.png" />
            </div>
            <div className="fg-gname">Lightning Baccarat</div>
          </div>
          <div className="fgbox">
            <div className="fg-img">
              <img alt="" src="images/home/live_casino02.png" />
            </div>
            <div className="fg-gname">Lightning Dice</div>
          </div>
          <div className="fgbox">
            <div className="fg-img">
              <img alt="" src="images/home/live_casino03.png" />
            </div>
            <div className="fg-gname">Dream Catcher</div>
          </div>
          <div className="fgbox">
            <div className="fg-img">
              <img alt="" src="images/home/live_casino04.png" />
            </div>
            <div className="fg-gname">Mega Fire Blaze Roulette Live</div>
          </div>
          <div className="fgbox">
            <div className="fg-img">
              <img alt="" src="images/home/live_casino05.png" />
            </div>
            <div className="fg-gname">Spin A Win</div>
          </div>
          <div className="fgbox">
            <div className="fg-img">
              <img alt="" src="images/home/live_casino06.png" />
            </div>
            <div className="fg-gname">Quantum Blackjack Live Plus</div>
          </div>
          <div className="fgbox">
            <div className="fg-img">
              <img alt="" src="images/home/live_casino07.png" />
            </div>
            <div className="fg-gname">Adventures Beyond Wonderland</div>
          </div>
        </div>
      </div>
      <div className="featured-container">
        <div className="title-wrap">
          <div className="title-text">
            <img src="./images/home/moregames.png" alt="" />
            MORE GAMES
          </div>
          <img
            className="image-featured-slots fadebase"
            alt=""
            src="images/title-fade-bg.png"
          />
        </div>
        <div className="moregame-wrapper">
          <a href="/sports" className="mgbox">
            <div className="mgimg-hover">
              <img alt="" src="./images/icon/spin2.png" />
            </div>
            <div className="mgimg">
              <img alt="" src="./images/icon/spin.png" />
            </div>
          </a>
          <a href="/casino" className="mgbox">
            <div className="mgimg-hover">
              <img alt="" src="./images/icon/gaming2.png" />
            </div>
            <div className="mgimg">
              <img alt="" src="./images/icon/gaming.png" />
            </div>
          </a>
          <a href="/lottery" className="mgbox">
            <div className="mgimg-hover">
              <img alt="" src="./images/icon/lottery2.png" />
            </div>
            <div className="mgimg">
              <img alt="" src="./images/icon/newLottery.png" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FeaturedSlots;
