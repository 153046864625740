import React, { useState } from "react";
import "../style.css";
import FeaturedSlots from "./FeaturedSlots";

const Howto = ({ user, history }) => {
  const [gameActive, setGameActive] = useState("Wbet");
  return (
    <section className="homepage-feature-sec">
      <section className="sports-game-sec">
        <div className="max-container sports">
          <div className="mobile-home-nav">
            <ul className="nav home-nav">
              <li className="nav-item">
                <a href="/" className="navhome-link active">
                  <img
                    className="w-38 h-38"
                    alt=""
                    src="images/icon/home-nav-icon.png"
                  />
                  <div>Home</div>
                </a>
              </li>
              <li className="nav-item">
                <a className="navhome-link" data-toggle="tab" href="#casino">
                  <img
                    className="w-38 h-38"
                    alt=""
                    src="images/icon/livecasino-nav-icon.png"
                  />
                  <div>Casino</div>
                </a>
              </li>
              <li className="nav-item">
                <a className="navhome-link" data-toggle="tab" href="#sports">
                  <img
                    className="w-38 h-38"
                    alt=""
                    src="images/icon/sport-nav-icon.png"
                  />
                  <div>Sports</div>
                </a>
              </li>
              <li className="nav-item">
                <a className="navhome-link" data-toggle="tab" href="#slots">
                  <img
                    className="w-38 h-38"
                    alt=""
                    src="images/icon/slots-nav-icon.png?1"
                  />
                  <div>Slots</div>
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="navhome-link" data-toggle="tab" href="#gaming">
                  <img
                    className="w-38 h-38"
                    alt=""
                    src="images/icon/gaming-nav-icon.png?1"
                  />
                  <div>GAMING</div>
                </a>
              </li> */}
              <li className="nav-item">
                <a href="/lottery" className="navhome-link">
                  <img
                    className="w-38 h-38"
                    alt=""
                    src="images/icon/lottery-nav-icon.png"
                  />
                  <div>Lottery</div>
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  href="./legend-spin-tournament-2023/index.aspx"
                  target="_blank"
                  className="navhome-link"
                >
                  <img
                    className="w-38 h-38"
                    alt=""
                    src="images/icon/spin-nav-icon.png"
                  />
                  <div>Tournament</div>
                </a>
              </li> */}
            </ul>
          </div>

          <div className="annoucement-sec">
            <div className="speaker-icon">
              <img alt="" src="./images/icon/icon-megaphone.png" />
            </div>
            <div className="annouce-text">
              <marquee scrolldelay="50">
                VG Soft warmly welcomes all new members with an exciting and
                highly rewarding Deposit Bonus Up To THB300 !
              </marquee>
            </div>
          </div>

          <div className="tab-content-home">
            <div className="tab-pane fade show active" id="home">
              <div className="sport-game-container">
                <div className="sg-column sport">
                  <div className="sport-game-inner">
                    <ul className="nav nav-games" id="myTab" role="tablist">
                      <li className="game-item">
                        <a
                          className="nav-game active"
                          data-toggle="tab"
                          href="#wbet"
                        >
                          <span>
                            <img alt="" src="images/home/wbet-sm.png" />
                          </span>
                          <span className="spline"></span>
                          <span>M9BET</span>
                        </a>
                      </li>
                      <li className="game-item">
                        <a
                          className="nav-game"
                          data-toggle="tab"
                          href="#maxbet"
                        >
                          <span>
                            <img alt="" src="images/home/maxbet-sm.png" />
                          </span>
                          <span className="spline"></span>
                          <span>Maxbet</span>
                        </a>
                      </li>
                      <li className="game-item">
                        <a
                          className="nav-game"
                          data-toggle="tab"
                          href="#sbobet"
                        >
                          <span>
                            <img alt="" src="images/home/sbobet-sm.png" />
                          </span>
                          <span className="spline"></span>
                          <span>Sbobet</span>
                        </a>
                      </li>
                      <li className="game-item">
                        <a className="nav-game" data-toggle="tab" href="#cmd">
                          <span>
                            <img alt="" src="images/home/cmd-sm.png" />
                          </span>
                          <span className="spline"></span>
                          <span>CMD368</span>
                        </a>
                      </li>
                      {/* <li className="game-item">
                        <a className="nav-game" data-toggle="tab" href="#fb">
                          <span>
                            <img alt="" src="images/home/fb-sm.png" />
                          </span>
                          <span className="spline"></span>
                          <span>FB Sports</span>
                        </a>
                      </li> */}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="wbet">
                        <div className="sports-intro-wrap">
                          <div className="sg-caption">
                            <img alt="" src="images/home/wbet-logo.png" />
                            <div className="sg-text">
                              Enjoy the best of sportsbook in the Market!
                            </div>
                          </div>
                          <div className="sg-chrt-img">
                            <img
                              src="images/home/wbet-player.png?1"
                              alt="Sweet Bonaza 1000"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="maxbet">
                        <div className="sports-intro-wrap">
                          <div className="sg-caption">
                            <img alt="" src="images/home/maxbet-logo.png" />
                            <div className="sg-text">
                              Widest sporting events and betting offers
                            </div>
                          </div>
                          <div className="sg-chrt-img">
                            <img alt="" src="images/home/maxbet-player.png?1" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="sbobet">
                        <div className="sports-intro-wrap">
                          <div className="sg-caption">
                            <img alt="" src="images/home/sbobet-logo.png" />
                            <div className="sg-text">
                              Enjoy safe and secure gaming experience
                            </div>
                          </div>
                          <div className="sg-chrt-img">
                            <img alt="" src="images/home/sbobet-player.png?1" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="cmd">
                        <div className="sports-intro-wrap">
                          <div className="sg-caption">
                            <img alt="" src="images/home/cmd-logo.png" />
                            <div className="sg-text">
                              Enjoy safe and secure gaming experience
                            </div>
                          </div>
                          <div className="sg-chrt-img">
                            <img alt="" src="images/home/cmd-player.png?1" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="fb">
                        <div className="sports-intro-wrap">
                          <div className="sg-caption">
                            <img alt="" src="images/home/fb-gold.png" />
                            <div className="sg-text">
                              Enjoy safe and secure gaming experience
                            </div>
                          </div>
                          <div className="sg-chrt-img">
                            <img alt="" src="images/home/fb-player.png?1" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="mobile-sportgame">
                    <div
                      id="sportGames"
                      className="carousel slide sportgame-slider pointer-event"
                      data-ride="carousel"
                    >
                      <img className="w-auto h-220" src="images/home/futsal-player02.gif" alt="" />
                      {/* <div className="carousel-inner">
                        <div className="carousel-item">
                          <div className="sportgame-wrap">
                            <div className="sg-caption">
                              <img alt="" src="images/home/wbet-logo.png" />
                              <div className="sg-text">
                                Enjoy the best of sportsbook in the Market!
                              </div>
                            </div>
                            <div className="sg-chrt-img">
                              <img alt="" src="images/home/wbet-player.png?1" />
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="sportgame-wrap">
                            <div className="sg-caption">
                              <img alt="" src="images/home/maxbet-logo.png" />
                              <div className="sg-text">
                                Widest sporting events and betting offers
                              </div>
                            </div>
                            <div className="sg-chrt-img">
                              <img
                                alt=""
                                src="images/home/maxbet-player.png?1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="sportgame-wrap">
                            <div className="sg-caption">
                              <img alt="" src="images/home/sbobet-logo.png" />
                              <div className="sg-text">
                                Enjoy safe and secure gaming experience
                              </div>
                            </div>
                            <div className="sg-chrt-img">
                              <img
                                alt=""
                                src="images/home/sbobet-player.png?1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="sportgame-wrap">
                            <div className="sg-caption">
                              <img alt="" src="images/home/cmd-logo.png" />
                              <div className="sg-text">
                                Enjoy safe and secure gaming experience
                              </div>
                            </div>
                            <div className="sg-chrt-img">
                              <img alt="" src="images/home/cmd-player.png?1" />
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item active">
                          <div className="sportgame-wrap">
                            <div className="sg-caption">
                              <img alt="" src="images/home/fb-gold.png" />
                              <div className="sg-text">
                                Enjoy safe and secure gaming experience
                              </div>
                            </div>
                            <div className="sg-chrt-img">
                              <img alt="" src="images/home/fb-player.png?1" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="carousel-indicators sport-games">
                        <li
                          data-target="#sportGames"
                          data-slide-to="0"
                          className=""
                        ></li>
                        <li
                          data-target="#sportGames"
                          data-slide-to="1"
                          className=""
                        ></li>
                        <li
                          data-target="#sportGames"
                          data-slide-to="2"
                          className=""
                        ></li>
                        <li
                          data-target="#sportGames"
                          data-slide-to="3"
                          className=""
                        ></li>
                        <li
                          data-target="#sportGames"
                          data-slide-to="4"
                          className="active"
                        ></li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="sports">
            <div className="home-product-wrapper">
						<a href="/sports"><img className="w-auto" src="images/home-product/sport/wbet.jpg?1" alt=""/></a>
						<a href="/sports"><img className="w-auto" src="images/home-product/sport/sbo.jpg?1" alt=""/></a>
						<a href="/sports"><img className="w-auto" src="images/home-product/sport/max.jpg?1" alt=""/></a>
						<a href="/sports"><img className="w-auto" src="images/home-product/sport/cmd.jpg?1" alt=""/></a>
						{/* <a href="/sports"><img className="w-auto" src="images/home-product/sport/fb-sports.jpg?1" alt=""/></a> */}
                        
					</div>
              {/* <div className="home-product-wrapper">
                <a href="/sports">
                  <img loading="lazy" src="./images/home/sportm8.png" alt="" />
                </a>
                <a href="/msports">
                  <img
                    loading="lazy"
                    src="./images/home/sportmsport.png"
                    alt=""
                  />
                </a>
                <a href="/ssports">
                  <img
                    loading="lazy"
                    src="./images/home/sportsport.png"
                    alt=""
                  />
                </a>
                <a href="/sports">
                  <img
                    loading="lazy"
                    src="./images/home/sportmuaystep2.png"
                    alt=""
                  />
                </a>
                <a href="/sports">
                  <img
                    loading="lazy"
                    src="./images/home/sporte1sport.png"
                    alt="/"
                  />
                </a>
              </div> */}
            </div>
            <div className="tab-pane fade" id="casino">
            <div className="home-product-wrapper">
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/eg.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/pt.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/pp.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/vivo.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/ab.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/gp.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/pretty.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/wm.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/bg.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/dg.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/mg.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/we.jpg" alt="" />
                </a>
                <a href="/casino">
                  <img className="w-auto" src="images/home-product/live/sexy.jpg" alt="" />
                </a>
              </div>
              {/* <div className="home-product-wrapper">
                <a href="/casino">
                  <img
                    loading="lazy"
                    src="./images/home/casinovip.png"
                    alt=""
                  />
                </a>

                <a href="/casino">
                  <img
                    loading="lazy"
                    src="./images/home/casinowebet.png"
                    alt=""
                  />
                </a>
                <a href="/casino">
                  <img
                    loading="lazy"
                    src="./images/home/casinosexy.png"
                    alt=""
                  />
                </a>
                <a href="/casino">
                  <img loading="lazy" src="./images/home/casinopp.png" alt="" />
                </a>
                <a href="/casino">
                  <img
                    loading="lazy"
                    src="./images/home/casinobiggaming.png"
                    alt=""
                  />
                </a>
                <a href="/casino">
                  <img
                    loading="lazy"
                    src="./images/home/casinovenus.png"
                    alt=""
                  />
                </a>
              </div> */}
            </div>
            <div className="tab-pane fade" id="slots">
            <div className="home-product-wrapper">
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/playtech.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/pp.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/qt.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/live22.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/uuslots.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/megah5.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/cq9.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/jbd.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/jili.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/joker.jpg?1" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/ag.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/png.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/ps.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/gameart.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/gp.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/genesis.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/simpleplay.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/wm.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/lgd.jpg" alt=""/></a>
						<a href="/slot"><img className="w-a uto" src="images/home-product/slot/ne.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/mg.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/ns.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/sg.jpg" alt=""/></a>
						<a href="/slot"><img className="w-auto" src="images/home-product/slot/hcslot.jpg" alt=""/></a>
					</div>
              {/* <div className="home-product-wrapper">
                <a href="/slot">
                  <img
                    loading="lazy"
                    src="./images/home/slotkingmaker.png"
                    alt=""
                  />
                  <div className="dname">King maker</div>
                </a>
                <a href="/slot">
                  <img
                    loading="lazy"
                    src="./images/home/slotrich88.png"
                    alt=""
                  />
                  <div className="dname">Rich88</div>
                </a>
                <a href="/slot">
                  <img loading="lazy" src="./images/home/slotsg.png" alt="" />
                  <div className="dname">Spade Gaming</div>
                </a>
                <a href="/slot">
                  <img loading="lazy" src="./images/home/slotjbd.png" alt="" />
                  <div className="dname">JDB</div>
                </a>
                <a href="/slot">
                  <img loading="lazy" src="./images/home/slotCG.png" alt="" />
                  <div className="dname">Creative Gaming</div>
                </a>
                <a href="/slot">
                  <img
                    loading="lazy"
                    src="./images/home/slotfachai.png"
                    alt=""
                  />
                  <div className="dname">Fachai</div>
                </a>
                <a href="/slot">
                  <img
                    loading="lazy"
                    src="./images/home/slotjoker.png"
                    alt=""
                  />
                  <div className="dname">Joker</div>
                </a>
                <a href="/slot">
                  <img loading="lazy" src="./images/home/slotpgv2.png" alt="" />
                  <div className="dname">PGsoft</div>
                </a>
                <a href="/slot">
                  <img loading="lazy" src="./images/home/slotpp.png" alt="" />
                  <div className="dname">JILI SLOT</div>
                </a>
                <a href="/slot">
                  <img
                    loading="lazy"
                    src="./images/home/slotredtiger.png"
                    alt=""
                  />
                  <div className="dname">Red Tiger</div>
                </a>
                <a href="/slot">
                  <img
                    loading="lazy"
                    src="./images/home/slotace333.png"
                    alt=""
                  />
                  <div className="dname">ace333</div>
                </a>
              </div> */}
            </div>
            {/* <div className="tab-pane fade" id="gaming">
              <div className="home-product-wrapper">
                <a href="gaming.aspx?t=kiss">
                  <img alt="" src="images/home-product/gaming/kiss.jpg" />
                </a>
                <a href="gaming.aspx?t=mega">
                  <img alt="" src="images/home-product/gaming/mega.jpg" />
                </a>
                <a href="gaming.aspx?t=pussy">
                  <img alt="" src="images/home-product/gaming/pussy.jpg" />
                </a>
                <a href="gaming.aspx?t=kaya">
                  <img alt="" src="images/home-product/gaming/kaya.jpg" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <FeaturedSlots user={user} history={history} />
    </section>
    // <div className="game-slot-main">
    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       padding: "15px 0",
    //       alignItems: "center",
    //     }}
    //   >
    //     <div className="app-tabbar-item">
    //       <img
    //         className="new-header-styled-img-megaphone"
    //         src="./images/icon/icon-megaphone.png"
    //         alt="logo"
    //       />
    //       <p className="app-tabBar-text">
    //         <marquee>
    //           VG Soft warmly welcomes all new members with an exciting and
    //           highly rewarding Deposit Bonus Up To THB300 !
    //         </marquee>
    //       </p>
    //     </div>
    //   </div>
    //   <div className="game-slot-content">
    //     <div className="game-slot-left">
    //       <div className="game-list-game">
    //         <div
    //           onClick={() => setGameActive('Wbet')}
    //           className={`my-1 new-header-styled-game-button new-header-styled-img-wbet ${gameActive === 'Wbet' ? 'active' : ''}`}
    //         />
    //         <div
    //           onClick={() => setGameActive('Maxbet')}
    //           className={`my-1 new-header-styled-game-button new-header-styled-img-maxbet ${gameActive === 'Maxbet' ? 'active' : ''}`}
    //         />
    //         <div
    //           onClick={() => setGameActive('SBOBet')}
    //           className={`my-1 new-header-styled-game-button new-header-styled-img-sbobet ${gameActive === 'SBOBet' ? 'active' : ''}`}
    //         />
    //         <div
    //           onClick={() => setGameActive('CMD368')}
    //           className={`my-1 new-header-styled-game-button new-header-styled-img-cmd368 ${gameActive === 'CMD368' ? 'active' : ''}`}
    //         />
    //       </div>
    //       <div className="howto-tab-content" id="myTabContent">
    //         <div className={`howto-tab-pane fade ${gameActive === 'Wbet' ? 'show active' : ''}`} id="wbet">
    //           <div className="howto-sports-intro-wrap">
    //             <div className="howto-sg-caption">
    //               <img src="images/home/wbet-logo.png" alt="" />
    //               <div className="howto-sg-text">
    //                 Enjoy the best of sportsbook in the Market!
    //               </div>
    //             </div>
    //             <div className="howto-sg-chrt-img">
    //               <img
    //                 src="images/home/wbet-player.png?1"
    //                 alt="Sweet Bonaza 1000"
    //               />
    //             </div>
    //           </div>
    //         </div>
    //         <div className={`howto-tab-pane fade ${gameActive === 'Maxbet' ? 'show active' : ''}`} id="maxbet">
    //           <div className="howto-sports-intro-wrap">
    //             <div className="howto-sg-caption">
    //               <img src="images/home/maxbet-logo.png" alt="" />
    //               <div className="howto-sg-text">
    //                 Widest sporting events and betting offers
    //               </div>
    //             </div>
    //             <div className="howto-sg-chrt-img">
    //               <img src="images/home/maxbet-player.png" alt="" />
    //             </div>
    //           </div>
    //         </div>
    //         <div className={`howto-tab-pane fade ${gameActive === 'SBOBet' ? 'show active' : ''}`} id="sbobet">
    //           <div className="howto-sports-intro-wrap">
    //             <div className="howto-sg-caption">
    //               <img src="images/home/sbobet-logo.png" alt="" />
    //               <div className="howto-sg-text">
    //                 Enjoy safe and secure gaming experience
    //               </div>
    //             </div>
    //             <div className="howto-sg-chrt-img">
    //               <img src="images/home/sbobet-player.png" alt="" />
    //             </div>
    //           </div>
    //         </div>
    //         <div className={`howto-tab-pane fade ${gameActive === 'CMD368' ? 'show active' : ''}`} id="cmd">
    //           <div className="howto-sports-intro-wrap">
    //             <div className="howto-sg-caption">
    //               <img src="images/home/cmd-logo.png" alt="" />
    //               <div className="howto-sg-text">
    //                 Enjoy safe and secure gaming experience
    //               </div>
    //             </div>
    //             <div className="howto-sg-chrt-img">
    //               <img src="images/home/cmd-player.png" alt="" />
    //             </div>
    //           </div>
    //         </div>
    //         {/* <div className={`howto-tab-pane fade ${gameActive === 'Wbet' ? 'show active' : ''}`} id="fb">
    //           <div className="howto-sports-intro-wrap">
    //             <div className="howto-sg-caption">
    //               <img src="images/home/fb-gold.png" alt="" />
    //               <div className="howto-sg-text">
    //                 Enjoy safe and secure gaming experience
    //               </div>
    //             </div>
    //             <div className="howto-sg-chrt-img">
    //               <img src="images/home/fb-player.png1" alt="" />
    //             </div>
    //           </div>
    //         </div> */}
    //       </div>
    //     </div>
    //     <div className="right" />
    //   </div>
    // </div>
  );
};

export default Howto;
