export default [
    {
      src: "./images/dropdown/sports/wbet.png",
      classshow: "",
      code: "M9",
      label: "M9BET",
      route: "/m9",
      idrActive: 0,
      id: 34,
    },
    {
      src: "./images/home/casinovip.png",
      classshow: "",
      code: "VipCasino",
      label: "VipCasino",
      route: "/casino",
      idrActive: 0,
      id: 33,
    },
    {
      src: "./images/home/casinowebet.png",
      classshow: "pages-icon-hot",
      code: "WE",
      label: "WE",
      route: "we",
      idrActive: 0,
      id: 26,
    },
  
    {
      src: "./images/home/casinosexy.png",
      classshow: "",
      code: "sexy_baccarat",
      label: "Sexy Baccarat",
      route: "awc/SEXYBCRT/LIVE",
      idrActive: 0,
      // game: ,
      id: 23,
    },
    {
      src: "./images/home/casinopp.png",
      classshow: "",
      code: "sexy_baccarat",
      label: "PP",
      route: "awc/PP/LIVE",
      idrActive: 0,
      id: 23,
    },
    {
      src: "./images/home/casinobiggaming.png",
      classshow: "",
      code: "BGAWS",
      label: "Big gaming",
      route: "big-gaming",
      idrActive: 0,
      id: 28,
    },
    {
      src: "./images/home/casinovenus.png",
      classshow: "",
      code: "sexy_baccarat",
      label: "Venus Casino",
      route: "venus",
      idrActive: 0,
      id: 23,
    },
    {
      src: "images/icon/evoicon.png",
      classshow: "",
      code: "evolution",
      label: "EVOLUTION",
      route: "/evolution-games",
      idrActive: 0,
      id: 15,
    },
    {
      src: "images/icon/playtechicon.png",
      classshow: "",
      code: "playtech",
      label: "Playtech",
      route: "/authenticate/playtech/7bal",
      idrActive: 0,
      id: 4,
    },
    
  {
    src: './images/home/sportmuaystep2.png',
    classshow: 'pages-icon-hot',
    code: 'muaystep2',
    label: 'MUAY STEP 2',
    route: 'muaystep2',
    idrActive: 0,
    id: 29
  },
  {
    src: './images/home/sportm8.png',
    classshow: 'pages-icon-hot',
    code: 'm8',
    label: 'M8',
    route: '/m8',
    id: 9
  },
  {
    src: './images/home/sportsport.png',
    classshow: '',
    code: 'ssport',
    label: 'S-Sports',
    route: 'ssports',
    idrActive: 0,
    id: 27
  },
  {
    src: './images/home/sportmsport.png',
    classshow: '',
    code: 'maxbet',
    label: 'M-Sports',
    route: 'msports',
    idrActive: 0,
    id: 6
  },
  {
    src: './images/home/sporte1sport.png',
    classshow: '',
    code: 'sexy_baccarat',
    label: 'ESPORTS',
    route: 'esports',
    idrActive: 0,
    id: 23
  }
  ];
  