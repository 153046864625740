import React from "react";
import { useTranslation } from 'react-i18next'
import "../style.css";

const GameDescription = ({ user, history }) => {
  const { t } = useTranslation()
  return (
    <div className="game-description-body">
      <div className="game-description-container" style={{color: '#787b8b'}}>
        <span>{t('TRUSTED_CASINO_TITLE2')}</span><br/>
        <span>{t('TRUSTED_CASINO_DESC')}</span><br/><br/>
        <span>{t('TRUSTED_CASINO_PLAY_DESC')}</span><br/><br/>
        <span>{t('TRUSTED_PLAYERS_TITLE')}</span><br/>
        <span>{t('TRUSTED_PLAYERS_DESC')}</span><br/><br/>
        <span>{t('MOBILE_CASINO_TITLE')}</span><br/>
        <span>{t('MOBILE_CASINO_DESC')}</span><br/><br/>
        <span>{t('EVOLUTION_GAMING_TITLE2')}</span><br/>
        <span>{t('EVOLUTION_GAMING')}</span><br/><br/>
        <span>{t('PLAYTECH_TITLE2')}</span><br/>
        <span>{t('PLAYTECH')}</span><br/><br/>
        <span>{t('WBET_TITLE')}</span><br/>
        <span>{t('WBET')}</span><br/><br/>
        <span>{t('SPADE_GAMING_TITLE')}</span><br/>
        <span>{t('SPADE_GAMING')}</span><br/><br/>
        <span>{t('KISS918_TITLE')}</span><br/>
        <span>{t('KISS918')}</span><br/><br/>
        <span>{t('MAXBET_TITLE')}</span><br/>
        <span>{t('MAXBET')}</span><br/><br/>
        <span>{t('ALLBET_TITLE')}</span><br/>
        <span>{t('ALLBET')}</span><br/><br/>
        <span>{t('TOP_CASINO_2025')}</span><br/>
        <span>{t('TOP_CASINO_DESC')}</span><br/><br/>
        <span>{t('ONLINE_CASINO_ANDROID')}</span><br/>
        <span>{t('ONLINE_CASINO_ANDROID_DESC')}</span><br/><br/>
        <span>{t('MOBILE_CASINO_PLATFORM')}</span><br/>
        <span>{t('MOBILE_CASINO_PLATFORM_DESC')}</span><br/><br/>
        <span>{t('REAL_MONEY_CASINO')}</span><br/>
        <span>{t('REAL_MONEY_CASINO_DESC')}</span><br/><br/>
        <span>{t('JUDI_ONLINE')}</span><br/>
        <span>{t('JUDI_ONLINE_DESC')}</span><br/><br/>
        <span>{t('REAL_WIN_CASINO')}</span><br/>
        <span>{t('REAL_WIN_CASINO_DESC')}</span><br/><br/>
        <span>{t('COMPREHENSIVE_CASINO')}</span><br/>
        <span>{t('COMPREHENSIVE_CASINO_DESC')}</span><br/><br/>
        <span>{t('ONLINE_CASINO')}</span><br/>
        <span>{t('ONLINE_CASINO_DESC')}</span><br/><br/>
        <span>{t('LIVE_ROULETTE')}</span><br/>
        <span>{t('LIVE_ROULETTE_DESC2')}</span><br/><br/>
        <span>{t('PRAGMATIC_PLAY')}</span><br/>
        <span>{t('PRAGMATIC_PLAY_DESC2')}</span><br/><br/>
      </div>
    </div>
  );
};

export default GameDescription;
