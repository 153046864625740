import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginModalVisible: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showLoginModal: (state) => {
      state.loginModalVisible = true;
    },
    hideLoginModal: (state) => {
      state.loginModalVisible = false;
    },
  },
});

export const { showLoginModal, hideLoginModal } = modalSlice.actions;
export default modalSlice.reducer;