import React from "react";
import "./boxstyle.css";
import numeral from "numeral";

const MainWallet = ({ userInfo, balance, onPullOut }) => {
  return (
    <div className="mainwallet-wrap">
      <div className="mwbox">
        <div className="mwtext">
          <img
            className="fas fa-wallet w-15 h-15"
            src="./images/icon/wallet.png"
            alt=""
          ></img>
          Main Wallet
          <button
            className="restore-btn cursor-pointer"
            id="btnrestoreall"
            onClick={() => onPullOut()}
          >
            <img
              className="fas fa-wallet w-15 h-15"
              src="./images/icon/wallet-2.png"
              alt=""
            ></img>
            Restore
          </button>
        </div>
        <div className="mwbalance">
          <sup className="crt-my">{userInfo.user_currency}</sup>
          <span className="wallet_balance ml-1">
            {numeral(balance).format("0,0.00")}
          </span>
          <button className="reload-btn btnrefreshwalletbalance">
            <i className="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
      <div className="mwbox-2">
        <div>
          <div className="mwtext">
            <img
              className="w-12 h-12"
              src="./images/icon/wheel-token.png"
              alt=""
            />
            Fortune Wheel
          </div>
          <div className="mwbalance">
            <span id="gstechgame_wof_token">0</span>
            <sub className="crt-my ml-1">Token</sub>
          </div>
        </div>
        <a
          href="/"
          className="rwc-wrap"
          onClick={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <div className="rwiconimg glow-gift-2">
            <img
              className="w-auto"
              src="./images/icon/rewardcenter-icon.png"
              alt=""
            />
          </div>
          <div className="rwtitle glow-on-2">Reward Center</div>
        </a>
      </div>
    </div>
  );
};

export default MainWallet;
