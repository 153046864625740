export default [
  {
    src: "./images/dropdown/sports/wbet.png",
    classshow: "",
    code: "M9",
    label: "M9BET",
    route: "/m9",
    idrActive: 0,
    id: 34,
  },
  {
    src: './images/home/sportmuaystep2.png',
    classshow: 'pages-icon-hot',
    code: 'muaystep2',
    label: 'MUAY STEP 2',
    route: 'muaystep2',
    idrActive: 0,
    id: 29
  },
  {
    src: './images/home/sportm8.png',
    classshow: 'pages-icon-hot',
    code: 'm8',
    label: 'M8',
    route: '/m8',
    id: 9
  },
  {
    src: './images/home/sportsport.png',
    classshow: '',
    code: 'ssport',
    label: 'S-Sports',
    route: 'ssports',
    idrActive: 0,
    id: 27
  },
  {
    src: './images/home/sportmsport.png',
    classshow: '',
    code: 'maxbet',
    label: 'M-Sports',
    route: 'msports',
    idrActive: 0,
    id: 6
  },
  {
    src: './images/home/sporte1sport.png',
    classshow: '',
    code: 'sexy_baccarat',
    label: 'ESPORTS',
    route: 'esports',
    idrActive: 0,
    id: 23
  }
]
