import React from "react";
import { useTranslation } from "react-i18next";
import "./Styles.css";

const listLinks = {
  games: [
    {
      title: "Sports",
      href: "/sports",
    },
    {
      title: "E - Sports",
      href: "#",
    },
    {
      title: "Casino",
      href: "/casino",
    },
    {
      title: "Slots",
      href: "/slot",
    },
    {
      title: "Fishing",
      href: "/fishing",
    },
    {
      title: "Lottery",
      href: "/lottery",
    },
  ],
  info: [
    {
      title: "About Us",
      href: "#",
    },
    {
      title: "VIP",
      href: "#",
    },
    {
      title: "Leaderboard",
      href: "#",
    },
    {
      title: "Affiliate Program",
      href: "#",
    },
    {
      title: "Contract Us",
      href: "#",
    },
    {
      title: "Responsible Gaming",
      href: "#",
    },
    {
      title: "Info Center",
      href: "#",
    },
    {
      title: "Terms of Conditions",
      href: "#",
    },
  ],
};

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className="footer-sec">
      <div className="max-container">
        <div className="ft-divider-row"></div>
        <div className="footer-column-wrapper">
          <div className="qbox">
            <div className="qbtitle-1">Certification &amp; Security by :</div>
            <div className="logo-icon">
              <img
                src="./images/footer/egba.svg"
                alt="EGBA"
              />
              <img
                src="./images/footer/cert-earth.svg"
                alt="EARTH"
              />
              <img
                src="./images/footer/rga.svg"
                alt="RGA"
              />
              <img
                src="./images/footer/eighteen.svg"
                alt="18+"
              />
              <img
                src="./images/footer/gamcare.svg"
                alt="Gamcare"
              />
              <img
                src="./images/footer/ecorga.svg"
                alt="ECOGRA"
              />
              <img
                src="./images/footer/egr.svg"
                alt="EGR Awards"
              />
              <img
                src="./images/footer/ecorga-safe.svg"
                alt="ECOGRA Safe"
              />
            </div>
          </div>
          <div className="qbox">
            <div className="qbtitle-1">Deposit Methods :</div>
            <div>
              <img className="w-auto" alt="" src="./images/home/crypto-pay.png" />
            </div>
          </div>
          <div className="qbox">
            <div className="qbtitle-1">Connect with Us :</div>
            <div className="social-wrap">
              <img
                className="size-32 ml-2"
                src="./images/footer/facebook.svg"
                alt="Facebook"
              />
              <img
                className="size-32 ml-2"
                src="./images/footer/youtube.svg"
                alt="YouTube"
              />
              <img
                className="size-32 ml-2"
                src="./images/footer/instagram.svg"
                alt="WeChat"
              />
              <img
                className="size-32 ml-2"
                src="./images/footer/twitter.svg"
                alt="Email"
              />
            </div>
          </div>
        </div>
        <div className="powered-wrapper mb-none">
        <div className="ft-divider-row"></div>
          <div className="qbtitle-1">Powered By :</div>
          <div className="provider-logo">
          <img src="./images/footer/wbet.png" alt="WBet" />
          <img src="./images/footer/maxbet.png" alt="Maxbet" />
          <img src="./images/footer/sbobet.png" alt="SBOBET" />
          <img src="./images/footer/fb-gold.png" alt="FB" />
          <img src="./images/footer/evolution.png" alt="Evolution Gaming" />
          <img src="./images/footer/worldentertainment.png" alt="World Entertainment Gaming" />
          <img src="./images/footer/playtech.png" alt="Playtech" />
          <img src="./images/footer/pragmatic.png" alt="Pragmatic Play" />
          <img src="./images/footer/vivogaming.png" alt="Vivo Gaming" />
          <img src="./images/footer/allbet.png" alt="Allbet" />
          <img src="./images/footer/gameplay.png" alt="Gameplay Interactive" />
          <img src="./images/footer/live22.png" alt="Live22 Interactive" />
          <img src="./images/footer/prettygmaing.png" alt="Pretty Interactive" />
          <img src="./images/footer/wmcasino.png" alt="WM Casino Interactive" />
          <img src="./images/footer/biggaming.png" alt="Big Interactive" />
          <img src="./images/footer/dreamgaming.png" alt="Dream Interactive" />
          <img src="./images/footer/microgaming.png" alt="Microgaming" />
          <img src="./images/footer/cq9.png" alt="CQ9" />
          <img src="./images/footer/jdb.png" alt="jdb" />
          <img src="./images/footer/lgd.png" alt="lgd" />
          <img src="./images/footer/worldmatch.png" alt="World Match" />
          <img src="./images/footer/playstar.png" alt="Play Star" />
          <img src="./images/footer/netent.png" alt="Netent" />
          <img src="./images/footer/asiagaming.png" alt="Asia gaming" />
          <img src="./images/footer/genesis.png" alt="Genesis" />
          <img src="./images/footer/simpleplay.png" alt="Simple play" />
          <img src="./images/footer/gameart.png" alt="Game Art" />
          <img src="./images/footer/playngo.png" alt="Play NGO" />
          <img src="./images/footer/jili.png" alt="Jili" />
          <img src="./images/footer/pussy888.png" alt="Pussy 888" />
          <img src="./images/footer/918kaya.png" alt="918 Kaya" />
          <img src="./images/footer/hc-slot.png" alt="HC-Slot" />
          <img src="./images/footer/nextspin.png" alt="nextspin" />
          <img src="./images/footer/spadegaming.png" alt="Spade gaming" />
          <img src="./images/footer/hs4d.png" alt="HS 4d" />
          <img src="./images/footer/uuslots.png" alt="UU Slots" />
          <img src="./images/footer/megah5.png" alt="Mega H5" />
          <img src="./images/footer/wow.png" alt="wow" />
          </div>
        </div>

        <div className="ft-divider-row"></div>
        <div className="copyright">
        <span>Copyright © {(new Date()).getFullYear()} KISS Cash</span>
        </div>
      </div>
    </section>
    // <div className="footer-body">
    //   <footer className="footer">
    //     <div className="footer-container">
    //       <div className="footer-section">
    //         <div className="footer-h4">Certification & Security by :</div>
    //         <div className="footer-logos">
    //           <img className='footer-logos-small' src="./images/footer/egba.svg" alt="EGBA" />
    //           <img className='footer-logos-small' src="./images/footer/cert-earth.svg" alt="EARTH" />
    //           <img className='footer-logos-small' src="./images/footer/rga.svg" alt="RGA" />
    //           <img className='footer-logos-small' src="./images/footer/eighteen.svg" alt="18+" />
    //           <img className='footer-logos-small' src="./images/footer/gamcare.svg" alt="Gamcare" />
    //           <img className='footer-logos-medium ml-i-20' src="./images/footer/ecorga.svg" alt="ECOGRA" />
    //           <img className='footer-logos-medium ml-i-20' src="./images/footer/egr.svg" alt="EGR Awards" />
    //           <img className='footer-logos-large ml-i-5' src="./images/footer/ecorga-safe.svg" alt="ECOGRA Safe" />
    //         </div>
    //       </div>

    //       <div className="footer-border-left"></div>
    //       <div className="footer-section">
    //         <div className="footer-h4">Deposit Methods :</div>
    //         <div className="footer-payment-icons">
    //           <img alt="" src="./images/home/crypto-pay.png" alt="Crypto" />
    //         </div>
    //       </div>
    //       <div className="footer-border-right"></div>

    //       <div className="footer-section">
    //         <div className="footer-h4">Connect with Us :</div>
    //         <div className="footer-social-icons">
    //           <img alt="" src="./images/footer/facebook.svg" alt="Facebook" />
    //           <img alt="" src="./images/footer/youtube.svg" alt="YouTube" />
    //           <img alt="" src="./images/footer/instagram.svg" alt="WeChat" />
    //           <img alt="" src="./images/footer/twitter.svg" alt="Email" />
    //         </div>
    //       </div>
    //     </div>

    //     <div className="footer-middle">
    //       <div className="footer-border-content">
    //         <div className="footer-h4">Powered By :</div>
    //         <div className="footer-providers">
    //           <img alt="" src="./images/footer/wbet.png" alt="WBet" />
    //           <img alt="" src="./images/footer/maxbet.png" alt="Maxbet" />
    //           <img alt="" src="./images/footer/sbobet.png" alt="SBOBET" />
    //           <img alt="" src="./images/footer/fb-gold.png" alt="FB" />
    //           <img alt="" src="./images/footer/evolution.png" alt="Evolution Gaming" />
    //           <img alt="" src="./images/footer/worldentertainment.png" alt="World Entertainment Gaming" />
    //           <img alt="" src="./images/footer/playtech.png" alt="Playtech" />
    //           <img alt="" src="./images/footer/pragmatic.png" alt="Pragmatic Play" />
    //           <img alt="" src="./images/footer/vivogaming.png" alt="Vivo Gaming" />
    //           <img alt="" src="./images/footer/allbet.png" alt="Allbet" />
    //           <img alt="" src="./images/footer/gameplay.png" alt="Gameplay Interactive" />
    //           <img alt="" src="./images/footer/live22.png" alt="Live22 Interactive" />
    //           <img alt="" src="./images/footer/prettygmaing.png" alt="Pretty Interactive" />
    //           <img alt="" src="./images/footer/wmcasino.png" alt="WM Casino Interactive" />
    //           <img alt="" src="./images/footer/biggaming.png" alt="Big Interactive" />
    //           <img alt="" src="./images/footer/dreamgaming.png" alt="Dream Interactive" />
    //           <img alt="" src="./images/footer/microgaming.png" alt="Microgaming" />
    //           <img alt="" src="./images/footer/cq9.png" alt="CQ9" />
    //           <img alt="" src="./images/footer/jdb.png" alt="jdb" />
    //           <img alt="" src="./images/footer/lgd.png" alt="lgd" />
    //           <img alt="" src="./images/footer/worldmatch.png" alt="World Match" />
    //           <img alt="" src="./images/footer/playstar.png" alt="Play Star" />
    //           <img alt="" src="./images/footer/netent.png" alt="Netent" />
    //           <img alt="" src="./images/footer/asiagaming.png" alt="Asia gaming" />
    //           <img alt="" src="./images/footer/genesis.png" alt="Genesis" />
    //           <img alt="" src="./images/footer/simpleplay.png" alt="Simple play" />
    //           <img alt="" src="./images/footer/gameart.png" alt="Game Art" />
    //           <img alt="" src="./images/footer/playngo.png" alt="Play NGO" />
    //           <img alt="" src="./images/footer/jili.png" alt="Jili" />
    //           <img alt="" src="./images/footer/pussy888.png" alt="Pussy 888" />
    //           <img alt="" src="./images/footer/918kaya.png" alt="918 Kaya" />
    //           <img alt="" src="./images/footer/hc-slot.png" alt="HC-Slot" />
    //           <img alt="" src="./images/footer/nextspin.png" alt="nextspin" />
    //           <img alt="" src="./images/footer/spadegaming.png" alt="Spade gaming" />
    //           <img alt="" src="./images/footer/hs4d.png" alt="HS 4d" />
    //           <img alt="" src="./images/footer/uuslots.png" alt="UU Slots" />
    //           <img alt="" src="./images/footer/megah5.png" alt="Mega H5" />
    //           <img alt="" src="./images/footer/wow.png" alt="wow" />
    //         </div>
    //       </div>
    //     </div>

    //     <div className='footer-copy-right'>
    //       <span>Copyright © {(new Date()).getFullYear()} VGsoft 18+</span>
    //     </div>
    //   </footer>
    // </div>
  );
};

export default Footer;
