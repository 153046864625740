import React, { useCallback, useEffect, useState } from "react";
import http from "service/http";
import queryString from "query-string";
import store from "store";
import config from "config";
// import { useTranslation } from "react-i18next";
import useLoading from "../loading/hook";
import useDialog from "../dialog/hook";
import { Input } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
} from "@ant-design/icons";
import banks from "../../constants/banks";
import { useSelector } from "react-redux";
import phoneNumber from "../../constants/phoneNumber";
import { showNotification } from "../../utils/function";
import { ETypeNotification } from "../../constants/gameData";

function Login({ classes, history }) {
  // const { t } = useTranslation();

  const {
    location: { search },
  } = history;

  const user = store.get("user") || {};

  const user_account_no = user.user_account_no || "";

  const isVisible = useSelector((state) => state.modal.loginModalVisible);

  const [loading, withLoading] = useLoading(false);

  const [loadingbtn, setLoading] = useState(false);

  const [errorField, setErrorField] = useState([""]);

  // const [step, updateStep] = useState(1);

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: "Register Error",
    btnLabel: "Got it",
    type: "error",
  });

  useEffect(() => {
    if (!user_account_no) return;
    history.push("/");
  }, [user_account_no, isVisible]);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,12}$/;

  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };

  const alphaNumericRegex = /^[A-Za-z0-9]+$/;

  function isAlphaNumeric(str) {
    return alphaNumericRegex.test(str);
  }
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  function isValidEmail(email) {
    return emailRegex.test(email);
  }

  const [registerInfo, updateRegisterInfo] = useState({
    username: "",
    fullname: "",
    password: "",
    reTypePassword: "",
    bankName: "",
    bankAccount: "",
    bankAccountNumber: "",
    email: "",
    mobile_phone: "",
    prefixPhoneNumber: "+66",
    otp: ""
  });

  const handleValidate = useCallback(() => {
    const errors = [];
    if (!registerInfo.username.trim()) {
      errors.push(["username"]);
    }
    if (
      registerInfo.username.trim() &&
      !isAlphaNumeric(registerInfo.username)
    ) {
      errors.push(["usernameValidate"]);
    }
    // if (!registerInfo.fullname.trim()) {
    //   errors.push(["fullname"]);
    // }
    if (!registerInfo.password.trim()) {
      errors.push(["password"]);
    }

    if (registerInfo.password && !validatePassword(registerInfo.password)) {
      errors.push(["passwordValidate"]);
    }

    if (
      registerInfo.password &&
      registerInfo.reTypePassword !== registerInfo.password
    ) {
      errors.push(["reTypePassword"]);
    }

    if (!registerInfo.bankName.trim() || registerInfo.bankName.trim() === "") {
      errors.push(["bankName"]);
    }

    if (!registerInfo.bankAccount.trim()) {
      errors.push(["bankAccount"]);
    }

    if (!registerInfo.bankAccountNumber.trim()) {
      errors.push(["bankAccountNumber"]);
    }

    // if (!registerInfo.email.trim()) {
    //   errors.push(["email"]);
    // }

    if (registerInfo.email && !isValidEmail(registerInfo.email)) {
      errors.push(["emailValidate"]);
    }

    if (!registerInfo.mobile_phone.trim()) {
      errors.push(["mobile_phone"]);
    }
    if (errors.length > 0) {
      setErrorField(errors);
      return true;
    }
    return false;
  }, [registerInfo]);


  const onSendOTP = async (data) => {
    try {

     const errors = [];
      if (!data.username.trim()) {
        errors.push(["username"]);
      }
      if (
        data.username.trim() &&
        !isAlphaNumeric(registerInfo.username)
      ) {
        errors.push(["usernameValidate"]);
      }

      if (!registerInfo.mobile_phone.trim()) {
        errors.push(["mobile_phone"]);
      }
      if (errors.length > 0) {
        setErrorField(errors);
        return;
      }

      setLoading(true);
      const payload = {
        fullname: data.username,
        phoneNumber: `${data.prefixPhoneNumber}${data.mobile_phone}`
      };
      await withLoading(() =>
        http.post({ path: "sendOtp", payload })
      );
      showNotification(ETypeNotification.SUCCESS, "Please check OTP code by telegram app!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showDialogErrorWithMessage(error.message);
    }
  };

  const onRegister = async () => {
    try {
      setLoading(true);
      const { parent, user_level: userLevel } = queryString.parse(search);
      const payload = {
        fullname: registerInfo.bankAccount,
        bankName: registerInfo.bankName,
        bankAccount: registerInfo.bankAccount,
        bankAccountNumber: registerInfo.bankAccountNumber,
        password: registerInfo.password,
        username: registerInfo.username,
        otp: registerInfo.otp,
        mobile_phone: `${registerInfo.prefixPhoneNumber}${registerInfo.mobile_phone}`,
        // email: "",
        user_account_no: registerInfo.username,
        parent_id: parent || config.agent,
        user_level: userLevel || "PLAYER",
      };
      const { id_token: token, user } = await withLoading(() =>
        http.post({ path: "users", payload })
      );

      http.setJwtToken(token);
      store.set("token", token);
      store.set("user", user);
      history.push("home");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showDialogErrorWithMessage(error.message);
    }
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const isError = handleValidate();
      if (!isError) {
        onRegister();
      }
    },
    [onRegister, handleValidate]
  );

  // useEffect(() => {
  //   if (history.location.search === "?step=2") {
  //     updateStep(2);
  //   }
  // }, [history.location.search]);

  return (
    <section className="register-sec">
      <div className="max-container">
        <div className="register-wrapper">
          <div className="reg-left-column login-form">
            <div className="regtitle">Register</div>
            <form onSubmit={handleSubmit}>
              <input
                type="hidden"
                id="ioBB"
                name="ioBB"
                value="0400bpNfiPCR/AUNf94lis1ztu5Divh068bw9BLeAQhFgJJjutezKCldRFka1IC5/G0PWRahEd1XkyvCA9qyxqpputed+trm4ecPAj5jvcfYvY51SymuXlKryeXc4KIy3xRrsW3b02b0TTBi8vF6RzScsce75xI3URwVbjkle2g4lDt7HVMsBHJtSq/AVGrJ6lBbiy08fE5mIlV0sOz7UkLVEd6rPtO0ffdTrpp4hqHvjacWZBamlGXoG/imnjwHY9HQtQzpGfcm0cR8X2Fd1ngNFGLDGZlWOX0jgul9FPLENvs3z2n2SaWqFaDl1FsZY8jwYa/hFBBVErqVT31SgfbGSQ2AVI8GyiSPbrugqIYFP1PvYB6U6HRcmuDWaCa0OIg2YKUKhF79cL3yhJ4h6cl88V+rdsbATajAyMeRup82fY15RZwU9zPDpHaSwVqaWdUNw+E1ob+/FRCJ3uese38lUZb/57l0F0BrUImY6c4xTDnz6D+YNUMLESoejCspRCic+NVPhtr8LLRP55sqU3Ao4WE7VDaOZWB8q5jdIPG4kfaN2zsh/xluv069DzFEA3vFJpQYAFUrDlfSKRv/eHV3QiboXLuw9Lm6EakrEZHPLeYI6ecnG8xseEcOjS9hgMLfGqRuOIrAFl90sOz7UkLVEd6rPtO0ffdTZqZAJAYrAhtw2dAlHfKKIDSMAgFZ/M+b/XQXiHK5P5k0bDyy2Q9fp1zxXVkhYr77KTMIYGWCBwYibAjilOCqFhz2ElaQjMLETEVdGJlxBwCc8acbcKqAuWf7gouzBPJaEMCy0s3hRLlX3uHnT/mMqxLKep0gAiQmCOnnJxvMbHi7rzbxH0ZCbqQct2fIUhDBy/ZGi/OZc1PWD1Ek94E2JxvvIApWVhTfoGVtYyeLHLRhEJESxSJLMa+Vx63F6egNooPmHSsxiCQQwLLSzeFEufTxOH0WU0cMwkGouP5PPyvQ78T0AGn/1yw+Jld00Kp8wuaZfmfFIvJAUY1iBbirnwalR6tq92sLbXR7w4autUYHyf83JPkBAkRaH4lVulA8gRgN+Z8HAFhGFzd/m6snVgtcnU5pFekiB8n/NyT5AQJEWh+JVbpQPIEYDfmfBwBYRhc3f5urJ1YLXJ1OaRXpIgfJ/zck+QECqJOz7YCWoR9VJUiLP0GDKkhytzXcp5UctCOEBE+hjPemkK/5qUgri/23HnRmogZRO7RrVxpZYPhrRjLkmcdik+wQ2TVXxhg91gIC+aYnfxoMmQgjdlAzWw/xth+r1zKRcPyEt5ygHKM3a/Hp8xO2L2TmOqLA3SnRdExDkULz1c3duQLRXawj6jaM9TAbeYUps/CD92Uxc5egzAl7BXH5JHV8jVZdAL5r"
              />
              <input name="affiliate" type="hidden" value="" />

              <div className="rfwrap">
                <div className="reg-field">
                  <input
                    className="reg-input"
                    type="text"
                    maxlength="12"
                    // required
                    placeholder="User Name*"
                    // placeholder={t('ENTER_MOBILE')}
                    value={registerInfo.username}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        username: event.target.value,
                      });
                    }}
                  />
                </div>
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "usernameValidate") && (
                    <div className="error-msg d-block">
                      User Name must only contain alpha-numeric characters
                    </div>
                  )}
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "username") && (
                    <div className="error-msg d-block">
                      Please Provide An Unique User Name
                    </div>
                  )}
              </div>

              {/* <div className="rfwrap">
                <div className="reg-field">
                  <input
                    className="reg-input"
                    maxlength="150"
                    type="text"
                    // required
                    placeholder="Fullname*"
                    // placeholder={t('ENTER_DISPLAY_NAME')}
                    value={registerInfo.fullname}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        fullname: event.target.value,
                      });
                    }}
                  />
                </div>

                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "fullname") && (
                    <div className="error-msg d-block">
                      Full Name Must Match Bank Account Holder Name For
                      Withdrawal
                    </div>
                  )}
              </div> */}

              <div className="rfwrap">
                <div className="reg-field">
                  <select
                    style={{
                      width: "20%",
                      background: "#0b2e41",
                      color: "#fff",
                    }}
                    className="reg-input login-input text-white"
                    type="text"
                    // required
                    placeholder="Bank name"
                    value={registerInfo.prefixPhoneNumber}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        prefixPhoneNumber: event.target.value,
                      });
                    }}
                  >
                    {phoneNumber.map((b) => {
                      return <option value={b.title}>{b.title}</option>;
                    })}
                  </select>
                  <input
                    type="text"
                    style={{
                      width: "57%",
                      marginLeft: "2%",
                      marginRight: "2%",
                    }}
                    className="reg-input"
                    value={registerInfo.mobile_phone}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        mobile_phone: event.target.value,
                      });
                    }}
                    maxlength="150"
                    placeholder="Phone Number"
                  />
                  <button
                    type="button"
                    onClick={() => onSendOTP(registerInfo)}
                    style={{ width: "17%", height: "40px", minWidth: "17%" }}
                    className="login-btn  cursor-pointer"
                  >
                    Verify
                  </button>
                </div>
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "mobile_phone") && (
                    <div className="error-msg d-block">
                      Please Provide A Valid Contact Number. eg:0181234567
                    </div>
                  )}
              </div>

              <div className="rfwrap">
                <div className="reg-field">
                  <input
                    type="text"
                    className="reg-input"
                    value={registerInfo.otp}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        otp: event.target.value,
                      });
                    }}
                    maxlength="150"
                    placeholder="OTP Code"
                  />
                </div>
              </div>

              <div className="rfwrap">
                <div className="reg-field">
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    className="reg-input"
                    maxLength={"12"}
                    // required
                    value={registerInfo.password}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        password: event.target.value,
                      });
                    }}
                    iconRender={(visible) =>
                      !visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "password") && (
                    <div className="error-msg d-block">
                      Password is required
                    </div>
                  )}
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "passwordValidate") && (
                    <div className="error-msg d-block">
                      Between 6 To 12 Alphanumeric (A-Z, a-z, 0-9) Character
                      Only
                    </div>
                  )}
              </div>
              <div className="rfwrap">
                <div className="reg-field">
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirm Password"
                    className="reg-input"
                    maxLength={"12"}
                    // required
                    value={registerInfo.reTypePassword}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        reTypePassword: event.target.value,
                      });
                    }}
                    iconRender={(visible) =>
                      !visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "reTypePassword") && (
                    <div className="error-msg d-block">
                      The two passwords that you entered do not match!
                    </div>
                  )}
              </div>
              <div className="rfwrap">
                <div className="reg-field">
                  <select
                    style={{ background: "#0b2e41", color: "#fff" }}
                    className="reg-input login-input text-white"
                    type="text"
                    // required
                    placeholder="Bank name"
                    value={registerInfo.bankName}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        bankName: event.target.value,
                      });
                    }}
                  >
                    <option value="">Bank name</option>
                    {banks.map((b) => {
                      return <option value={b.title}>{b.title}</option>;
                    })}
                  </select>
                </div>
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "bankName") && (
                    <div className="error-msg d-block">
                      Bank name is required.
                    </div>
                  )}
              </div>

              <div className="rfwrap">
                <div className="reg-field">
                  <input
                    className="reg-input"
                    type="text"
                    // required
                    placeholder="Bank Account Name"
                    value={registerInfo.bankAccount}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        bankAccount: event.target.value,
                        fullname: event.target.value,
                      });
                    }}
                  />
                </div>
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "bankAccount") && (
                    <div className="error-msg d-block">
                      Bank Account Name is required.
                    </div>
                  )}
              </div>

              <div className="rfwrap">
                <div className="reg-field">
                  <input
                    className="reg-input"
                    placeholder="Bank Account No"
                    type="text"
                    value={registerInfo.bankAccountNumber}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        bankAccountNumber: event.target.value,
                      });
                    }}
                  />
                </div>
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "bankAccountNumber") && (
                    <div className="error-msg d-block">
                      Bank Account No is required.
                    </div>
                  )}
              </div>

              {/* <div className="rfwrap">
                <div className="reg-field">
                  <input
                    id="txt_email"
                    name="txt_email"
                    type="text"
                    className="reg-input"
                    value={registerInfo.email}
                    onChange={(event) => {
                      updateRegisterInfo({
                        ...registerInfo,
                        email: event.target.value,
                      });
                    }}
                    maxlength="150"
                    placeholder="Email"
                  />
                </div>
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "emailValidate") && (
                    <div className="error-msg d-block">
                      Email Address is invalid
                    </div>
                  )}
                {errorField.length > 0 &&
                  errorField.some((x) => x[0] === "email") && (
                    <div className="error-msg d-block">
                      Please Provide An Active Email Address To Facilitate
                      Future Payment Related Correspondences
                    </div>
                  )}
              </div> */}

              <div className="reg-btn-wrap">
                <button
                  type="submit"
                  className="join-reg-btn cursor-pointer"
                  disabled={loading || loadingbtn}
                >
                  REGISTER NOW!
                </button>
              </div>
              <div className="agree-text">
                By clicking the <span className="yltext">REGISTER</span> button,
                I hereby acknowledge that I am above 18 years old and have read
                and accepted your{" "}
                <a
                  href="/"
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                >
                  Terms &amp; Conditions
                </a>
              </div>
            </form>
          </div>
          <div className="reg-right-column">
            <div className="fun-begin">
              <div className="regtext-1">Your Fun Begin Here!</div>
              <div className="regtext-2">KISS VIP Your Place To Win</div>
              <ul className="fun">
                <li>
                  <span className="yltext">THB 10,000</span> Legend Spin
                  Tournament Prize
                </li>
                <li>
                  <span className="yltext">Up to THB 688</span> Special
                  Attendance Bonus
                </li>
                <li>
                  <span className="yltext">THB 1000</span> Crazy Booster Bonus
                </li>
                <li>
                  <span className="yltext">Fortune Wheel</span> - Win Gold Bar
                  Daily Spin, Daily Win
                </li>
              </ul>
            </div>
            <div className="iframe-video">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube-nocookie.com/embed/videoseries?si=PR5W1AP5AfyilqCA&amp;controls=0&amp;list=PLcA-EFCMNnL22KgLoKiAdiy7pzpe6WjGk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen=""
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <ErrorDialog />
    </section>
    // <LoginDialog
    //   tabActive="register"
    //   onClose={history.goBack}
    // />
  );
}

export default Login;
