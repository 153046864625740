import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Banking from "../NewBankingMb/banking";
import formCreateInputs from "service/form/create";
import { useForm } from "react-final-form-hooks";
import http from "service/http";
import store from "store";
import { useTranslation } from "react-i18next";
import styles from "./style";
import useLoading from "../loading/hook";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./style.css";
import MenuItemBoxLeftMobile from "../../component/layout/BoxLeft/MenuItemBoxLeftMobile";
import MenuItemBoxLeft from "../../component/layout/BoxLeft/MenuItemBoxLeft";
import MainWallet from "../../component/layout/BoxLeft/MainWallet";
import config from "config";

function HistoriesNewTest({ classes, history }) {
  const user = store.get("user");

  const { t } = useTranslation();

  const [loading, withLoading] = useLoading(false);

  const [games, updateGame] = useState([]);

  const [histories, updateHistories] = useState([]);

  const [gameBalances, updateGameBalances] = useState([]);

  const [userInfo, setUserInfo] = useState(user);

  const [balance, setBalance] = useState(0);

  const [transactionType, setTransactionType] = useState("winlose");

  // const handleChangeTypes = (typeTr) => {
  //   setChangeTypes(typeTr)
  // }

  const fetchAccount = async () => {
    const { user_id: userId } = user;
    const [userResp, gameBalancesResp] = await withLoading(() =>
      Promise.all([
        http.get({ path: "me" }),
        http.get({ path: `users/${userId}/balance-games` }),
      ])
    );
    updateGameBalances(
      gameBalancesResp
        .filter((i) => i)
        .filter(({ game_code: gameCode }) =>
          config.gamesAvailable.includes(gameCode)
        )
    );
    setUserInfo(userResp);

    store.set("user", userResp);

    let bl = userResp.user_money || 0;
    if (bl === 0) {
      gameBalancesResp
        .filter((x) => x.balance.game_code !== "asiagaming")
        .forEach((r) => {
          bl += Number(r.balance.balance || 0);
        });
    }

    store.set("userBalance", bl);

    store.set("userBalanceTime", Date.now());

    setBalance(bl);
  };

  const onPullOut = async () => {
    const { user_id: userId } = store.get("user");
    const tasks = [];
    gameBalances.forEach(({ gameId, balance, game_code }) => {
      if (balance > 1 && game_code !== "asiagaming") {
        const providerCode = game_code === "ag_galaxy" ? "?ProviderId=AG" : "";
        tasks.push(
          http.post({
            path: `users/${userId}/game/${gameId}/withdraw${providerCode}`,
            payload: {
              amount: balance,
            },
          })
        );
      }
    });
    await withLoading(() => Promise.all(tasks));
    fetchAccount();
  };

  useEffect(() => {
    fetchAccount();
    http.get({ path: "games" }).then(updateGame);
  }, []);

  const onSubmit = async ({ txType, type, fromDate, toDate }) => {
    try {
      const params = {
        fromDate: moment(fromDate).format("YYYY-MM-DD 00:00:00"),
        toDate: moment(toDate).format("YYYY-MM-DD 23:59:59"),
        game: type.split("-")[0],
        txType,
      };
      await withLoading(() =>
        http
          .get({
            path: `report/transaction-history`,
            params: {
              ...params,
              game: params.game !== "all" ? params.game : undefined,
            },
          })
          .then((rs) => {
            let rabats = rs.rabats;
            if (params.txType === "winlose" && params.game !== "all")
              rabats = rabats.filter((x) => x.game_code === params.game);
            updateHistories(rabats);
          })
      );
    } catch (error) {
      throw error;
    }
  };

  // const schema = Joi.object().keys({
  //   type: Joi.string().label("Game"),
  //   fromDate: Joi.date()
  //     .label("From Date")
  //     .required(),
  //   toDate: Joi.date()
  //     .label("To Date")
  //     .required(),
  // });

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: {
      fromDate: moment().toDate(),
      toDate: moment().toDate(),
      type: "all-",
      txType: "winlose",
    },
  });

  const [txType, type, fromDate, toDate] = formCreateInputs(
    ["txType", "type", "fromDate", "toDate"],
    form
  );

  return (
    <div className="content-body">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "180px",
            minHeight: "180px",
            textAlign: "center",
            marginTop: "80px",
          }}
          className="pro_title"
        >
          {t("LOADING")} ...
        </div>
      ) : (
        <div className="deposit-sec">
          <MenuItemBoxLeftMobile history={history} _pageName={"History"} />
          <div className="af-container">
            <MenuItemBoxLeft history={history} _pageName={"History"} promotion={userInfo.promotion}/>
            <div className="afcolumn-right">
              <div className="afinner">
                <MainWallet
                  userInfo={userInfo}
                  balance={balance}
                  onPullOut={onPullOut}
                />
                <div className="frame-container">
                  <form onSubmit={handleSubmit}>
                    <div className="aform-wrapper">
                      <div className="af-submenu-wrap history" id="btntap">
                        <a
                          href="/"
                          class={
                            transactionType === "winlose"
                              ? "afsub-btn active"
                              : "afsub-btn"
                          }
                          data-type="d"
                          onClick={(e) => {
                            setTransactionType("winlose");
                            txType.input.onChange({
                              target: { value: "winlose" },
                            });
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span>Winlose</span>
                        </a>
                        <a
                          href="/"
                          class={
                            transactionType === "deposit"
                              ? "afsub-btn active"
                              : "afsub-btn"
                          }
                          data-type="d"
                          onClick={(e) => {
                            setTransactionType("deposit");
                            txType.input.onChange({
                              target: { value: "deposit" },
                            });
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span>Deposit</span>
                        </a>
                        <a
                          href="/"
                          class={
                            transactionType === "withdraw"
                              ? "afsub-btn active"
                              : "afsub-btn"
                          }
                          data-type="w"
                          onClick={(e) => {
                            setTransactionType("withdraw");
                            txType.input.onChange({
                              target: { value: "withdraw" },
                            });
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span>Withdraw</span>
                        </a>
                        <a
                          href="/"
                          class={
                            transactionType === "bonus"
                              ? "afsub-btn active"
                              : "afsub-btn"
                          }
                          data-type="w"
                          onClick={(e) => {
                            setTransactionType("bonus");
                            txType.input.onChange({
                              target: { value: "bonus" },
                            });
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span>Bonus</span>
                        </a>
                        <a
                          href="/"
                          class={
                            transactionType === "comm"
                              ? "afsub-btn active"
                              : "afsub-btn"
                          }
                          data-type="w"
                          onClick={(e) => {
                            setTransactionType("comm");
                            txType.input.onChange({
                              target: { value: "comm" },
                            });
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span>Comm</span>
                        </a>
                      </div>
                      <div className="af-field d-none">
                        <input
                          type="text"
                          className="afinput history"
                          value={transactionType}
                          maxlength="10"
                          autocomplete="off"
                          {...txType.input}
                        />
                      </div>
                      <div className="depo-divider"></div>
                      <div className="date-form-wrapper">
                        <div className="history-date-wrapper">
                          <div className="hsword">Games</div>
                          <div className="af-field">
                            <select
                              className="afinput"
                              id="psuite"
                              {...type.input}
                            >
                              <option value="all-">All</option>
                              {games.map((g) => (
                                <option
                                  value={g.game_code + "-" + g.label}
                                  key={g.game_code + g.label}
                                >
                                  {g.label || g.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="hsword"></div>
                          <div className="af-field">
                            <DatePicker
                              showIcon={true}
                              className="history-new-styled-inputdate"
                              onChange={(date) => {
                                fromDate.input.onChange({
                                  target: { value: date },
                                });
                              }}
                              popperModifiers={{
                                preventOverflow: {
                                  enabled: true,
                                },
                              }}
                              selected={fromDate.input.value}
                            />
                            <span className="date-icon">
                              <i className="fas fa-calendar-alt"></i>
                            </span>
                          </div>
                          <div className="hsword">To</div>
                          <div className="af-field">
                            <DatePicker
                              showIcon={true}
                              className="history-new-styled-inputdate"
                              onChange={(date) =>
                                toDate.input.onChange({
                                  target: { value: date },
                                })
                              }
                              popperModifiers={{
                                preventOverflow: {
                                  enabled: true,
                                },
                              }}
                              selected={toDate.input.value}
                            />
                          </div>
                        </div>
                        <div className="history-btn-wrap">
                          <button
                            type="submit"
                            className="join-reg-btn history btnsearch"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <div
                        className="table-responsive history-table-wrap"
                        id="resultlist"
                      >
                        <Banking bankings={histories} loading={loading} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(HistoriesNewTest);
