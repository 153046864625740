import React from "react";
import "./Styles.css";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { showLoginModal } from "../../store/modalSlice";

const data = [
  {
    image: "./images/banner/Banners.jpg",
  },
];

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 4,
  slidesToScroll: 1,
  adaptiveHeight: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1124,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Head = ({ image, user, history }) => {
  const dispatch = useDispatch();
  
  const user_account_no =
    user && user.user_account_no ? user.user_account_no : "";

  return (
    <div style={{ width: "100%" }}>
      <div className="">
        <img src={image} alt="banner" />
        <div className="new-header-styled-header-menu-feature head-styled-banner2">
          <div className="new-header-styled-logo">
            <div className="justify-between d-flex">
              <img
                className="new-header-styled-img-deposit"
                src="./images/icon/s-join.png"
                alt="logo"
              />
              <div className="d-flex t-white ml-12 flex-column my-auto">
                <p className="mb-0 fw-700 fs-20">JOIN*</p>
                <span className="fw-600">It only takes a moment</span>
              </div>
            </div>
          </div>
          <div className="new-header-styled-logo">
            <div className="justify-between d-flex">
              <img
                className="new-header-styled-img-deposit"
                src="./images/icon/s-deposit.png"
                alt="logo"
              />

              <div className="d-flex t-white ml-12 flex-column my-auto">
                <p className="mb-0 fw-700 fs-20">Deposit</p>
                <span className="fw-600">Select your deposit option</span>
              </div>
            </div>
          </div>
          <div className="new-header-styled-logo">
            <div className="justify-between d-flex">
              <img
                className="new-header-styled-img-deposit"
                src="./images/icon/s-withdraw.png"
                alt="logo"
              />

              <div className="d-flex t-white ml-12 flex-column my-auto">
                <p className="mb-0 fw-700 fs-20">Withdraw</p>
                <span className="fw-600">Withdraw your winning</span>
              </div>
            </div>
          </div>
        </div>
        {!user_account_no && (
          <section className="mobile-login-reg">
            <button
              type="button"
              className="mobile-join-btn cursor-pointer"
              onClick={() => history.push('/register')}
            >
              Join Now
            </button>
            <button
              type="button"
              className="mobile-login-btn cursor-pointer"
              data-toggle="modal"
              data-target="#login"
              onClick={() => dispatch(showLoginModal())}
            >
              Login
            </button>
          </section>
        )}
      </div>
    </div>
  );
};
export default Head;
