import React from "react";
import "./boxstyle.css";

const MenuItemBoxLeft = ({ history, _pageName, promotion }) => {
  const pageName = _pageName || "Deposit";
  return (
    <div className="afcolumn-left">
      <div className="player-vip-box">
        <div className="vip-badge-img">
          <img src="./images/menu/Normal.png" alt="" />
        </div>
        <div className="mblvl-text-1">Member Level</div>
        <div className="mblvl-text-2">NORMAL</div>
        {promotion && (
          <div className="mblvl-text-1">{promotion.current}/{promotion.targetTurnover}</div>
        )}
      </div>
      <div className="afmenu-box">
        <ul className="nav afmenuside-nav">
          <li className="nav-item">
            <a
              className={`mslink ${pageName === "Deposit" ? "active" : ""}`}
              id="menu-deposit-page-redirect"
              href="/deposit"
            >
              <span className="afn-icon">
                <img src="./images/icon/af-deposit.png" alt="" />
              </span>
              <span>Deposit</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`mslink ${pageName === "Withdrawal" ? "active" : ""}`}
              id="menu-withdrawal"
              href="/withdraw"
            >
              <span className="afn-icon">
                <img src="./images/icon/af-withdraw.png" alt="" />
              </span>
              <span>Withdrawal</span>
            </a>
          </li>

          {/* <li className="nav-item">
            <a
              className={`mslink ${pageName === "Transfer" ? "active" : ""}`}
              id="menu-transfer"
              href="/"
            >
              <span className="afn-icon">
                <img src="./images/icon/af-transfer.png" alt="" />
              </span>
              <span>Transfer</span>
            </a>
          </li> */}
          <li className="nav-item">
            <a
              className={`mslink ${pageName === "History" ? "active" : ""}`}
              id="menu-history"
              href="/histories"
            >
              <span className="afn-icon">
                <img src="./images/icon/af-statement.png" alt="" />
              </span>
              <span>History</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`mslink ${pageName === "Profile" ? "active" : ""}`}
              id="menu-profile"
              href="/account"
            >
              <span className="afn-icon">
                <img src="./images/icon/af-profile.png" alt="" />
              </span>
              <span>Profile</span>
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              className={`mslink ${pageName === "PrivateMessage" ? "active" : ""}`}
              id="menu-inbox"
              href="/"
            >
              <span className="afn-icon">
                <img src="images/icon/af-message.png" alt="" />
              </span>
              <span>Private Message</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`mslink ${pageName === "CashRebate" ? "active" : ""}`}
              id="menu-inbox"
              href="/"
            >
              <span className="afn-icon">
                <img src="images/icon/af-rebate.png?1" alt="" />
              </span>
              <span>Cash Rebate</span>
            </a>
          </li> */}
        </ul>
      </div>

      {/* <ul className="menu-boxleft-menu">
        <li className={`${pageName === "Deposit" ? "active" : ""}`}>
          <a href="/deposit">Deposit</a>
        </li>
        <li className={`${pageName === "Withdrawal" ? "active" : ""}`}>
          <a href="/withdraw">Withdrawal</a>
        </li>
        <li className={`${pageName === "Transfer" ? "active" : ""}`}>
          <a href="/">Transfer</a>
        </li>
        <li className={`${pageName === "History" ? "active" : ""}`}>
          <a href="/histories">History</a>
        </li>
        <li className={`${pageName === "Profile" ? "active" : ""}`}>
          <a href="/account">Profile</a>
        </li>
        <li className={`${pageName === "PrivateMessage" ? "active" : ""}`}>
          <a href="/">Private Message</a>
        </li>
        <li className={`${pageName === "CashRebate" ? "active" : ""}`}>
          <a href="/">Cash Rebate</a>
        </li>
      </ul> */}
    </div>
  );
};

export default MenuItemBoxLeft;
