import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Slide } from "@material-ui/core";
// import { PropagateLoader } from "react-spinners";
// import Header from "component/header";
// import CloseIcon from "@material-ui/icons/Close";
// import { map, isEmpty } from "ramda";
// import Bottom from "component/bottom";
import { useTranslation } from "react-i18next";
import store from "store";
import http from "service/http";
import useLoading from "../loading/hook";
import styles from "./style";
import SLOTS from "./constant";
// import config from "config";
// import gameData from "../../constants/gameData";
import queryString from "query-string";
// import GameAccount from "../../component/gameAccount";
// import Head from "../../component/layout/Head";
// import Testimonials from "../../component/layout/Testimonials";
// import TotalPlay from "../../component/layout/TotalPlay";
import component from "../../component/";
// import SlotGameComp from "feature/slotGameComp";

const { useDialog } = component;

// const listSlotGame = [
//   {
//     src: "./images/home/slotkingmaker.png",
//     classshow: "pages-icon-hot",
//   },
//   {
//     src: "./images/home/slotrich88.png",
//     classshow: "pages-icon-hot",
//   },
//   {
//     src: "./images/home/slotsg.png",
//     classshow: "pages-icon-hot",
//   },
//   {
//     src: "./images/home/slotjbd.png",
//     classshow: "pages-icon-hot-none",
//   },
//   {
//     src: "./images/home/slotCG.png",
//     classshow: "pages-icon-hot-none",
//   },
//   {
//     src: "./images/home/slotfachai.png",
//     classshow: "pages-icon-hot-none",
//   },
//   {
//     src: "./images/home/slotjoker.png",
//     classshow: "pages-icon-hot-none",
//   },
//   {
//     src: "./images/home/slotpgv2.png",
//     classshow: "pages-icon-hot-none",
//   },
//   {
//     src: "./images/home/slotpp.png",
//     classshow: "pages-icon-hot-none",
//   },
//   {
//     src: "./images/home/slotredtiger.png",
//     classshow: "pages-icon-hot-none",
//   },
//   {
//     src: "./images/home/slotace333.png",
//     classshow: "pages-icon-hot-none",
//   },
// ];
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const generateDeeplink = ({ id, account, password }) => {
  const proxy = {
    20: `evo888android://lobbyevoandroid?account=${account}&password=${password}`,
    3: `lobbyplay8oy://lobbyplay8oy?account=${account}&password=${password}`,
    11: `lobbymegarelease://lobbymegarelease?account=${account}&password=${password}`,
    2: `lobbykiss://lobbykiss?account=${account}&password=${password}`,
    8: `joker://www.joker123.net/mobile?data=${account}`,
    1: "",
  };

  return proxy[id];
};

function home({ history, classes }) {
  const {
    location: { search },
  } = history;

  const [loading, withLoading] = useLoading(false);

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: "Error",
    btnLabel: "Got it",
    type: "error",
  });

  const [gameSelected, updateGameSelected] = React.useState({});
  const [games, setGames] = React.useState([]);
  const { user_id: userId } = store.get("user") || { user_id: undefined };
  const { game, html55 } = queryString.parse(search);
  const gamesSetup = async () => {
    const gameList = await http.get({ path: "games" });
    setGames(gameList);
    return gameList;
  };

  const [activeTab, setActiveTab] = useState("Hot");

  const loginToGame = async (
    { src, label, route, id, redirect, target, html5, code },
    gameList = null
  ) => {
    if (target === "_blank") {
      window.open(route);
    }
    if (redirect) {
      history.push(route);
      return;
    }
    if (
      !gameList &&
      (id === null || !games.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "Please login first !" });
      return;
    }
    if (
      gameList &&
      (id === null || !gameList.find(({ id: gameId }) => id === gameId))
    ) {
      updateGameSelected({ label, src, err: "System Maintenance " });
      return;
    }
    updateGameSelected({ label, src, route, id });
    try {
      const providerCode = code === "asiagaming-galaxy" ? "?ProviderId=AG" : "";
      const { balance, err, game_password: password } = await withLoading(() =>
        http
          .post({
            path: `users/${userId}/game/logout${providerCode}`,
            payload: {
              gameLoginId: id,
            },
          })
          .catch((error) => {
            if (error.message !== "Not enough money !") {
              // eslint-disable-next-line no-throw-literal
              throw { message: error.message };
            }
            return true;
          })
          .then(() =>
            http.get({
              path: `users/${userId}/games/${id}/balance`,
              params: { ProviderId: "AG" },
            })
          )
      );

      let deeplinkAcc = balance.account;
      if (code === "joker" && html5 === 0)
        deeplinkAcc = await http
          .get({
            json: false,
            path: `games/joker/login`,
            params: { GameCode: 0, mode: "M" },
          })
          .then((rs) => JSON.parse(rs).sign);
      // console.log(html5, code, html5 === 0, deeplinkAcc)
      const outDeeplink = generateDeeplink({
        id,
        account: deeplinkAcc,
        password,
      });
      updateGameSelected({
        label,
        src,
        route,
        id,
        balance: balance.balance,
        account: balance.account,
        err,
        deeplink: outDeeplink,
        password,
        html5,
        code,
      });
      if (
        code !== "xe88" &&
        (html5 === 1 || !outDeeplink || outDeeplink === "")
      )
        history.push(route);
    } catch (e) {
      showDialogErrorWithMessage(e.message);
    }
  };
  const fetchGame = async () => {
    const gameList = await gamesSetup();
    // console.log(gameList)
    if (game) {
      if (html55 === 1) {
        // console.log(SLOTS, html55)
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink,
          html5,
          code,
        } = SLOTS.find((x) => x.code === game && x.html5 === html55);
        loginToGame(
          { src, label, route, id, redirect, target, deeplink, html5, code },
          gameList
        );
      } else {
        const {
          src,
          label,
          route,
          id,
          redirect,
          target,
          deeplink,
          html5,
          code,
        } = SLOTS.find((x) => x.code === game);
        loginToGame(
          { src, label, route, id, redirect, target, deeplink, html5, code },
          gameList
        );
      }
    }
  };
  React.useEffect(() => {
    if (userId) {
      http.post({ path: `users/${userId}/sync-game` });
    }

    fetchGame();
  }, []);

  const handleClose = () => {
    updateGameSelected({});
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="slots-sec">
        <img className="w-auto slots-sec-bg2" alt="" src="images/slots/gaming01.gif" />
        <div className="max-container">
          <div className="sdeco deco-1">
            <img alt="" className="w-auto" src="images/slots/deco-chery.png" />
          </div>
          <div className="sdeco deco-2">
            <img alt="" className="w-auto" src="images/slots/deco-bell.png" />
          </div>
          <div className="sdeco deco-3">
            <img
              alt=""
              className="w-auto"
              src="images/slots/deco-scatter.png"
            />
          </div>
          <div className="sdeco deco-4">
            <img alt="" className="w-auto" src="images/slots/deco-crown.png" />
          </div>
          <div className="slots-lobby-container">
            <div className="slots-provider">
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <div className="hot-slot">Hot</div>
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/playtech.png"
                    alt="Playtech Slot Casino"
                  />
                </span>
                <span>Playtech</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <div className="hot-slot">Hot</div>
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/pragmatic.png"
                    alt="Pragmatic Slot Casino"
                  />
                </span>
                <span>Pragmatic P.</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <div className="hot-slot">Hot</div>
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/qtech.png"
                    alt="Qtech Slot Casino"
                  />
                </span>
                <span>Qtech</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <div className="hot-slot">Hot</div>
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/live22.png"
                    alt="Live22 Slot Casino"
                  />
                </span>
                <span>Live22</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <div className="hot-slot">Hot</div>
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/uuslots.png?1"
                    alt="Uuslot Live Casino"
                  />
                </span>
                <span>UU Slots</span>
              </a>
              {/* <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <div className="hot-slot">Hot</div>
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/megah5.png?1"
                    alt="Megah5 Slot Casino"
                  />
                </span>
                <span>MEGA H5</span>
              </a> */}
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <div className="hot-slot">Hot</div>
                <span className="pdv-icon">
                  <img
                    className="w-auto"
                    src="images/slots/icon/cq9.png"
                    alt="Cq9 Slot Casino"
                  />
                </span>
                <span>CQ9</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <div className="hot-slot">Hot</div>
                <span className="pdv-icon">
                  <img
                    className="w-auto"
                    src="images/slots/icon/wow.png"
                    alt=""
                  />
                </span>
                <span>Wow Gaming</span>
              </a>

              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    className="w-auto"
                    src="images/slots/icon/jdb.png"
                    alt="Jdb Slot Casino"
                  />
                </span>
                <span>JDB</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/jili.png"
                    alt="Jili Slot Casino"
                  />
                </span>
                <span>Jili</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/asiagaming.png"
                    alt="Asiagaming Slot Casino"
                  />
                </span>
                <span>Asia Gaming</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/playngo.png"
                    alt="Playngo Slot Casino"
                  />
                </span>
                <span>Play'nGo</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/playstar.png"
                    alt="Playstar Slot Casino"
                  />
                </span>
                <span>PlayStar</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/gameart.png"
                    alt="Gameart Slot Casino"
                  />
                </span>
                <span>GameArt</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/gameplay.png"
                    alt="Gameplay Slot Casino"
                  />
                </span>
                <span>GamePlay</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/genesis.png"
                    alt="Genesis Slot Casino"
                  />
                </span>
                <span>Genesis</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/simpleplay.png"
                    alt="Simpleplay Slot Casino"
                  />
                </span>
                <span>SimplePlay</span>
              </a>
              {/* <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/worldmatch.png"
                    alt="Worldmatch Slot Casino"
                  />
                </span>
                <span>WorldMatch</span>
              </a> */}
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}}className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    className="w-auto"
                    src="images/slots/icon/lgd.png"
                    alt="LGD Slot Casino"
                  />
                </span>
                <span>LGD</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/netent.png"
                    alt="Netent Slot Casino"
                  />
                </span>
                <span>Netent</span>
              </a>

              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    className="w-auto"
                    src="images/slots/icon/hc.png"
                    alt="HC Slot Casino"
                  />
                </span>
                <span>HC Slot</span>
              </a>

              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/Microgaming.png"
                    alt="Microgaming Slot Casino"
                  />
                </span>
                <span>Microgaming</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/Nextspin.png"
                    alt="Nextspin Slot Casino"
                  />
                </span>
                <span>Nextspin</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/Spadegaming.png"
                    alt="Spadegaming Slot Casino"
                  />
                </span>
                <span>Spadegaming</span>
              </a>
              <a href="/" onClick={(e) => { e.preventDefault(); alert("Comming Soon")}} className="spvdbox ">
                <span className="pdv-icon">
                  <img
                    src="images/slots/icon/joker.png"
                    alt="Joker Slot Casino"
                  />
                </span>
                <span>Joker</span>
              </a>
            </div>

            <div className="slots-lobby">
              <div className="slot-filter-wrap">
                <div className="sf-btn-wrap">
                  <button
                    id="all"
                    onClick={() => setActiveTab("All")}
                    className={`sfbtn cursor-pointer ${
                      activeTab === "All" ? "active" : ""
                    }`}
                  >
                    All
                  </button>
                  <button
                    id="hot"
                    onClick={() => setActiveTab("Hot")}
                    className={`sfbtn cursor-pointer ${
                      activeTab === "Hot" ? "active" : ""
                    }`}
                  >
                    Hot
                  </button>
                  <button
                    id="slot"
                    onClick={() => setActiveTab("Slots")}
                    className={`sfbtn cursor-pointer ${
                      activeTab === "Slots" ? "active" : ""
                    }`}
                  >
                    Slots
                  </button>
                  <button
                    id="arcade"
                    onClick={() => setActiveTab("Arcade")}
                    className={`sfbtn cursor-pointer ${
                      activeTab === "Arcade" ? "active" : ""
                    }`}
                  >
                    Arcade
                  </button>
                </div>
                <div className="slot-search-box">
                  <input
                    type="text"
                    className="slots-input"
                    placeholder="Search"
                    id="txt_Search"
                    name="txt_Search"
                    onkeyup="searchGames()"
                  />
                  <button className="slot-search-btn">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              {activeTab === "All" && (
                <div className="slots-container" id="gameTable">
                  <div className="slotgbox " id="bulls eye bells">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Bulls Eye Bells.jpg"
                      />
                    </div>
                    <div className="slot-name">Bulls Eye Bells </div>
                  </div>
                  <div
                    className="slotgbox "
                    id="football! cash collect powerplay jackpot"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Football! Cash Collect PowerPlay Jackpot.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Football! Cash Collect PowerPlay Jackpot
                    </div>
                  </div>
                  <div className="slotgbox " id="gold trio football">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Gold Trio Football.jpg"
                      />
                    </div>
                    <div className="slot-name">Gold Trio Football </div>
                  </div>
                  <div className="slotgbox " id="feng shui flip">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Feng Shui Flip.jpg"
                      />
                    </div>
                    <div className="slot-name">Feng Shui Flip </div>
                  </div>
                  <div
                    className="slotgbox "
                    id="wheels of flame powerplay jackpot"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Wheels of Flame PowerPlay Jackpot.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Wheels of Flame PowerPlay Jackpot
                    </div>
                  </div>
                  <div className="slotgbox " id="panther moon bonus lines">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Panther Moon Bonus Lines.jpg"
                      />
                    </div>
                    <div className="slot-name">Panther Moon Bonus Lines </div>
                  </div>
                  <div
                    className="slotgbox "
                    id="mega fire blaze dwarves and goblins"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Mega Fire Blaze Dwarves and Goblins.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Mega Fire Blaze Dwarves and Goblins
                    </div>
                  </div>
                  <div className="slotgbox " id="solar eclipse northern winds">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Solar Eclipse Northern Winds.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Solar Eclipse Northern Winds
                    </div>
                  </div>
                  <div
                    className="slotgbox "
                    id="mega fire blaze piggies and the bank ppjp"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Mega Fire Blaze Piggies and the Bank PPJP.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Mega Fire Blaze Piggies and the Bank PPJP
                    </div>
                  </div>
                  <div className="slotgbox " id="samurai fury">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Samurai Fury.jpg"
                      />
                    </div>
                    <div className="slot-name">Samurai Fury </div>
                  </div>
                  <div className="slotgbox " id="fire blaze sky queen megaways">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Fire Blaze Sky Queen Megaways.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Fire Blaze Sky Queen Megaways
                    </div>
                  </div>
                  <div className="slotgbox " id="solar eclipse jaguar's lair">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Solar Eclipse Jaguar's Lair.jpg"
                      />
                    </div>
                    <div className="slot-name">Solar Eclipse Jaguar's Lair</div>
                  </div>
                  <div
                    className="slotgbox "
                    id="fire blaze blue wizard powerplay jackpot"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Fire Blaze Blue Wizard Powerplay Jackpot.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Fire Blaze Blue Wizard Powerplay Jackpot
                    </div>
                  </div>
                  <div className="slotgbox " id="veils of venice">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Veils of Venice.jpg"
                      />
                    </div>
                    <div className="slot-name">Veils of Venice </div>
                  </div>
                  <div className="slotgbox " id="oink oink oink">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Oink Oink Oink.jpg"
                      />
                    </div>
                    <div className="slot-name">Oink Oink Oink </div>
                  </div>
                  <div className="slotgbox " id="macabra linx">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Macabra Linx.jpg"
                      />
                    </div>
                    <div className="slot-name">Macabra Linx </div>
                  </div>
                  <div
                    className="slotgbox "
                    id="leprechau's luck cash collect megaways"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Leprechau's Luck Cash Collect Megaways.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Leprechau's Luck Cash Collect Megaways
                    </div>
                  </div>
                  <div className="slotgbox " id="lunar link phoenix moons">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Lunar Link Phoenix Moons.jpg"
                      />
                    </div>
                    <div className="slot-name">Lunar Link Phoenix Moons </div>
                  </div>
                  <div className="slotgbox " id="lucky gift cash collect">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Lucky Gift Cash Collect.jpg"
                      />
                    </div>
                    <div className="slot-name">Lucky Gift Cash Collect </div>
                  </div>
                  <div className="slotgbox " id="mega fire blaze 3 wizards">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Mega Fire Blaze 3 Wizards.jpg"
                      />
                    </div>
                    <div className="slot-name">Mega Fire Blaze 3 Wizards </div>
                  </div>
                </div>
              )}

              {activeTab === "Slots" && (
                <div className="slots-container" id="gameTable">
                  <div className="slotgbox " id="bulls eye bells">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Bulls Eye Bells.jpg"
                      />
                    </div>
                    <div className="slot-name">Bulls Eye Bells </div>
                  </div>

                  <div
                    className="slotgbox "
                    id="football! cash collect powerplay jackpot"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Football! Cash Collect PowerPlay Jackpot.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Football! Cash Collect PowerPlay Jackpot
                    </div>
                  </div>
                  <div className="slotgbox " id="gold trio football">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Gold Trio Football.jpg"
                      />
                    </div>
                    <div className="slot-name">Gold Trio Football </div>
                  </div>
                  <div className="slotgbox " id="feng shui flip">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Feng Shui Flip.jpg"
                      />
                    </div>
                    <div className="slot-name">Feng Shui Flip </div>
                  </div>
                  <div
                    className="slotgbox "
                    id="wheels of flame powerplay jackpot"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Wheels of Flame PowerPlay Jackpot.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Wheels of Flame PowerPlay Jackpot
                    </div>
                  </div>
                  <div className="slotgbox " id="panther moon bonus lines">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Panther Moon Bonus Lines.jpg"
                      />
                    </div>
                    <div className="slot-name">Panther Moon Bonus Lines </div>
                  </div>
                  <div
                    className="slotgbox "
                    id="mega fire blaze dwarves and goblins"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Mega Fire Blaze Dwarves and Goblins.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Mega Fire Blaze Dwarves and Goblins
                    </div>
                  </div>
                  <div className="slotgbox " id="solar eclipse northern winds">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Solar Eclipse Northern Winds.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Solar Eclipse Northern Winds
                    </div>
                  </div>
                  <div
                    className="slotgbox "
                    id="mega fire blaze piggies and the bank ppjp"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Mega Fire Blaze Piggies and the Bank PPJP.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Mega Fire Blaze Piggies and the Bank PPJP
                    </div>
                  </div>
                  <div className="slotgbox " id="samurai fury">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Samurai Fury.jpg"
                      />
                    </div>
                    <div className="slot-name">Samurai Fury </div>
                  </div>
                  <div className="slotgbox " id="samurai fury">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Samurai Fury.jpg"
                      />
                    </div>
                    <div className="slot-name">Samurai Fury </div>
                  </div>
                  <div className="slotgbox " id="fire blaze sky queen megaways">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Fire Blaze Sky Queen Megaways.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Fire Blaze Sky Queen Megaways
                    </div>
                  </div>
                  <div className="slotgbox " id="solar eclipse jaguar's lair">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Solar Eclipse Jaguar's Lair.jpg"
                      />
                    </div>
                    <div className="slot-name">Solar Eclipse Jaguar's Lair</div>
                  </div>
                  <div
                    className="slotgbox "
                    id="fire blaze blue wizard powerplay jackpot"
                  >
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Fire Blaze Blue Wizard Powerplay Jackpot.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Fire Blaze Blue Wizard Powerplay Jackpot
                    </div>
                  </div>
                  <div className="slotgbox " id="veils of venice">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Veils of Venice.jpg"
                      />
                    </div>
                    <div className="slot-name">Veils of Venice </div>
                  </div>
                  <div className="slotgbox " id="oink oink oink">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Oink Oink Oink.jpg"
                      />
                    </div>
                    <div className="slot-name">Oink Oink Oink </div>
                  </div>
                </div>
              )}

              {activeTab === "Hot" && (
                <div className="slots-container" id="gameTable">
                  <div className="slotgbox  hot " id="nian nian you yu">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Nian Nian You Yu.jpg"
                      />
                    </div>
                    <div className="slot-name">Nian Nian You Yu</div>
                  </div>
                  <div className="slotgbox  hot " id="super lion">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Super Lion.jpg"
                      />
                    </div>
                    <div className="slot-name">Super Lion </div>
                  </div>
                  <div className="slotgbox  hot " id="inca jackpot">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Inca Jackpot.jpg"
                      />
                    </div>
                    <div className="slot-name">Inca Jackpot </div>
                  </div>
                  <div className="slotgbox  hot " id="highway kings">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Highway Kings.jpg"
                      />
                    </div>
                    <div className="slot-name">Highway Kings </div>
                  </div>
                  <div className="slotgbox  hot " id="golden tour">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Golden Tour.jpg"
                      />
                    </div>
                    <div className="slot-name">Golden Tour </div>
                  </div>
                  <div className="slotgbox  hot " id="buffalo blitz">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Buffalo Blitz.jpg"
                      />
                    </div>
                    <div className="slot-name">Buffalo Blitz </div>
                  </div>
                  <div className="slotgbox  hot " id="safari heat">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Safari Heat.jpg"
                      />
                    </div>
                    <div className="slot-name">Safari Heat </div>
                  </div>
                  <div className="slotgbox  hot " id="funky monkey">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Funky Monkey.jpg"
                      />
                    </div>
                    <div className="slot-name">Funky Monkey </div>
                  </div>
                  <div className="slotgbox  hot " id="epic ape">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Epic Ape.jpg"
                      />
                    </div>
                    <div className="slot-name">Epic Ape </div>
                  </div>
                  <div className="slotgbox  hot " id="great blue">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Great Blue.jpg"
                      />
                    </div>
                    <div className="slot-name">Great Blue </div>
                  </div>
                  <div className="slotgbox  hot " id="ugga bugga">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Ugga Bugga.jpg"
                      />
                    </div>
                    <div className="slot-name">Ugga Bugga </div>
                  </div>
                  <div className="slotgbox  hot " id="archer">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Archer.jpg"
                      />
                    </div>
                    <div className="slot-name">Archer </div>
                  </div>
                  <div className="slotgbox  hot " id="dolphin reef">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Dolphin Reef.jpg"
                      />
                    </div>
                    <div className="slot-name">Dolphin Reef </div>
                  </div>
                  <div className="slotgbox  hot " id="zhao cai jin bao">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Zhao Cai Jin Bao.jpg"
                      />
                    </div>
                    <div className="slot-name">Zhao Cai Jin Bao </div>
                  </div>
                  <div className="slotgbox  hot " id="triple monkey">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Triple Monkey.jpg"
                      />
                    </div>
                    <div className="slot-name">Triple Monkey </div>
                  </div>
                  <div className="slotgbox  hot " id="gem queen">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Gem Queen.jpg"
                      />
                    </div>
                    <div className="slot-name">Gem Queen </div>
                  </div>
                  <div className="slotgbox  hot " id="zhao cai tong zi">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Zhao Cai Tong Zi.jpg"
                      />
                    </div>
                    <div className="slot-name">Zhao Cai Tong Zi </div>
                  </div>
                  <div className="slotgbox  hot " id="jin qian wa">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        className="w-auto"
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Jin Qian Wa.jpg"
                      />
                    </div>
                    <div className="slot-name">Jin Qian Wa </div>
                  </div>
                </div>
              )}

              {activeTab === "Arcade" && (
                <div className="slots-container" id="gameTable">
                  <div className="slotgbox " id="perfect blackjack multihand 5">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Perfect Blackjack Multihand 5.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Perfect Blackjack Multihand 5
                    </div>
                  </div>
                  <div className="slotgbox " id="casino hold'em">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Casino Hold'Em.jpg"
                      />
                    </div>
                    <div className="slot-name">Casino Hold'Em </div>
                  </div>
                  <div className="slotgbox " id="cashback blackjack">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Cashback Blackjack.jpg"
                      />
                    </div>
                    <div className="slot-name">Cashback Blackjack </div>
                  </div>
                  <div className="slotgbox " id="premium blackjack">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Premium Blackjack.jpg"
                      />
                    </div>
                    <div className="slot-name">Premium Blackjack </div>
                  </div>
                  <div className="slotgbox " id="free chip blackjack">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Free Chip Blackjack.jpg"
                      />
                    </div>
                    <div className="slot-name">Free Chip Blackjack </div>
                  </div>
                  <div className="slotgbox " id="heads up hold'em">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Heads Up Hold'em.jpg"
                      />
                    </div>
                    <div className="slot-name">Heads Up Hold'em </div>
                  </div>
                  <div className="slotgbox " id="mobile blackjack">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Mobile Blackjack.jpg"
                      />
                    </div>
                    <div className="slot-name">Mobile Blackjack </div>
                  </div>
                  <div className="slotgbox " id="blackjack surrender">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Blackjack Surrender.jpg"
                      />
                    </div>
                    <div className="slot-name">Blackjack Surrender </div>
                  </div>
                  <div className="slotgbox " id="all bets blackjack">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/All Bets Blackjack.jpg"
                      />
                    </div>
                    <div className="slot-name">All Bets Blackjack </div>
                  </div>
                  <div className="slotgbox " id="baccarat">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Baccarat.jpg"
                      />
                    </div>
                    <div className="slot-name">Baccarat </div>
                  </div>
                  <div className="slotgbox " id="3 card brag">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/3 Card Brag.jpg"
                      />
                    </div>
                    <div className="slot-name">3 Card Brag </div>
                  </div>
                  <div className="slotgbox " id="caribbean stud poker">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Caribbean Stud Poker.jpg"
                      />
                    </div>
                    <div className="slot-name">Caribbean Stud Poker </div>
                  </div>
                  <div className="slotgbox " id="7 e mezzo">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/7 e mezzo.jpg"
                      />
                    </div>
                    <div className="slot-name">7 e mezzo </div>
                  </div>
                  <div className="slotgbox " id="hi-lo premium">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Hi-Lo Premium.jpg"
                      />
                    </div>
                    <div className="slot-name">Hi-Lo Premium </div>
                  </div>
                  <div className="slotgbox " id="premium blackjack single hand">
                    <div className="play-hover">
                      <button
                        className="playslot-btn cursor-pointer"
                        onClick={() => alert("Comming Soon")}
                      >
                        Play Now
                      </button>
                    </div>
                    <div className="slot-tumbnail">
                      <img
                        alt=""
                        src="https://gsmd.336699bet.com/lobby/gamelist/image/px/en-us/Premium Blackjack Single Hand.jpg"
                      />
                    </div>
                    <div className="slot-name">
                      Premium Blackjack Single Hand
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="footer-sec pm-detail-wrap">
        <div className="max-container">
          <div className="footer-seo">
            <h1 style={{color: '#787b8b'}}>
              Slot Game Online for Mobile Thailand | Slot Judi Thailand |
              Thailand Online Casino Slot | Online Slot Thailand | Online Slots
              Games Thailand
            </h1>

            <p>
              If you're a fan of online gambling, you'll be pleased to know that
              we offer a wide range of Mobile Slot Game Thailand games that can
              be played on your mobile device.Our Slot Game Online for Mobile
              Thailand offers a seamless and user-friendly experience, making it
              easy for you to access your favorite Slots Game Thailand wherever
              you are.
            </p>

            <p>
              Convenience: You can play Online Slot Thailand games on your
              mobile device from the comfort of your own home or on the go. All
              you need is an internet connection,and you're ready to start
              playing.
            </p>

            <p>
              Variety: We offer a wide range of Slots Game Thailand, from
              classic three-reel slots to modern video slots. With so many games
              to choose from,you're sure to find one that meets your
              preferences.
            </p>

            <p>
              Bonuses and Promotions: We offer a range of Thailand Slots Casino
              bonuses and promotions for new and existing players, including
              welcome bonuses, free spins, and cashback offers.These promotions
              can help boost your bankroll and increase your chances of winning
              big.
            </p>

            <h2 style={{color: '#787b8b'}}>Thailand Online Casino Slot with Top Games</h2>

            <p>
              Our online Casino offers a variety of Online Slots Games Thailand
              games that are tailored to meet the preferences of
              Thailandplayers. From traditionalThailand-themed slots to modern
              video slots, we have something for everyone. VG online slot games
              are designed to provide a fun and exciting experience,with
              high-quality graphics and immersive sound effects.
            </p>

            <h2 style={{color: '#787b8b'}}>Play VG online Mobile Slot Game Thailand</h2>

            <p>
              Our mobile slot games are designed to be played on any mobile
              device, including smartphones and tablets.We use the latest
              technology to ensure that our games run smoothly on all Slot Judi
              Thailand devices, so you can enjoy a seamless gaming experience no
              matter where you are.
            </p>

            <h2 style={{color: '#787b8b'}}>Online Slot Thailand at VG online</h2>

            <p>
              Our online slot games are easy to play and offer a range of
              betting options to suit all budgets. Whether you're a beginner or
              an experienced player,you're sure to find a slot game that meets
              your preferences. With a variety of themes and features, our
              Thailand Online Slot Casino slot games are sure to keep you
              entertained for hours.
            </p>

            <h3 style={{color: '#787b8b'}}>FAQs</h3>

            <h3 style={{color: '#787b8b'}}>Q: Are your slot games fair?</h3>

            <p>
              A: Yes, our Slot Game Online for Mobile Thailand games are
              regularly audited for fairness to ensure that all outcomes are
              completely random.We use a Random Number Generator (RNG) to
              determine the outcome of each spin, which ensures that the results
              are completely unbiased.
            </p>

            <h3 style={{color: '#787b8b'}}>Q: How do I deposit and withdraw funds?</h3>

            <p>
              A: We offer a variety of payment methods, including credit cards,
              debit cards, and e-wallets. To make a deposit, simply select your
              preferred payment methodand follow the on-screen instructions.
              Withdrawals are processed quickly and securely, and funds are
              usually credited to your account within a few working days
            </p>

            <p>
              Online Casino Thailand | Live Casino Thailand | Online Sportsbook
              Thailand | Live Casino Thailand Promotion | Sports Toto Thailand
            </p>
          </div>
        </div>
      </section>
    </>
    // <div style={{ display: 'flex', flexDirection: 'column', background: "#fff" }}>
    //   <Head image={imageslot} />
    //   <div className="pages">
    //     <button className="pages-btn-items">Slot Game</button>
    //     <SlotGameComp history={history} />
    //     <img
    //       style={{ width: '100%', margin: '30px 0' }}
    //       src="./images/home/bannerslots.png"
    //       alt="bannerslot"
    //     />
    //     <TotalPlay />
    //     <Testimonials />
    //   </div>
    // </div>
  );
}
export default withStyles(styles)(home);
