import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Banking from "../NewBankingMb/banking";
import formCreateInputs from "service/form/create";
import { useForm } from "react-final-form-hooks";
import http from "service/http";
import store from "store";
import { useTranslation } from "react-i18next";
import styles from "./style";
import useLoading from "../loading/hook";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./style.css";
import MenuItemBoxLeftMobile from "../../component/layout/BoxLeft/MenuItemBoxLeftMobile";
import MenuItemBoxLeft from "../../component/layout/BoxLeft/MenuItemBoxLeft";
import MainWallet from "../../component/layout/BoxLeft/MainWallet";
import config from "config";

function Promos({ classes, history }) {
  return (
    <>
      <section className="promo-sec">
        <div className="max-container">
          <div className="promo-container" id="filter-box">
            <div className="filter-menu">
              <div className="pmbox filter active" data-filter="all">
                <div className="pmbox-inner">All</div>
              </div>
              <div className="pmbox filter" data-filter=".new">
                <div className="pmbox-inner">NEW MEMBER</div>
              </div>
              <div className="pmbox filter" data-filter=".special">
                <div className="pmbox-inner">SPECIAL</div>
              </div>
              <div className="pmbox filter" data-filter=".slot">
                <div className="pmbox-inner">Slots</div>
              </div>
              <div className="pmbox filter" data-filter=".casino">
                <div className="pmbox-inner">CASINO</div>
              </div>
              <div className="pmbox filter" data-filter=".sport">
                <div className="pmbox-inner">SPORT</div>
              </div>
              <div className="pmbox filter" data-filter=".weekly">
                <div className="pmbox-inner">WEEKLY</div>
              </div>
            </div>

            <div id="promoContent" className="promo-content-area">
              <div
                className="promo-bx mix special"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000278"
                >
                  <div className="pm-img">
                    <img src="../images/promo/DAILY FORTUNE WHEEL.png" alt="" />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">DAILY FORTUNE WHEEL</div>
                      <div className="smpromo-caption">
                        Deposit of THB100 to get ONE spin chance token. Spin the
                        Fortune Wheel and claim your prize!.
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000278">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/DAILY FORTUNE WHEEL.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">DAILY FORTUNE WHEEL</div>
                        <div className="pmcaption">
                          Deposit of THB100 to get ONE spin chance token. Spin
                          the Fortune Wheel and claim your prize!.
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td>Min Deposit</td>
                                <td>Spin Token </td>
                              </tr>
                              <tr>
                                <td>THB100</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>Accumulated THB5,000</td>
                                <td>5</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="pmtnc-wrap">
                        <div className="rules-btn-wrap">
                          <button
                            className="pm-more-btn rules"
                            data-toggle="modal"
                            data-target="#general-rules"
                          >
                            General Rules
                          </button>
                        </div>

                        <strong>TERMS &amp; CONDITIONS</strong>
                        <ul className="terms">
                          <li>
                            A minimum deposit of THB100 is required to get 1
                            token to spin the Fortune Wheel minigame page.
                          </li>
                          <li>
                            Daily accumulated deposit of THB5,000 to get 5 extra
                            tokens.
                          </li>
                          <li>
                            Members are only allowed to claim a maximum of six
                            (6) minigame token daily.
                          </li>
                          <li>
                            Example: 1st token based on single Deposit THB100
                            &amp; Extra 5 token when accumulate total deposit
                            amount THB5,000 within a day.
                          </li>
                          <li style={{ color: "yellow;" }}>
                            Voucher claim: Member can claim back cash money in
                            the voucher amount in a single receipt via Livechat
                            within 7 days. Receipt must be write down with
                            member's username and KISS VIP as a proof of payment.
                            Receipt must be dated after the winning date.
                          </li>
                          <li>
                            All members must fulfil the required 1x turnover
                            requirement with the bonus claimed before any
                            withdrawal can be made.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix weekly"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000157"
                >
                  <div className="pm-img">
                    <img src="../images/promo/pb-legend.jpg?1" alt="" />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        LEGEND SPIN WEEKLY TOURNAMENT
                      </div>
                      <div className="smpromo-caption">
                        A Rewarding WEEKLY Slots Tournament with 30 New Winners
                        Announced Each Week!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000157">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img src="../images/promo/pb-legend.jpg?1" alt="" />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          LEGEND SPIN WEEKLY TOURNAMENT
                        </div>
                        <div className="pmcaption">
                          A Rewarding WEEKLY Slots Tournament with 30 New
                          Winners Announced Each Week!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Products</strong>
                                </td>
                                <td align="center">
                                  <strong>Game Suites</strong>
                                </td>
                                <td align="center">
                                  <strong>Min Withdraw</strong>
                                </td>
                                <td align="center">
                                  <strong>Max Withdraw</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">Slots</td>
                                <td align="center">
                                  All Slots (Except Pussy888)
                                </td>
                                <td align="center">Bonus Amount X3</td>
                                <td align="center">Bonus Amount X10</td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <div className="pmtnc-wrap">
                            <div className="rules-btn-wrap">
                              <button
                                className="pm-more-btn rules"
                                data-toggle="modal"
                                data-target="#general-rules"
                              >
                                General Rules
                              </button>
                            </div>
                            <strong>TERMS &amp; CONDITIONS</strong>
                            <ul className="terms">
                              <li>
                                This promotion is run on weekly basis (Mon -
                                Sun)
                              </li>
                              <li>
                                Participation in this weekly tournament is based
                                on the accumulated total bets turnover made by a
                                member in a particular week. Weekly winners will
                                be the top 30 members with the highest total
                                bets turnover for that particular week.
                              </li>
                              <li>
                                The prizes awarded to the top 30 members with
                                the highest weekly bets turnover will be derived
                                from an aggregated prize pool of THB 10,000. The
                                amount of the awarded prize will be based on the
                                winner’s ranking.
                              </li>
                              <li style={{ color: "#FFD700" }}>
                                <strong>
                                  Bonus Play Conditions: Members are required to
                                  fulfill winover of the [(Bonus Amount) x3]
                                  amount in order to withdraw. Maximum
                                  withdrawal limited to [(Bonus Amount) x10].
                                  Excess balance will be removed from Main
                                  Wallet/Game Wallet during withdrawal without
                                  prior notice.
                                </strong>
                              </li>
                              <li style={{ color: "#FFD700" }}>
                                <strong>
                                  Bonus is for slots game only. Applicable to
                                  all game suite. Except Pussy888.
                                </strong>
                              </li>
                              <li>
                                In the event where this bonus has been wagered
                                on products in other games (aside from SLOTS),
                                all bonuses and winnings will be forfeited.
                              </li>
                              <li>
                                Prize can be claimed upon the tournament week
                                ended on the following Monday after 1:00am.
                              </li>
                              <li>
                                To view tournament details and latest ranking,
                                kindly click{" "}
                                <a
                                  title="LEGEND SPIN WEEKLY TOURNAMENT"
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    alert("Comming Soon");
                                  }}
                                >
                                  <strong>LEADERBOARD</strong>
                                </a>
                                . Player’s position will be refreshed every 30
                                minutes.
                              </li>
                              <li>
                                If there are two or more players who complete
                                the mission with the same total bets turnover,
                                the player completing it first will have higher
                                position on the leaderboard.
                              </li>
                              <li>
                                Prize must be claimed within 1 week after the
                                current week tournament ended.
                              </li>
                              <li>
                              KISS VIP reserves the right to cancel/extend/amend
                                this promotion at any time, either for all
                                players or individual player.
                              </li>
                              <li style={{ color: "#FFD700" }}>
                                <strong>
                                  The General Rules Terms &amp; Conditions of
                                  KISS VIP apply to all promotions.
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix new slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000293"
                >
                  <div className="pm-img">
                    <img src="../images/promo/EUWMYR-slots-pb.jpg" alt="" />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        100% WELCOME BONUS FOR SLOTS
                      </div>
                      <div className="smpromo-caption">
                        Get 100% Bonus on Your First Deposit Up to THB300!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000293">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img src="../images/promo/EUWMYR-slots-pb.jpg" alt="" />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          100% WELCOME BONUS FOR SLOTS
                        </div>
                        <div className="pmcaption">
                          Get 100% Bonus on Your First Deposit Up to THB300!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">Product</td>
                                <td align="center">Min Deposit</td>
                                <td align="center">Bonus</td>
                                <td align="center">Max Bonus</td>
                                <td align="center">Min Withdraw</td>
                                <td align="center">Max Withdraw</td>
                              </tr>
                              <tr>
                                <td align="center">
                                  All Slots &amp; 711 Gaming Slot
                                </td>
                                <td align="center">THB 30</td>
                                <td align="center">100%</td>
                                <td align="center">THB 300</td>
                                <td align="center">(Depo+Bonus) X3</td>
                                <td align="center">(Depo+Bonus) X8</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          <strong>Withdrawal Requirement Example:-</strong>
                          <br />
                          Deposit = THB 30
                          <br />
                          100% Bonus = THB 30
                          <br />
                          Min Withdraw = (THB 30+ THB 30) x 3 = THB 180
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          This promotion is applicable to all new registered
                          members on first deposit.
                        </li>
                        <li>
                          This promotion is applicable to all slots games &amp;
                          711 Gaming Slot.{" "}
                        </li>
                        <li>
                          This promotion is subjected to minimum withdraw
                          (Depo+Bonus) X3 ; maximum withdraw (Depo+Bonus) X8 and
                          only one (1&#41; time withdraw. Excess balance will be
                          removed during withdrawal progress without prior
                          notice.
                        </li>
                        <li>
                          This promotion cannot be claimed in conjunction with
                          other KISS VIP promotions.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix new slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000300"
                >
                  <div className="pm-img">
                    <img src="../images/promo/ENJOY WEEKEND 30pct SLOTS BONUS_.png" alt="" />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        100% + 200 FREE SPINS WELCOME BONUS FOR SLOTS
                      </div>
                      <div className="smpromo-caption">
                        Get 100% Bonus Up to THB1,000 and 200 Free Spins!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000300">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img src="../images/promo/EU-pb-pragmatic.jpg" alt="" />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          100% + 200 FREE SPINS WELCOME BONUS FOR SLOTS
                        </div>
                        <div className="pmcaption">
                          Get 100% Bonus Up to THB1,000 and 200 Free Spins!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Product</strong>
                                </td>
                                <td align="center">
                                  <strong>Deposit</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Min Withdraw</strong>
                                </td>
                                <td align="center">
                                  <strong>Max Withdraw</strong>
                                </td>
                                <td align="center">
                                  <strong>Free Spin</strong>
                                </td>
                                <td align="center">
                                  <strong>Free Spin Bet Amount</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center" rowspan="3">
                                  All Slots
                                </td>
                                <td align="center">THB 100</td>
                                <td align="center">THB 100</td>
                                <td align="center">THB 600</td>
                                <td align="center">THB 1,600</td>
                                <td align="center">+ 20 Spins</td>
                                <td align="center">THB1 per spin</td>
                              </tr>
                              <tr>
                                <td align="center">THB 500</td>
                                <td align="center">THB 500</td>
                                <td align="center">THB 3,000</td>
                                <td align="center">THB 8,000</td>
                                <td align="center">+ 100 Spins</td>
                                <td align="center">THB1 per spin</td>
                              </tr>
                              <tr>
                                <td align="center">THB 1,000</td>
                                <td align="center">THB 1,000</td>
                                <td align="center">THB 6,000</td>
                                <td align="center">THB 16,000</td>
                                <td align="center">+ 200 Spins</td>
                                <td align="center">THB1 per spin</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>FS Effective Time</strong>
                                </td>
                                <td align="center">
                                  <strong>Provider</strong>
                                </td>
                                <td align="center">
                                  <strong>Free Spin Game</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">Next day 10:00 (GMT+8)</td>
                                <td align="center">Pragmatic Play</td>
                                <td align="center">
                                  Gates of Olympus , Sweet Bonanza , Sweet
                                  Bonanza Xmas , Starlight Princess , Sugar Rush
                                  , 5 Lions Megaways
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          This promotion is applicable to all new registered
                          members on first deposit.
                        </li>
                        <li>
                          This promotion is applicable to all slots games &amp;
                          711 Gaming Slot.{" "}
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Free Spin will be awarded to member's REWARD CENTER.
                          </strong>
                        </li>
                        <li>
                          This promotion is subjected to minimum withdraw
                          (Depo+Bonus) X3 ; maximum withdraw (Depo+Bonus) X8 and
                          only one (1&#41; time withdraw. Excess balance will be
                          removed during withdrawal progress without prior
                          notice.
                        </li>
                        <li>
                          Free Spins only applies to games stated above and only
                          valid for 24 hours.
                        </li>
                        <li>
                          Free spins rewarded only required 1x turnover before
                          withdrawal can be made.
                        </li>
                        <li>
                          This promotion cannot be claimed in conjunction with
                          other KISS VIP promotions.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix new slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000301"
                >
                  <div className="pm-img">
                    <img src="../images/promo/EUWMYR-slots-pb.jpg" alt="" />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        JILI 50% WELCOME SLOT BONUS
                      </div>
                      <div className="smpromo-caption">
                        Deposit &amp; Claim 50% Welcome Bonus For Jili Slot
                        Games!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000301">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img src="../images/promo/EU-pb-jili.jpg" alt="" />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          JILI 50% WELCOME SLOT BONUS
                        </div>
                        <div className="pmcaption">
                          Deposit &amp; Claim 50% Welcome Bonus For Jili Slot
                          Games!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">Game Provider</td>
                                <td align="center">Minimum Deposit</td>
                                <td align="center">Bonus</td>
                                <td align="center">Winover</td>
                                <td align="center">Maximum Bonus</td>
                              </tr>
                              <tr>
                                <td align="center">Jili Slots</td>
                                <td align="center">THB 30</td>
                                <td align="center">50%</td>
                                <td align="center">X2</td>
                                <td align="center">THB 250</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          Example :-
                          <br />
                          Deposit = THB 30
                          <br />
                          50% Bonus = THB 15
                          <br />
                          Winover Requirement = (THB 30+ THB 15) x 2 = THB 90
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          This promotion is applicable to all new registered
                          members on first deposit.
                        </li>
                        <li>
                          This promotion is applicable to Jili Slot only.{" "}
                        </li>
                        <li>
                          This promotion is subjected to winover (Depo+Bonus)
                          X2.
                        </li>
                        <li>
                          This promotion cannot be claimed in conjunction with
                          other KISS VIP promotions.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix new casino"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000292"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/100pct FIRST DEPOSIT STARTER PACK.png"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        60% LIVE CASINO SPECIAL WELCOME BONUS
                      </div>
                      <div className="smpromo-caption">
                        Lucky Starter Pack for New Members
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000292">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/100pct FIRST DEPOSIT STARTER PACK.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          60% LIVE CASINO SPECIAL WELCOME BONUS
                        </div>
                        <div className="pmcaption">
                          Lucky Starter Pack for New Members
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive">
                          <br />
                          <table
                            width="80%"
                            border="1"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                            className="forTable"
                          >
                            <tbody>
                              <tr className="trTitle">
                                <td width="10%" align="center">
                                  <b>PRODUCT</b>
                                </td>
                                <td width="50%" align="center">
                                  <b>BONUS</b>
                                </td>
                                <td width="10%" align="center">
                                  <b>MIN. DEPOSIT</b>
                                </td>
                                <td width="10%" align="center">
                                  <b>MAX. BONUS</b>
                                </td>
                                <td width="10%" align="center">
                                  <b>REQUIREMENT</b>
                                </td>
                              </tr>
                              <tr className="tr1">
                                <td align="center">Live Casino</td>
                                <td align="center">
                                  60% Live Casino Special Welcome Bonus
                                </td>
                                <td align="center">THB 30</td>
                                <td align="center">THB 300</td>
                                <td align="center">30X Rollover</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p>
                          <strong>Rollover Calculation Example:</strong>
                        </p>
                        <div className="table-responsive">
                          <br />
                          <table
                            width="50%"
                            border="1"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                            className="forTable"
                          >
                            <tbody>
                              <tr className="trTitle">
                                <td width="10%" align="center">
                                  <b>DEPOSIT</b>
                                </td>
                                <td align="center" colspan="2">
                                  <b>BONUS (60%)</b>
                                </td>
                                <td align="center" colspan="2">
                                  <b>ROLLOVER</b>
                                </td>
                              </tr>
                              <tr className="tr1">
                                <td align="center">THB 30</td>
                                <td align="center">+</td>
                                <td align="center">THB 18</td>
                                <td align="center">X30</td>
                                <td align="center">THB 1440</td>
                              </tr>
                              <tr className="tr1">
                                <td align="center">THB 500</td>
                                <td align="center">+</td>
                                <td align="center">THB 300</td>
                                <td align="center">X30</td>
                                <td align="center">THB 24000</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <br />
                        <div className="pmtnc-wrap">
                          <div className="rules-btn-wrap">
                            <button
                              className="pm-more-btn rules"
                              data-toggle="modal"
                              data-target="#general-rules"
                            >
                              General Rules
                            </button>
                          </div>

                          <strong>TERMS &amp; CONDITIONS</strong>
                          <ul className="terms">
                            <li>This promotion is for live casino only.</li>
                            <li>
                              This promotion is for new registered members on
                              first deposit.
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                This promotion applicable for All Live Casino
                                Providers (Except Allbet)
                              </strong>
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                Live Casino Blackjack and Texas Hold'em are not
                                applicable to this promotion. (All winnings may
                                be forfeited)
                              </strong>
                            </li>
                            <li>
                              All drawn or tied wagers, cancelled or void
                              bets/wagers or bets placed on opposite sides in a
                              single game will not count towards the rollover
                              accumulation.
                            </li>
                            <li>
                              Only 25% of total wagers placed on Live Casino
                              games under Game Show category and Roulette (all
                              variations) will count towards the wagering
                              requirement for any bonus.
                            </li>
                            <li>
                              {" "}
                              This promotion cannot be claimed in conjunction
                              with other KISS VIP promotions.
                            </li>
                            <li>
                              The General Rules Terms &amp; Conditions of KISS VIP
                              apply to all promotions.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix new slot sport casino"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000108"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/TRIAL PACK PROMO – DEPOSIT MYR 50 GET MYR 88.png"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        TRIAL PACK PROMO – DEPOSIT THB 50 GET THB 88
                      </div>
                      <div className="smpromo-caption">
                        A Trial Pack Bonus for NEW MEMBERS. Grab now while
                        promotion still ongoing!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000108">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/TRIAL PACK PROMO – DEPOSIT THB 50 GET THB 88.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          TRIAL PACK PROMO – DEPOSIT THB 50 GET THB 88
                        </div>
                        <div className="pmcaption">
                          A Trial Pack Bonus for NEW MEMBERS. Grab now while
                          promotion still ongoing!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">Minimum Deposit</td>
                                <td align="center">Rollover</td>
                                <td align="center">Maximum Bonus</td>
                              </tr>
                              <tr>
                                <td align="center">THB 50</td>
                                <td align="center">X10</td>
                                <td align="center">THB 38</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          Example :-
                          <br />
                          Deposit = THB 50
                          <br />
                          Bonus = THB 38
                          <br />
                          Rollover = (THB 50+ THB 38) x 10 rollover = THB 880
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          This promotion is open to new register members on
                          First Deposit. Members are allowed to claim only one
                          type of Welcome Bonus.{" "}
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Promotion does not applicable to WM Casino, Big
                            Gaming, Dream Gaming, Allbet, Pussy888 dan 4D
                            Lottery.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Live Casino Blackjack, Texas Hold'em, 4D lottery,
                            Fish Hunting, RNG Table games &amp;
                            Multi-player/Arcade games are not applicable to this
                            promotion. (All winnings may be forfeited).{" "}
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Virtual Game, Virtual Sports, Number Game, RNG Keno
                            and any other Games other than sports in the
                            sportsbook do not apply to this promotion. All
                            winnings will be forfeited.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Only all confirmed bets/wagers are counted towards
                            the rollover requirement. All drawn or tied wagers,
                            cancelled or void bets/wagers, Early cash out or
                            bets placed on opposite sides in a single game will
                            not count towards the rollover accumulation. Any
                            bets placed containing selections of odds that are
                            less than 0.5 or 1.50 (Decimal odds) will not count
                            towards any rollover.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Only 25% of total wagers placed on Live Casino games
                            under Game Show category, Roulette (all variations)
                            and 50% of total wagers placed on any slots and RNG
                            games will count towards the wagering requirement
                            for any bonus.
                          </strong>
                        </li>
                        <li>
                          Members are require to meet the rollover [deposit +
                          bonus] above before withdrawal.
                        </li>
                        <li>
                          Members found to be abusing or defrauding promotional
                          T&amp;Cs will face forfeiture of bonuses &amp;
                          winnings.
                        </li>
                        <li>
                          Total bets from claiming this promotion are not
                          eligible for the Daily Rebate bonus and Slot Rescue
                          Bonus.
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            The General Rules Terms &amp; Conditions of KISS VIP
                            apply to all promotions.
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix special"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000245"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/Welcome To EUWIN. Your Wonderful World of Entertainment!_.png"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        COMPLETE YOUR PROFILE &amp; GET FREE REWARD!
                      </div>
                      <div className="smpromo-caption">
                        Complete the profile and make deposit requirement to
                        receive bonus prize up to THB 1,000!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000245">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/Welcome To EUWIN. Your Wonderful World of Entertainment!_.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          COMPLETE YOUR PROFILE &amp; GET FREE REWARD!
                        </div>
                        <div className="pmcaption">
                          Complete the profile and make deposit requirement to
                          receive bonus prize up to THB 1,000!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>

                      <div className="pmwrap" style={{ textAlign: "left" }}>
                        <strong>Who To Qualify:</strong>
                        <br />
                        <span>Open to all registered active members.</span>
                        <br />
                        <br />
                        <strong>How To Apply:</strong>
                        <br />
                        <span>1. Complete the details in your profile.</span>
                        <br />
                        <span>2. Fill up withdrawal bank details.</span>
                        <br />
                        <span>
                          3. Contact LiveChat after fulfilling the deposit
                          requirement of THB300.
                        </span>
                        <br />
                      </div>

                      <div className="pmtnc-wrap">
                        <div className="rules-btn-wrap">
                          <button
                            className="pm-more-btn rules"
                            data-toggle="modal"
                            data-target="#general-rules"
                          >
                            General Rules
                          </button>
                        </div>

                        <strong>TERMS &amp; CONDITIONS</strong>
                        <ul className="terms">
                          <li>
                            Members are required to fulfill THB 300 deposit.
                          </li>
                          <li>
                            Qualified members are eligible to get 1 Fortune
                            Wheel Token as Profile Reward.
                          </li>
                          <li style={{ color: "yellow" }}>
                            Profile Reward can only be claimed ONCE (1&#41;.
                          </li>
                          <li style={{ color: "yellow" }}>
                            Use the token to spin in the Fortune Wheel. You
                            might get free gift voucher or credit bonus up to
                            THB 1,000!
                          </li>
                          <li>
                            This promotion can be claimed in conjunction with
                            other KISS VIP promotions unless otherwise stipulated.
                          </li>
                          <li>
                            KISS VIP reserves the right to suspend or terminate any
                            duplicate accounts or any account that does not
                            adhere to the stipulated terms and conditions.
                          </li>
                          <li>
                            KISS VIP reserves the right to modify or cancel this
                            promotion at any time without prior notice.
                          </li>
                          <li>
                            The General Rules Terms &amp; Conditions of KISS VIP
                            apply to all promotions.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix new slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000163"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/ENJOY WEEKEND 30pct SLOTS BONUS_.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        CRAZY PUSSY BOOSTER BONUS
                      </div>
                      <div className="smpromo-caption">
                        Exclusive Crazy Booster Bonus (for Pussy888 Slots Game)
                        Deposit THB 300 Play with THB 1000!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000163">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/CRAZY 918MEGAPUSSY BOOSTER BONUS.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">CRAZY PUSSY BOOSTER BONUS</div>
                        <div className="pmcaption">
                          Exclusive Crazy Booster Bonus (for Pussy888 Slots
                          Game) Deposit THB 300 Play with THB 1000!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap"></div>
                      <div className="table-responsive promo-table">
                        <table width="100%" className="table-promo">
                          <tbody>
                            <tr>
                              <td align="center">Game Provider</td>
                              <td align="center">Deposit</td>
                              <td align="center">Crazy Bonus</td>
                              <td align="center">Requirement</td>
                              <td align="center">Max Payout</td>
                            </tr>
                            <tr>
                              <td align="center">Pussy888</td>
                              <td align="center">THB 300</td>
                              <td align="center">THB 700</td>
                              <td align="center">THB 3,000 or above</td>
                              <td align="center">THB 2,300</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="pmtnc-wrap">
                        <div className="rules-btn-wrap">
                          <button
                            className="pm-more-btn rules"
                            data-toggle="modal"
                            data-target="#general-rules"
                          >
                            General Rules
                          </button>
                        </div>

                        <strong>TERMS &amp; CONDITIONS</strong>
                        <ul className="terms">
                          <li>
                            Promotion are open to new register KISS VIP Members.
                            Valid for Pussy888. (SLOT only)
                          </li>
                          <li style={{ color: "#FFD700" }}>
                            <strong>
                              Members are required to achieve THB 3,000 credit
                              before withdrawal could be made. Maximum
                              withdrawal is THB 2,300.
                            </strong>
                          </li>
                          <li style={{ color: "#FFD700" }}>
                            <strong>
                              Excess balance and Crazy bonus credit will be
                              removed during withdrawal progress.
                            </strong>
                          </li>
                          <li>
                            Booster bonus amount THB 700 is purely act as a
                            lending credit and is not able to withdraw.
                          </li>
                          <li>
                            Offender found abuse or fraud promotion T&amp;C will
                            face bonuses &amp; winning forfeited.
                          </li>
                          <li>
                            Members are not entitled for the Daily Rebate and
                            Slot Rescue Bonus with this promotion.
                          </li>
                          <li>
                            The General Rules Terms &amp; Conditions of KISS VIP
                            apply to all promotions.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix new sport"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000255"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/100 SPORTSBOOK WELCOME BONUS.png"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        100% SPORTSBOOK WELCOME BONUS
                      </div>
                      <div className="smpromo-caption">
                        Make your first deposit &amp; get up to THB 300 Bonus
                        Now!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000255">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/100 SPORTSBOOK WELCOME BONUS.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          100% SPORTSBOOK WELCOME BONUS
                        </div>
                        <div className="pmcaption">
                          Make your first deposit &amp; get up to THB 300 Bonus
                          Now!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">Minimum Deposit</td>
                                <td align="center">Bonus</td>
                                <td align="center">Rollover</td>
                                <td align="center">Maximum Bonus</td>
                              </tr>
                              <tr>
                                <td align="center">THB 30</td>
                                <td align="center">100%</td>
                                <td align="center">X25</td>
                                <td align="center">THB 300</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          Example :-
                          <br />
                          First Deposit = THB 100
                          <br />
                          100% Bonus = THB 100
                          <br />
                          Rollover Requirement = (THB 100+ THB 100) x 25
                          rollover = THB 5,000
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          Promotion are open to new register KISS VIP Members.
                        </li>
                        <li>
                          Each member are only allow to claim ONE(1&#41; welcome
                          bonus. Members must select this promo in the Bonus Box
                          when fill up deposit form to entitle in this
                          promotion.
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Members are required to fulfill Turnover of the [
                            (deposit + bonus) x turnover ] amount in order to
                            withdraw.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Virtual Game, Virtual Sports, Number Game, RNG Keno
                            and any other Games aside from sports are not
                            applicable to this promotion. Doing so will risk
                            bonus and winning being forfeited.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Only bets that generate win/loss outcome will be
                            counted as valid bet while draw result, both sides
                            bet, voided, cancelled game Early cash out or bets
                            placed containing selections of odds less than 0.5
                            or 1.50 (Decimal odds) will not be counted as valid
                            bet, hence will not count as valid turnover.
                          </strong>
                        </li>
                        <li>
                          Offender found abuse or fraud promotion T&amp;C will
                          face bonuses &amp; winning forfeited.
                        </li>
                        <li>
                          Members are not entitled for the Daily Rebate and
                          Daily Slot Rescue Bonus with this promotion.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000348"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/EU Big Win Challenge.png"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        DAILY SLOTS BIG WIN CHALLENGES
                      </div>
                      <div className="smpromo-caption">
                        Make it happen, challenge the BIG WIN and get extra
                        credit!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000348">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/EU Big Win Challenge.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          DAILY SLOTS BIG WIN CHALLENGES
                        </div>
                        <div className="pmcaption">
                          Make it happen, challenge the BIG WIN and get extra
                          credit!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <br />
                        <p style={{ color: "#FFD700" }}>
                          <strong>
                            Qualification :- Withdrawal from your (Deposit /
                            Deposit + Bonus) X10 or more.
                            <br />
                            Minimum deposit :- THB 200 or above fund deposit in
                            single transaction and fully wager in SLOTS game
                            only.
                          </strong>
                        </p>

                        <div className="table-responsive mt-3">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">Challenge</td>
                                <td align="center">Requirement</td>
                                <td align="center">Bonus</td>
                                <td align="center">Min Withdraw</td>
                                <td align="center">Max Withdraw</td>
                              </tr>
                              <tr>
                                <td align="center">Big Win</td>
                                <td align="center">Deposit Winover X10</td>
                                <td align="center">THB 188</td>
                                <td align="center">Bonus Amount X3</td>
                                <td align="center">Bonus Amount X10</td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <div className="pmwrap" style={{ textAlign: "left" }}>
                            <strong>
                              Big Win challenge: Example 1 (deposit without
                              bonus)
                            </strong>
                            <br />
                            <span>
                              Deposit THB 200, win and withdraw THB 2000
                              successfully to get extra bonus THB 188.
                            </span>
                            <br />
                            <br />

                            <strong>
                              Big Win challenge: Example 2 (deposit with bonus)
                            </strong>
                            <br />
                            <span>
                              Deposit THB 200 with 15% deposit bonus THB 30, win
                              and withdraw THB 2300 successfully to get extra
                              bonus THB 188.
                            </span>
                          </div>

                          <div className="pmtnc-wrap">
                            <div className="rules-btn-wrap">
                              <button
                                className="pm-more-btn rules"
                                data-toggle="modal"
                                data-target="#general-rules"
                              >
                                General Rules
                              </button>
                            </div>
                            <strong>TERMS &amp; CONDITIONS</strong>
                            <ul className="terms">
                              <li>
                                First Deposit Welcome Bonus promotions are not
                                applicable to this promotion.
                              </li>
                              <li>
                                Deposit wagered in Pussy888 or other games
                                (aside from SLOT) are not eligible to claim
                                rewards.
                              </li>
                              <li>
                                Daily Big Win Challenge only could be claimed
                                ONE (1) time per day. Cut-off time is 00:00:00
                                to 23:59:59 (GMT +8).
                              </li>
                              <li style={{ color: "#FFD700" }}>
                                <strong>
                                  Received bonus are valid to place bet on SLOTS
                                  games only.
                                </strong>
                              </li>
                              <li style={{ color: "#FFD700" }}>
                                <strong>
                                  Bonus is subject to a 3x minimum requirement
                                  &amp; maximum 10x of bonus reward prior to
                                  withdrawal. Exceed balance will be removed
                                  from account during withdrawal without prior
                                  notice.
                                </strong>
                              </li>
                              <li>
                                Bet generated by any rebates or bonus/prize
                                payout will not count towards for this
                                promotion.
                              </li>
                              <li>
                                Members are not entitled for the Daily Rebate
                                and Daily Slot Rescue Bonus with this promotion.
                              </li>
                              <li>
                                Offender found abuse or fraud promotion T&amp;C
                                will face bonuses &amp; winning forfeited.
                              </li>
                              <li style={{ color: "#FFD700" }}>
                                <strong>
                                  The General Rules Terms &amp; Conditions of
                                  KISS VIP apply to all promotions.
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000349"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/EU Turnover Challenge.png"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        DAILY SLOTS TURNOVER CHALLENGES
                      </div>
                      <div className="smpromo-caption">
                        Complete daily turnover challenge and get your reward
                        everyday!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000349">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/EU Turnover Challenge.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          DAILY SLOTS TURNOVER CHALLENGES
                        </div>
                        <div className="pmcaption">
                          Complete daily turnover challenge and get your reward
                          everyday!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <br />
                        <p style={{ color: "#FFD700" }}>
                          <strong>
                            Turnover Challenge: All SLOTS game suite
                          </strong>
                        </p>

                        <table width="100%" className="table-promo">
                          <tbody>
                            <tr>
                              <td align="center">Challenge</td>
                              <td align="center">Requirement</td>
                              <td align="center">Bonus</td>
                              <td align="center">Min Withdraw</td>
                              <td align="center">Max Withdraw</td>
                            </tr>
                            <tr>
                              <td align="center">Turnover</td>
                              <td align="center">5,000</td>
                              <td align="center">THB 30</td>
                              <td align="center">Bonus Amount X3</td>
                              <td align="center">Bonus Amount X10</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="pmtnc-wrap">
                          <div className="rules-btn-wrap">
                            <button
                              className="pm-more-btn rules"
                              data-toggle="modal"
                              data-target="#general-rules"
                            >
                              General Rules
                            </button>
                          </div>

                          <strong>TERMS &amp; CONDITIONS</strong>
                          <ul className="terms">
                            <li>
                              First Deposit Welcome Bonus promotions are not
                              applicable to this promotion.
                            </li>
                            <li>
                              Deposit wagered in Pussy888 or other games (aside
                              from SLOTS) are not eligible to claim rewards.
                            </li>
                            <li>
                              Daily Turnover Challenge only could be claimed ONE
                              (1&#41; time per day. Cut-off time is 00:00:00 to
                              23:59:59 (GMT +8).
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                Received bonus are valid to place bet on SLOTS
                                games ONLY.
                              </strong>
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                Bonus is subject to a 3x minimum requirement
                                &amp; maximum 10x of bonus reward prior to
                                withdrawal. Exceed balance will be removed from
                                account during withdrawal without prior notice.
                              </strong>
                            </li>
                            <li>
                              Bet generated by any rebates or bonus/prize payout
                              will not count towards for this promotion.
                            </li>
                            <li>
                              Offender found abuse or fraud promotion T&amp;C
                              will face bonuses &amp; winning forfeited.
                            </li>
                            <li>
                              Members are not entitled for the Daily Rebate and
                              Daily Slot Rescue Bonus with this promotion.
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                The General Rules Terms &amp; Conditions of
                                KISS VIP apply to all promotions.
                              </strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix slot "
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000237"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/ENJOY WEEKEND 30pct SLOTS BONUS_.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        ENJOY WEEKEND 30% SLOTS BONUS
                      </div>
                      <div className="smpromo-caption">
                        Kick start with 30% Bonus for slots during Weekend!
                        LIMITED TIME ONLY!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000237">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/ENJOY WEEKEND 30pct SLOTS BONUS_.png?1"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          ENJOY WEEKEND 30% SLOTS BONUS
                        </div>
                        <div className="pmcaption">
                          Kick start with 30% Bonus for slots during Weekend!
                          LIMITED TIME ONLY!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Minimum Deposit</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Requirement</strong>
                                </td>
                                <td align="center">
                                  <strong>Maximum Bonus</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">THB 100</td>
                                <td align="center">30%</td>
                                <td align="center">Winover X5</td>
                                <td align="center">THB 90</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          Example Withdrawal:-
                          <br />
                          Deposit = THB 100
                          <br />
                          30% Bonus = THB 30
                          <br />
                          Min. Withdrawal = (THB 100+ THB 30) x 5 Winover = THB
                          650
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          Promotion is for slots game only. Applicable to all
                          game suite. Except Pussy888.
                        </li>
                        <li>
                          Members are able to claim this bonus once on Saturday
                          and once on Sunday.
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Penalty 30x rollover requirement if founded place
                            bet on live casino, sportsbook &amp; products other
                            than SLOTS games.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            The first offender will get a warning penalty, and
                            if the offender is committed again, all bonuses and
                            winning points will be forfeited.
                          </strong>
                        </li>
                        <li>
                          Members are not entitled for the Daily Rebate and
                          Daily Slot Rescue Bonus with this promotion.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix new slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000162"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/NEWBIE 30pct BONUS FOR SLOTS.png"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        NEWBIE 30% BONUS FOR SLOTS
                      </div>
                      <div className="smpromo-caption">
                        Deposit &amp; Claim Newbie 30% Welcome Bonus With Only
                        1x Rollover!.
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000162">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/NEWBIE 30pct BONUS FOR SLOTS.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          NEWBIE 30% BONUS FOR SLOTS
                        </div>
                        <div className="pmcaption">
                          Deposit &amp; Claim Newbie 30% Welcome Bonus With Only
                          1x Rollover!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">Game Provider</td>
                                <td align="center">Minimum Deposit</td>
                                <td align="center">Bonus</td>
                                <td align="center">Rollover</td>
                                <td align="center">Maximum Bonus</td>
                              </tr>
                              <tr>
                                <td align="center">All Slots</td>
                                <td align="center">THB 50</td>
                                <td align="center">30%</td>
                                <td align="center">X1</td>
                                <td align="center">THB 90</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          Example :-
                          <br />
                          Deposit = THB 100
                          <br />
                          30% Bonus = THB 30
                          <br />
                          Rollover Requirement = (THB 100+ THB 30) x 1 rollover
                          = THB 130
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          Promotion are open to new register KISS VIP Members.
                        </li>
                        <li>
                          This promotion is applicable to all aforementioned
                          providers only. Minimum deposit is THB 50 to be
                          entitled for this promotion.{" "}
                        </li>
                        <li>
                          Promotion only valid for SLOTS games. In the event of
                          bonus wagered in other products than slot games, all
                          winnings may be forfeited.
                        </li>
                        <li>
                          Members are not entitled for the Daily Rebate and
                          Daily Slot Rescue Bonus with this promotion.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000238"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/918_MEGA_PUSSY 10pct DAILY 1ST BONUS.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        PUSSY 10% DAILY 1ST BONUS
                      </div>
                      <div className="smpromo-caption">
                        An 10% Daily Deposit Slot Bonus exclusively for
                        Pussy888; up to THB300!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000238">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/918_MEGA_PUSSY 10pct DAILY 1ST BONUS.png?1"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">PUSSY 10% DAILY 1ST BONUS</div>
                        <div className="pmcaption">
                          An 10% Daily Deposit Slot Bonus exclusively for
                          Pussy888; up to THB300!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Product</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Maximum Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Requirement</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">711 Gaming Slot</td>
                                <td align="center">10%</td>
                                <td align="center">THB 300</td>
                                <td align="center">
                                  Min. Withdraw = (Deposit + Bonus) x3
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          <strong>Min. Withdraw Example :-</strong>
                          <br />
                          Deposit = THB 100
                          <br />
                          Bonus 10% = THB 10
                          <br />
                          Min. Withdraw = (THB 100 + THB 10) x 3 = THB 330
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>This promo is for Pussy888 SLOTS GAMES only.</li>
                        <li>
                          Players are required to deposit a minimum amount of
                          THB 30 to be entitled for this promotion.
                        </li>
                        <li>
                          Players may claim the promotion once (1) per day. To
                          be eligible for this promotion, kindly select this
                          promo under Bonus Box during submission of deposit.{" "}
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Live Casino, Sportsbook, 4D Lottery, RNG Tables
                            Games, Hunting Fish, Arcade &amp; Multi-player Games
                            are not applicable to this promotion.
                          </strong>
                        </li>
                        <li>
                          Offender found abuse or fraud promotion T&amp;C will
                          face bonuses &amp; winning forfeited.
                        </li>
                        <li>
                          Turnover generated from this promotion is not entitled
                          for the Daily Rebate and Daily Slot Rescue Bonus.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000116"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/25pct ANGEL RESCUE BONUS.png"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        25% ANGEL RESCUE BONUS
                      </div>
                      <div className="smpromo-caption">
                        KISS VIP is proud to present its 25% Angel Rescue Bonus for
                        slots! Valid for plays on any Tuesday, Thursday, or
                        Saturday.
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000116">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/25pct ANGEL RESCUE BONUS.png"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">25% ANGEL RESCUE BONUS</div>
                        <div className="pmcaption">
                          KISS VIP is proud to present its 25% Angel Rescue Bonus
                          for slots! Valid for plays on any Tuesday, Thursday,
                          or Saturday.
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">Net Loss Amount</td>
                                <td align="center">Rescue Bonus</td>
                                <td align="center">Max Bonus</td>
                                <td align="center">Min Withdraw</td>
                                <td align="center">Max Withdraw</td>
                              </tr>
                              <tr>
                                <td align="center">THB100 and above</td>
                                <td align="center">25%</td>
                                <td align="center">THB 100</td>
                                <td align="center">Bonus X3</td>
                                <td align="center">Bonus X10</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left", color: "#FFD700" }}>
                          <strong>
                            Exceed balance will be removed from account during
                            withdrawal without prior notice.
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Who To Qualify: Member who deposit on any Tuesday,
                            Thursday, or Saturday during promotion period and
                            was loss on slots game on the same day.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Application Scenario: To claim the rescue bonus for
                            slot plays on a Tuesday, member must contact Live
                            Chat after 12:00PM on Wednesday and expired on
                            Wednesday 11:59PM.
                          </strong>
                        </li>
                        <li>
                          Only valid for deposits and loss THB 100 and above in
                          slot games excluding Pussy888
                        </li>
                        <li>
                          Any deposit with Welcome Bonuses and 30% Weekend Slot
                          Bonus are not entitled for this promotion.
                        </li>
                        <li>
                          Member may contact Live Chat and claim 25% of the
                          deposit amount as Angel Rescue Bonus on the next
                          calendar day.
                        </li>
                        <li>
                          This promotion is only claimable if the member's
                          account credit balance is less than THB 5.
                        </li>
                        <li>
                          Offender found abuse or fraud promotion T&amp;C will
                          face bonuses &amp; winning forfeited.
                        </li>
                        <li>
                          Members are not entitled for the Daily Rebate with
                          this promotion.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix slot"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000236"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/UNLIMITED 15pct SLOTS BONUS_.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        UNLIMITED 15% SLOTS BONUS
                      </div>
                      <div className="smpromo-caption">
                        Spin it now with Unlimited 15% Slots Bonus, up to THB
                        150!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000236">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/UNLIMITED 15pct SLOTS BONUS_.png?1"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">UNLIMITED 15% SLOTS BONUS</div>
                        <div className="pmcaption">
                          Spin it now with Unlimited 15% Slots Bonus, up to THB
                          150!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Product</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Maximum Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Requirement</strong>
                                </td>
                              </tr>

                              <tr>
                                <td align="center">
                                  Slot Games (Except Pussy888)
                                </td>
                                <td align="center">15%</td>
                                <td align="center">THB 150</td>
                                <td align="center">
                                  Min. Withdraw = (Deposit + Bonus) x5
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}></p>
                        <p style={{ textAlign: "left" }}>
                          <strong>Min. Withdraw Example (SLOT ONLY) :-</strong>
                          <br />
                          Deposit = THB 100
                          <br />
                          Bonus 15% = THB 15
                          <br />
                          Min. Withdraw = (THB 100 + THB 15) x 5 = THB 575
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          Promotion is for slots game only. Applicable to all
                          game suite. Except Pussy888.
                        </li>
                        <li>
                          Players are required to deposit a minimum amount of
                          THB 30 to be entitled for this promotion.
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            In the event where this bonus has been wagered on
                            products in other games (aside from SLOTS), the
                            rollover requirement will be 20x before withdrawal
                            can be made. In case violation of this bonus recurs,
                            all bonuses and winnings will be forfeited and the
                            rollover calculation will be disregarded.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Bets turnover generated from other games (aside from
                            SLOTS) with Unlimited 15% Slots Bonus will not count
                            towards rebate calculation.
                          </strong>
                        </li>
                        <li>
                          Bonus only valid for 30 days upon issuance. Bonuses
                          and winnings will be removed from the member’s account
                          if prerequisites are not fulfilled within the given
                          time frame.
                        </li>
                        <li>
                          KISS VIP reserves the right to suspend or terminate any
                          duplicate accounts or any account that does not adhere
                          to the stipulated terms and conditions.
                        </li>
                        <li>
                          KISS VIP reserves the right to modify or cancel this
                          promotion at any time without prior notice.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix slot sport casino"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000022"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/10pct DAILY FIRST DEPOSIT BONUS.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        10% DAILY FIRST DEPOSIT BONUS
                      </div>
                      <div className="smpromo-caption">
                        10% Daily First Deposit Bonus up to THB 300. Grab it
                        now!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000022">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/10pct DAILY FIRST DEPOSIT BONUS.png?1"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          10% DAILY FIRST DEPOSIT BONUS
                        </div>
                        <div className="pmcaption">
                          10% Daily First Deposit Bonus up to THB 300. Grab it
                          now!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Product</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Maximum Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Requirement</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  Sports &amp; Live Casino (Except ALLBET)
                                </td>
                                <td align="center">10%</td>
                                <td align="center">THB 300</td>
                                <td align="center">Rollover 15x</td>
                              </tr>
                              <tr>
                                <td align="center">
                                  All Slots (Except Pussy888)
                                </td>
                                <td align="center">10%</td>
                                <td align="center">THB 300</td>
                                <td align="center">
                                  Min. Withdraw = (Deposit + Bonus) x5
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          1&#41;{" "}
                          <strong>Rollover Calculation Example :-</strong>
                          <br />
                          Deposit = THB 100
                          <br />
                          Bonus 10% = THB 10
                          <br />
                          Required Bet Amount = (THB 100 + THB 10) x 15 = 1,650
                          <br />
                        </p>

                        <p style={{ textAlign: "left" }}>
                          2&#41; <strong>Min. Withdraw Example :-</strong>
                          <br />
                          Deposit = THB 100
                          <br />
                          Bonus 10% = THB 10
                          <br />
                          Min. Withdraw = (THB 100 + THB 10) x 5 = THB 550
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          This promotion is subject to availability. Promotion
                          does not applicable to ALLBET, PUSSY888.
                        </li>
                        <li>
                          Players are required to deposit a minimum amount of
                          THB 30 to be entitled for this promotion.
                        </li>
                        <li>
                          Players may claim the promotion once (1&#41; per day.
                          To be eligible for this promotion, kindly select this
                          promo under Bonus Box during submission of deposit.{" "}
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Texas Hold'em, 4D lottery, Fish Hunting, RNG Table
                            games &amp; Multi-player/Arcade games are not
                            applicable to this promotion. (All winnings may be
                            forfeited)
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Only 25% of total wagers placed on any games under
                            Game Show category, Roulette (all Variations) will
                            count towards the wagering requirement of any bonus.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Members are not entitled for the Daily Rebate if
                            wager in Live Casino products with this promotion.
                          </strong>
                        </li>
                        <li>
                          Only all confirmed bets/wagers are counted towards the
                          rollover requirement. All drawn or tied wagers,
                          cancelled or void bets/wagers, Early cash out or bets
                          placed on opposite sides in a single game will not
                          count towards the rollover accumulation. Any bets
                          placed containing selections of odds that are less
                          than 0.5 or 1.50 (Decimal odds) will not count towards
                          any rollover.
                        </li>
                        <li>
                          Any unfulfilled previous bonus required rollover
                          amount or withdraw requirement and/or balance will be
                          accumulated to this bonus rollover requirement.
                        </li>
                        <li>
                          Offender found abuse or fraud promotion T&amp;C will
                          face bonuses &amp; winning forfeited.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix slot sport casino"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000011"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/UNLIMITED 5pct DAILY RE-DEPOSIT BONUS.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        UNLIMITED 5% DAILY RE-DEPOSIT BONUS
                      </div>
                      <div className="smpromo-caption">
                        Get Up To THB 100 On Every Deposit!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000011">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/UNLIMITED 5pct DAILY RE-DEPOSIT BONUS.png?1"
                        alt=""
                      ></img>
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          UNLIMITED 5% DAILY RE-DEPOSIT BONUS
                        </div>
                        <div className="pmcaption">
                          Get Up To THB 100 On Every Deposit!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Product</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Maximum Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Requirement</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  Sports &amp; Live Casino (Except ALLBET)
                                </td>
                                <td align="center">5%</td>
                                <td align="center">THB 100</td>
                                <td align="center">Rollover 10x</td>
                              </tr>
                              <tr>
                                <td align="center">
                                  All Slots (Except Pussy888){" "}
                                </td>
                                <td align="center">5%</td>
                                <td align="center">THB 100</td>
                                <td align="center">
                                  Min. Withdraw = (Deposit + Bonus) x3
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          1&#41;{" "}
                          <strong>Rollover Calculation Example :-</strong>
                          <br />
                          Deposit = THB 100
                          <br />
                          Bonus 5% = THB 5<br />
                          Required Bet Amount = (THB 100 + THB 5) x 10 = 1,050
                          <br />
                        </p>

                        <p style={{ textAlign: "left" }}>
                          2&#41; <strong>Min. Withdraw Example :-</strong>
                          <br />
                          Deposit = THB 100
                          <br />
                          Bonus 5% = THB 5<br />
                          Min. Withdraw = (THB 100 + THB 5) x 3 = THB 315
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="pmtnc-wrap">
                      <div className="rules-btn-wrap">
                        <button
                          className="pm-more-btn rules"
                          data-toggle="modal"
                          data-target="#general-rules"
                        >
                          General Rules
                        </button>
                      </div>

                      <strong>TERMS &amp; CONDITIONS</strong>
                      <ul className="terms">
                        <li>
                          This promotion is subject to availability. Promotion
                          does not applicable to ALLBET, PUSSY888.
                        </li>
                        <li>
                          Players are required to deposit a minimum amount of
                          THB 30 to be entitled for this promotion.
                        </li>
                        <li>
                          To be eligible for this promotion, kindly select this
                          promo under Bonus Box during submission of deposit.{" "}
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Texas Hold'em, 4D lottery, Fish Hunting, RNG Table
                            games &amp; Multi-player/Arcade games are not
                            applicable to this promotion. (All winnings may be
                            forfeited)
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Only 25% of total wagers placed on any games under
                            Game Show category, Roulette (all Variations) will
                            count towards the wagering requirement of any bonus.
                          </strong>
                        </li>
                        <li style={{ color: "#FFD700" }}>
                          <strong>
                            Members are not entitled for the Daily Rebate if
                            wager in Live Casino products with this promotion
                          </strong>
                        </li>
                        <li>
                          Only all confirmed bets/wagers are counted towards the
                          rollover requirement. All drawn or tied wagers,
                          cancelled or void bets/wagers, Early cash out or bets
                          placed on opposite sides in a single game will not
                          count towards the rollover accumulation. Any bets
                          placed containing selections of odds that are less
                          than 0.5 or 1.50 (Decimal odds) will not count towards
                          any rollover.
                        </li>
                        <li>
                          Any unfulfilled previous bonus required rollover
                          amount or withdraw requirement and/or balance will be
                          accumulated to this bonus rollover requirement.
                        </li>
                        <li>
                          Offender found abuse or fraud promotion T&amp;C will
                          face bonuses &amp; winning forfeited.
                        </li>
                        <li>
                          The General Rules Terms &amp; Conditions of KISS VIP
                          apply to all promotions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix slot sport casino"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000020"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/UNLIMITED DAILY REBATE UP TO 1.00.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        UNLIMITED DAILY REBATE UP TO 1.00%
                      </div>
                      <div className="smpromo-caption">
                        KISS VIP would like to show its appreciation for all loyal
                        members with a rewarding Unlimited Daily Rebate up to
                        1.00%
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000020">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/UNLIMITED DAILY REBATE UP TO 1.00.png?1"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          UNLIMITED DAILY REBATE UP TO 1.00%
                        </div>
                        <div className="pmcaption">
                          KISS VIP would like to show its appreciation for all
                          loyal members with a rewarding Unlimited Daily Rebate
                          up to 1.00%
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>VIP Level</strong>
                                </td>
                                <td align="center">
                                  <strong>Slot</strong>
                                </td>
                                <td align="center">
                                  <strong>Live Casino</strong>
                                </td>
                                <td align="center">
                                  <strong>Sportbook</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">Diamond</td>
                                <td align="center">1%</td>
                                <td align="center">0.8%</td>
                                <td align="center">0.8%</td>
                              </tr>
                              <tr>
                                <td align="center">Platinum</td>
                                <td align="center">0.8%</td>
                                <td align="center">0.7%</td>
                                <td align="center">0.6%</td>
                              </tr>
                              <tr>
                                <td align="center">Gold</td>
                                <td align="center">0.6%</td>
                                <td align="center">0.6%</td>
                                <td align="center">0.5%</td>
                              </tr>
                              <tr>
                                <td align="center">Silver</td>
                                <td align="center">0.6%</td>
                                <td align="center">0.5%</td>
                                <td align="center">0.4%</td>
                              </tr>
                              <tr>
                                <td align="center">Bronze</td>
                                <td align="center">0.5%</td>
                                <td align="center">0.5%</td>
                                <td align="center">0.4%</td>
                              </tr>
                              <tr>
                                <td align="center">Normal</td>
                                <td align="center">0.4%</td>
                                <td align="center">0.4%</td>
                                <td align="center">0.4%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Slot</strong>
                                </td>
                                <td align="center">
                                  <strong>Minimum Payout</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>
                                    All Slot games EXCEPT Pussy888
                                  </strong>
                                </td>
                                <td align="center" rowspan="5">
                                  THB 5
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>Live Casino</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>
                                    All Live Casino games EXCEPT Blackjack 21,
                                    Poker Casino Hold'em (all variations)
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>Sportbook</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>
                                    All Sportbook games EXCEPT Bets on odds 0.5
                                    (Asian Odds) or 1.5 (Decimal Odds) and below
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="pmtnc-wrap">
                          <div className="rules-btn-wrap">
                            <button
                              className="pm-more-btn rules"
                              data-toggle="modal"
                              data-target="#general-rules"
                            >
                              General Rules
                            </button>
                          </div>

                          <strong>TERMS &amp; CONDITIONS</strong>
                          <ul className="terms">
                            <li>
                              Rebate will be credited to member's account MAIN
                              WALLET daily after 3:00PM (GMT+8)
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                Only 25% of total wagers placed on Live Casino's
                                games under Game Show category, Roulette (all
                                variations) will count towards the rebate
                                calculation.
                              </strong>
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                All Draw result, Both sides bet, Voided or
                                Cancelled, Fish Hunting, RNG Table games &amp;
                                Multi-player/Arcade games are not applicable to
                                this promotion.
                              </strong>
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                Single game suite minimum payout Daily Rebate is
                                THB 5, and no maximum payout.
                              </strong>
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                Bets turnover generated from previous Daily
                                Rebates &amp; bonus / prize reward will not
                                count towards rebate calculation.
                              </strong>
                            </li>
                            <li style={{ color: "#FFD700" }}>
                              <strong>
                                All deposit made with deposit bonus and wager in
                                Live Casino, turnover generated is not entitled
                                for this promotion.
                              </strong>
                            </li>

                            <li>
                              Rebate is not subject to any rollover requirement.
                            </li>
                            <li>
                              This promotion can be claimed in conjunction with
                              other promotions unless otherwise stipulated.
                            </li>
                            <li>
                              The General Rules Terms &amp; Conditions of KISS VIP
                              apply to all promotions.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="promo-bx mix weekly"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000056"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/DOUBLE DELIGHT SPECIAL ATTENDANCE BONUS.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        DOUBLE DELIGHT SPECIAL ATTENDANCE BONUS
                      </div>
                      <div className="smpromo-caption">
                        As a gesture of thanks and appreciation to all loyal
                        members, KISS VIP will reward members with TWO types of
                        bonuses each month under a single promo!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="promo1000056">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/DOUBLE DELIGHT SPECIAL ATTENDANCE BONUS.png?1"
                        alt=""
                      />
                    </div>

                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          DOUBLE DELIGHT SPECIAL ATTENDANCE BONUS
                        </div>
                        <div className="pmcaption">
                          As a gesture of thanks and appreciation to all loyal
                          members, KISS VIP will reward members with TWO types of
                          bonuses each month under a single promo!
                        </div>
                      </div>

                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Attedance</strong>
                                </td>
                                <td align="center">
                                  <strong>Deposit</strong>
                                </td>
                                <td align="center">
                                  <strong>Turnover</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Slot Games</strong>
                                </td>
                                <td align="center">
                                  <strong>Live Casino / Sports</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center" rowspan="3">
                                  <strong>3 Consecutive Days</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 5000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>25000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 500</strong>
                                </td>
                                <td align="center" rowspan="3">
                                  Min &amp; Max Withdraw (bonus amount) X5
                                </td>
                                <td align="center" rowspan="3">
                                  10x rollover; Max Withdraw (bonus amount) X5
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>THB 3000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>15000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 300</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>THB 1000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>5000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 100</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          1&#41;{" "}
                          <strong>BONUS #1: WEEKLY ATTENDANCE BONUS</strong>
                          <br />
                          Qualification Criteria:-
                          <br />
                          1. Member must make at least one (1&#41; deposit per
                          day for a period of any 3 CONSECUTIVE DAYS within a
                          week. (e.g: Monday, Tuesday &amp; Wednesday, or
                          Thursday, Friday &amp; Saturday)
                          <br />
                          2. The bonus will be based on the accumulated deposit
                          made and turnover required during those 3 days.
                          <br />
                          3. For the deposit wager in Pussy888 is not entitle
                          for this promo.
                          <br />
                        </p>
                      </div>

                      <div className="pm-dvd-line"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>Deposit Count</strong>
                                </td>
                                <td align="center">
                                  <strong>Deposit</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Slot Games</strong>
                                </td>
                                <td align="center">
                                  <strong>Live Casino / Sports</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>30 or 31 Days (FULL MONTH)</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 3000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 188</strong>
                                </td>
                                <td align="center" rowspan="6">
                                  Min &amp; Max Withdraw (bonus amount) X5
                                </td>
                                <td align="center" rowspan="6">
                                  10x rollover; Max Withdraw (bonus amount) X5
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>25 Days or more </strong>
                                </td>
                                <td align="center">
                                  <strong>THB 2500 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 88</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>20 Days or more</strong>
                                </td>
                                <td align="center">
                                  <strong>2000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 68</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>15Days or more</strong>
                                </td>
                                <td align="center">
                                  <strong>1500 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 38</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>10 Days or more</strong>
                                </td>
                                <td align="center">
                                  <strong>1000 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 28</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>7 days or more</strong>
                                </td>
                                <td align="center">
                                  <strong>700 &amp; Above</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 18</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <p style={{ textAlign: "left" }}>
                          1&#41;{" "}
                          <strong>BONUS #2: MONTHLY ATTENDANCE BONUS</strong>
                          <br />
                          Qualification Criteria:-
                          <br />
                          1. Member must fulfill at least SEVEN (7) deposit days
                          within a calendar month.
                          <br />
                          2. The bonus will be based on how many days of deposit
                          and amount made during the calendar month.
                          <br />
                        </p>
                      </div>

                      <div className="pmtnc-wrap">
                        <div className="rules-btn-wrap">
                          <button
                            className="pm-more-btn rules"
                            data-toggle="modal"
                            data-target="#general-rules"
                          >
                            General Rules
                          </button>
                        </div>

                        <strong>TERMS &amp; CONDITIONS</strong>
                        <ul className="terms">
                          <li>
                            Each member can claim weekly bonus once (1&#41; per
                            week, monthly bonus once (1&#41; per month. Bonus
                            only can be claimed when the stipulated requirements
                            have been met.
                          </li>
                          <li>
                            Kindly LiveChat us to claim Weekly Bonus in the
                            following Monday from 2AM onward and must be claimed
                            within 7 days. For Monthly Bonus, start on the 1st
                            day of the following month from 2AM onwards and must
                            be claimed within 30 days.
                          </li>
                          <li>
                            Only all confirmed bets/wagers are counted towards
                            the rollover requirement. All drawn or tied wagers,
                            cancelled or void bets/wagers, Early cash out or
                            bets placed on opposite sides in a single game will
                            not count towards the rollover accumulation. Any
                            bets placed containing selections of odds that are
                            less than 0.5 or 1.50 (Decimal odds) will not count
                            towards any rollover.
                          </li>
                          <li style={{ color: "#FFD700" }}>
                            <strong>
                              Only 25% of total wagers placed on Live Casino's
                              games under Game Show category, Roulette (all
                              variations) will count towards rollover
                              requirement.
                            </strong>
                          </li>
                          <li style={{ color: "#FFD700" }}>
                            <strong>
                              In the event where this bonus has been wagered in
                              4D lottery, Fish Hunting, RNG Table games &amp;
                              Multi-player/Arcade games, all bonuses and
                              winnings will be canceled.
                            </strong>
                          </li>
                          <li>
                            This promotion can be claimed in conjunction with
                            other KISS VIP promotions EXCEPT First Deposit Welcome
                            Bonus.
                          </li>
                          <li>
                            KISS VIP reserves the right to suspend or terminate any
                            duplicate accounts or any account that does not
                            adhere to the stipulated terms and conditions.
                          </li>
                          <li>
                            KISS VIP reserves the right to modify or cancel this
                            promotion at any time without prior notice.
                          </li>
                          <li>
                            The General Rules Terms &amp; Conditions of KISS VIP
                            apply to all promotions.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="promo-bx mix special"
                style={{ display: "inline-block" }}
                data-bound=""
              >
                <div
                  className="promobox"
                  data-toggle="modal"
                  data-target="#promo1000013"
                >
                  <div className="pm-img">
                    <img
                      src="../images/promo/88pct BIRTHDAY MONTH DEPOSIT BONUS.png?1"
                      alt=""
                    />
                  </div>
                  <div className="pm-detail-wrap">
                    <div className="comtext">
                      <div className="sm-promo-title">
                        UP TO THB 1388 BIRTHDAY CASH BONUS
                      </div>
                      <div className="smpromo-caption">
                        Celebrate your birthday here at KISS VIP with up to THB
                        1388 cash bonus!
                      </div>
                    </div>
                    <div className="more-info-wrap">
                      <button type="button" className="pm-more-btn">
                        More Info{" "}
                        <i className="fas fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="promo1000013">
                <div className="modal-dialog modal-dialog-centered promo">
                  <div className="modal-content promo">
                    <button
                      type="button"
                      className="close-x"
                      data-dismiss="modal"
                    >
                      <svg
                        fill-rule="evenodd"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="close"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                      </svg>
                    </button>
                    <div className="promo-banner-popup">
                      <img
                        src="../images/promo/88pct BIRTHDAY MONTH DEPOSIT BONUS.png?1"
                        alt=""
                      />
                    </div>
                    <div className="promo-popup-content">
                      <div className="pmtitle-wrap">
                        <div className="pmtitle">
                          UP TO THB 1388 BIRTHDAY CASH BONUS
                        </div>
                        <div className="pmcaption">
                          Celebrate your birthday here at KISS VIP with up to THB
                          1388 cash bonus!
                        </div>
                      </div>
                      <div className="pm-dvd-line-gold"></div>
                      <div className="pmwrap">
                        <div className="table-responsive promo-table">
                          <table width="100%" className="table-promo">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <strong>VIP Level</strong>
                                </td>
                                <td align="center">
                                  <strong>Bonus</strong>
                                </td>
                                <td align="center">
                                  <strong>Requirement</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>Diamond</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 1388</strong>
                                </td>
                                <td align="center" rowspan="4">
                                  Free Cash
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>Platinum</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 688</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>Gold</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 288</strong>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  <strong>Silver</strong>
                                </td>
                                <td align="center">
                                  <strong>THB 138</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <div className="table-responsive promo-table">
                            <table width="100%" className="table-promo">
                              <tbody>
                                <tr>
                                  <td align="center">
                                    <strong>Member Grade</strong>
                                  </td>
                                  <td align="center">
                                    <strong>Birthday Month</strong>
                                  </td>
                                  <td align="center">
                                    <strong>Bonus</strong>
                                  </td>
                                  <td align="center">
                                    <strong>Live Casino / Sportbook</strong>
                                  </td>
                                  <td align="center">
                                    <strong>All Slot Games</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center">
                                    <strong>Bronze</strong>
                                  </td>
                                  <td align="center" rowspan="2">
                                    3 Deposit Count &amp; Deposit THB 300
                                  </td>
                                  <td align="center">
                                    <strong>THB 88</strong>
                                  </td>
                                  <td align="center" rowspan="2">
                                    Rollover 3x
                                  </td>
                                  <td align="center" rowspan="2">
                                    WinOver X2
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center">
                                    <strong>Normal</strong>
                                  </td>
                                  <td align="center">
                                    <strong>THB 28</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <p style={{ textAlign: "left" }}>
                            1&#41; <strong>How It's Work ?</strong>
                            <br />
                            Birthday Members must send us following details to
                            our Live Chat service :-
                            <br />
                            Account Username :<br />
                            A copy of Identification Card for verification :
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className="pmtnc-wrap">
                        <div className="rules-btn-wrap">
                          <button
                            className="pm-more-btn rules"
                            data-toggle="modal"
                            data-target="#general-rules"
                          >
                            General Rules
                          </button>
                        </div>

                        <strong>TERMS &amp; CONDITIONS</strong>
                        <ul className="terms">
                          <li>
                            Birthday members are able to claim this offer once a
                            year at any time during the birthday month. Birthday
                            members will receive their bonus after the
                            Identification Card has been verified.
                          </li>
                          <li style={{ color: "#FFD700" }}>
                            <strong>
                              Birthday bonus amount is according to VIP level.
                            </strong>
                          </li>
                          <li style={{ color: "#FFD700" }}>
                            <strong>
                              Promotion is applicable at any time during the
                              birthday month subject to fulfil minimum 3 deposit
                              count and deposit THB300.
                            </strong>
                          </li>
                          <li style={{ color: "#FFD700" }}>
                            <strong>
                              One Identification Card are valid to claim for 1
                              Membership account. Duplicate account will not
                              qualify to this bonus reward.
                            </strong>
                          </li>
                          <li>
                            Live Casino/Sportsbook: bonus is subject to 3x
                            Rollover prior to withdrawal.
                          </li>
                          <li>
                            Slot Game (Include Pussy888) : bonus is subject to
                            Min. Withdraw requirement (2x bonus amount).
                          </li>
                          <li>
                            Offender found abuse or fraud promotion T&amp;C will
                            face bonuses &amp; winning forfeited.
                          </li>
                          <li>
                            4D Lottery, RNG Tables Games, Hunting Fish &amp;
                            Multi-player Games are not applicable to this
                            promotion.
                          </li>
                          <li>
                            Members are not entitled for the Daily Rebate and
                            Daily Slot Rescue Bonus with this promotion.
                          </li>
                          <li>
                            The General Rules Terms &amp; Conditions of KISS VIP
                            apply to all promotions.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="footer-sec pm-detail-wrap">
        <div class="max-container">
          <div class="footer-seo">
            <h1 style={{ color: "#787b8b" }}>
              Win Highest Online Casino Thailand Promotion
            </h1>

            <p style={{ color: "#787b8b" }}>
              Online gambling has gained immense popularity in Thailand, and the
              trend is not slowing down anytime soon. With the ever-increasing
              number of online casinos, it can be hard to find one that meets
              all your gambling needs and win{" "}
              <strong>Online Casino Thailand Promotion</strong>. However, at our
              online Casino, we offer a variety of exciting games and promotions
              to keep you entertained and help you win big. Our latest promotion
              includes the following:
            </p>

            <h2 style={{ color: "#787b8b" }}>Live Casino Thailand Promotion</h2>

            <p style={{ color: "#787b8b" }}>
              At our online Casino , we offer a thrilling live casino experience
              that allows you to interact with real dealers in real-time. Our{" "}
              <strong>Live Casino Thailand Promotion</strong> includes a 100%
              welcome bonus up to THB 300. This promotion is available for new
              players who make their first deposit of THB 50 or more.
            </p>

            <h2 style={{ color: "#787b8b" }}>Deposit THB 50 Get THB 88</h2>

            <p style={{ color: "#787b8b" }}>
              Make a <strong>Deposit THB 50 Get THB 88</strong> or more, and you
              will receive THB 88 bonus credits. This promotion is available for
              new and existing players, and the bonus credits can be used to
              play any of our games. Don't miss this opportunity to boost your
              bankroll and increase your chances of winning big.
            </p>

            <h2 style={{ color: "#787b8b" }}>
              100% Slot Welcome Bonus Thailand
            </h2>

            <p style={{ color: "#787b8b" }}>
              Our <strong>100% Slot Welcome Bonus Thailand</strong> is available
              for new players who make their first deposit of THB 50 or more.
              You will receive a 100% bonus up to THB 600, which can be used to
              play any of our slot games. With a wide range of slot games
              available, you're sure to find one that meets your preferences.
            </p>

            <h3 style={{ color: "#787b8b" }}>FAQs</h3>

            <h3 style={{ color: "#787b8b" }}>
              Q: Is it safe to gamble at your online Casino?
            </h3>

            <p style={{ color: "#787b8b" }}>
              A: Yes, we take the safety and security of our players very
              seriously. We use the latest SSL encryption technology to ensure
              that all personal and financial information is protected.
              Additionally, our games are regularly audited for fairness, so you
              can be sure that you're playing in a fair and transparent
              environment.
            </p>

            <h3 style={{ color: "#787b8b" }}>
              Q: What payment methods do you accept?
            </h3>
            <p style={{ color: "#787b8b" }}>
              A: We accept a variety of payment methods, including credit cards,
              debit cards, and e-wallets. Some of the most popular payment
              methods.
            </p>

            <p style={{ color: "#787b8b" }}>
              Online Casino Thailand | Live Casino Thailand | Online Sportsbook
              Thailand | Mobile Slot Game Thailand | Sports Toto Thailand
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default withStyles(styles)(Promos);
