export default {
  LANGS: '语言',
  HOME: '主页',
  SPORTS: '体育',
  POKER: 'Poker',
  VIRTUAL_SPORTS: '虚拟体育',
  LIVE_CASINO: '真人娱乐场',
  SLOTS: '老虎机',
  LOTTERY: '彩票',
  PROMOTIONS: '促销活动',
  CONTACT_US: '联系我们',
  LIVE_CHAT: '在线聊天',
  WECHAT: '微信',
  BANKING: '银行',
  ABOUT_US: '关于我们',
  RESPONSIBLE_GAMING: '负责任的游戏',
  TERMS_OF_USE: '使用条款',
  TRUSTED_PAYMENT: '可信付款',
  TRANSFER: '转移',
  TOPUP: '充值',
  WITHDRAW: '提款',
  ACCOUNT: '帐户',
  HISTORIES: '历史',
  REPORTS: '報告',
  DOWNLOAD: '下载',
  LOGOUT: '退出',
  EDIT_PROFILE: '编辑个人资料',
  CHANGE_PASSWORD: '更改密码',

  // FORM AREA
  BANK_NAME: '请输入银行名称（完整）',
  BANK_ACCOUNT_NAME: '请输入帐号名称',
  BANK_ACCOUNT_NO: '请输入银行帐号否',
  WITHDRAW_AMOUNT: '请输入提取金额',
  WITHDRAW_NOW: '现在提取金额',
  SELECT_BANK: '选择银行',
  TOPUP_AMOUNT: '请输入金额',
  AGREEMENT: '我同意条款和条件',
  ONLINE_TOP_UP_NOW: '现在线上存款',
  DEPOSIT_WITH_RECEIPT: '以收据存款',
  DEPOSIT_AMOUNT: '请输入存款金额',
  DEPSOSIT_BONUS: '索赔存款奖金',
  TRANSFER_IN: '转入',
  TRANSFER_OUT: '转出',
  TYPE_TRANS: '选择类型交易',
  SUBMIT: '立即提交',

  LOADING: '加载',
  FROM: '从日期',
  TO: '到日期',
  TRANS_SUC: '转移成功',
  TRANS_ERR: '传输错误',
  TRANS_DONE: '传输已成功完成',
  GOT_IT: '搞定了',
  SELECT_GAME: '选择游戏',
  CLAIM_LOYALTY_BONUS: '索赔忠诚奖金',
  DEPO_SUC: '存款成功',
  DEPO_ERR: '存款错误',
  DEPO_DONE: '您的请求已成功提交',
  DEPOSIT: '存款',
  FILE: '文件',
  WITH_SUC: '提取金额成功',
  WITH_ERR: '提取错误',
  WITH_DONE: '您的请求已成功提交',
  CLICK_DOWNLOAD: '点击下载',
  ENTER_MOBILE: '输入手机号',
  ENTER_PASS: '输入密码',
  SIGN_IN: '登录',
  REGISTER: '注册',
  ENTER_DISPLAY_NAME: '输入您的显示名称',
  ENTER_EMAIL: '输入您的主电子邮件',
  ENTER_AGENT: '输入您的代理电子邮件（可选）',
  RETYPE_PASS: '重新输入密码',
  BACK_TO_LOGIN: '返回登录',
  PULL_OUT: '信用转去钱包',
  RESTORE: 'Restore',
  
  AG_FISHING_KING: 'AG钓鱼王',
  NOTICE_LABEL: '公告',
  NOTICE: '中國農業銀行將執行銀行系統',

  MEMBER_REGISTER: '会员注册',
  YOUR_ACCOUNT_CONTACT_DETAIL: '您的帐户和联系方式',
  USERNAME: '用户名',
  USERNAME_CONDITION: '仅3到9个字符，字母数字（a-z，0-9）',
  EMAIL: '电子邮件地址',
  EMAIL_CONDITION: '密码检索需要正确的电子邮件地址',
  PASSWORD: '密码',
  PASSWORD_CONDITION: '密码要求至少1个小写字母，1个大写字母，1个数字以及6至10个字母数字（A-Z，a-z，0-9）。',
  CONFIRM_PASSWORD: '确认密码',
  CURRENCE: '货币',
  PHONE: '电话号码',
  PHONE_CONDITION: '请填写正确的电话号码（仅数字格式），以便在需要重设密码时更加轻松',
  BANK: '银行名称',
  BANK_NUMBER: '银行帐号',
  BANK_NUMBER_CONDITION: '请填写正确的银行帐号，以便在需要提款时更容易',
  BANK_ACCOUNT_NAME_CONDITION: '仅在银行存折中列出姓名和字符',
  TERM_18: '*我今年18岁以上，已阅读并接受本网站上发布的条款和条件，隐私权政策和投注规则。',
  LOGIN: '登录',
  MEMBER_LOGIN: '会员登录',
  FORGOT_PASSWORD: '忘记密码?',
  MOST_POPULAR_GAMES: '最受欢迎的游戏',
  MOST_POPULAR_GAMES_DESCRIPTION: '体验貴賓感觉！',
  VIDEO_GAME_BONUS_POOL: 'Newwin 超级幸运奖',
  PLAY_NOW: '现在播放',
  RECOMMEND_GAMES: '推荐游戏',
  WINNING_LIST_YESTERDAY: '昨天获奖名单',
  NUMBER_OF_BEST_PLACED: '下注数量',
  AMOUNT_PAID_OUT: '支付金额',
  MORE: '更多',
  CURRENT_BALANCE: '当前余额',
  PAYMENT_METHOD: '付款方法',
  FROM_BANK: '从银行',
  AMOUNT: '等于',
  RELOAD: '重新載入',
  FROM_DATE: '從日期',
  TO_DATE: '至今',
  START_THE_ROAD_TO_ADVANCEMENT: '開始前進的道路',
  ROAD_TO_ADVANCEMENT: '進步之路',
  START_UPDATE_EVERY_MONDAY: '每個星期一開始更新',
  MY_OFFER: '我的報價',
  RECENTLY_PLAYED: '最近播放',
  MY_COLLECTION: '我的收藏',

  MY_ACCOUNT: '我的帐户',
  YOUR_WALLET: '你的钱包',
  MY_WALLET: '我的钱包',
  BONUS: '奖金',
  QUICK_REGISTER: '快速开户',
  LATEST_OFFERS: '最新优惠',
  GAME: '游戏',
  GAMES: '游戏类',
  LIST: '目录',
  OTHER: '其他',
  AFFILIATE_AGENT: '会员代理',
  BET_THIS_MONTH: '这个月下注',
  WASHABLE_CODE_AMOUNT_OF: '洗码量',
  TO_WASH_CODE: '清洗代码',
  PLEASE: '请',
  TO_VIEW: '查看',
  NEWS: '消息',
  FORUM: '论坛',
  COPYRIGHT: '© 2018 97 IPAY 版权所有.',
  REWARDS: '奖赏',
  BASE_PROFILE: '基本资料',
  PROFILE: '资料',
  REFERAL_ID: '推荐人ID',
  EMAIL_ADDRESS: '电子邮件地址',
  MAIN_BALANCE: '主要余额',
  JOIN_DATE: '参加日期',
  LAST_EDIT: '最后编辑',
  SUBMIT_LABEL: '提交',
  RANKING: '排行',
  USER: '用户',
  DEPOSIT_TOTAL: '存款总额',
  BET_AMOUNT: '投注金额',
  WINNING_AMOUNT: '中奖金额',
  STATISTICS_WITHIN: '7天内统计',
  HIGH_EXPLOSION: '高流行',
  POPULAR: '流行',
  TOTAL_BET: '总投注',
  WIN_LOSE: '输赢',
  SEVEN_DAY_STATISTICS: '7天统计',

  DEPOSIT_ASSISTANT: '存款助理',
  CALL: '呼叫',
  DOWNLOAD_APP: '下载应用程式',

  PROMOTIONS_DESCRIPTION: '促销活动',
  SLOT_DESCRIPTION: '花式游戏',
  LIVE_CASINO_DESCRIPTION: '真人游戏',
  LOTTERY_DESCRIPTION: '彩票',
  SPORTBOOK_DESCRIPTION: '体育',
  FISHING: '釣魚',
  ONLINE_BANKING: '網上銀行業務',
  CREDIT_NOT_ENOUGH: '信用額度不足，請聯繫客服。',
  INCORRECT_USERNAME_PASSWORD: '用戶名或密碼錯誤',
  ERROR: '錯誤',

  

  SPORTSBOOK_PAGE: "Sportsbook page",
  SPORTSBOOK_PAGE_TITLE: "Online Sportsbook Thailand | Trusted Online Sports Betting Bookie",
  SPORTSBOOK_DESCRIPTION: "(KISS) Sportsbook is an online sports betting platform that offers a wide range of sports events and markets for users to bet on. We will take a closer look at the various features and aspects of Online Sportsbook Thailand. We are a website that offers sports betting services to users in Thailand. The website features a variety of sports events and games that users can bet on, including soccer, basketball, tennis, and more.",
  SPORTS_EVENTS_MARKETS_TITLE: "Sports Events and Markets:",
  SPORTS_EVENTS_MARKETS_DESC: "(KISS) Sportsbook offers a vast selection of sports events and markets for users to bet on, including popular sports such as football, basketball, tennis, and many more. Online Sports Betting Thailand platform also offers a variety of bet types, including money line, point spread, and over/under.",

  USER_INTERFACE_TITLE: "User Interface and Experience:",
  USER_INTERFACE_DESC: "Sbobet Login Thailand: -",
  USER_INTERFACE_DESC2: "Has a user-friendly interface, making it easy for users to navigate and place their bets. The platform is available on both desktop and mobile devices, allowing users to bet on their favourite sports events on the go.",

  BONUSES_PROMOTIONS_TITLE: "Bonuses and Promotions:-",
  BONUSES_PROMOTIONS_DESC: "(KISS) Sportsbook offers various bonuses and promotions to its users. Wbet Thailand users can avail themselves of a welcome bonus upon signing up, and there are also regular promotions such as cashback offers and free bets.",
  SECURITY_SAFETY_TITLE: "Security and Safety:",
  SECURITY_SAFETY_DESC: "(KISS) Sportsbook takes the safety and security of its users seriously. The platform uses the latest encryption technology to ensure that all user information and transactions are secure. The website also offers bonuses and promotions to new and existing users, such as welcome bonuses and cashback offers.",
  CUSTOMER_SUPPORT_TITLE: "Customer Support:",
  CUSTOMER_SUPPORT_DESC: "Online Football Betting Thailand offers customer support 24/7, and users can reach out to the support team through various channels, including live chat, email, and phone.",
  QA_SECTION_TITLE: "Q&A",
  QA_QUESTION1: "Q: Is (KISS) Sportsbook available in all countries?",
  QA_ANSWER1: "A: No, (KISS) Sportsbook is not available in all countries. It is important to check if the platform is available in your country before signing up.",
  QA_QUESTION2: "Q: What payment methods are accepted by (KISS) Sportsbook?",
  QA_ANSWER2: "A: (KISS) Sportsbook accepts various payment methods, including credit/debit cards, e-wallets, and bank transfers.",
  PLATFORM_DESCRIPTION: "We are a platform for sports betting enthusiasts in Thailand to place bets on their favorite teams and games, with a range of options and features designed to enhance the user experience.",
  FOOTER_TAGS: "Casino Online Mobile Thailand | Thailand Live Casino Online | Online Casino Thailand Promotion | Online Slot Thailand | 6D Betting Thailand",

  LIVE_CASINO_PAGE_TITLE: "Live Casino page",
  LIVE_CASINO_INTRO: "Play Best Live Casino Games Thailand - Pragmatic Play, Evolution Gaming, Live Roulette",
  LIVE_CASINO_DESC: "Thailand best live dealer casino, play live roulette, live blackjack, live baccarat, live poker and much more here. Whatever you want is here!",
  LIVE_CASINO_POPULARITY: "In recent years, the popularity of Online Live Casino Thailand games has skyrocketed, especially in Thailand. The convenience of being able to play at your favorite slot games on your mobile phone has made it even more accessible to avid players. If you as a casino player are a fan of Live Casino Games Thailand and reside in Thailand, you are in luck. In this article, we will be discussing the top mobile slot games available in Thailand.",
  THAILAND_LIVE_CASINO_TITLE: "Thailand Live Casino Online",
  THAILAND_LIVE_CASINO_DESC: "Thailand Live Casino Online are readily available for anyone who wants to play. Online casinos offer the best slot game titles, with excellent graphics and a variety of themes to choose from. Players can access these games on their desktop, laptop, or mobile devices, making it more convenient to play anywhere and anytime.",
  LIVE_ROULETTE_TITLE: "Live Roulette Online Thailand",
  LIVE_ROULETTE_DESC: "Live roulette online in Thailand offers players an immersive gaming experience that replicates the thrill of playing at a physical casino. The live stream is high quality and the interface is easy to use, making it a great option for both new and experienced players.",

  PLAYTECH_TITLE: "Playtech Casino Thailand",
  PLAYTECH_DESC: "Playtech is a leading provider of online gaming software and has a strong presence in the Thailand market. Playtech offers a range of exciting online roulette games with different variations and features to suit every player's preference. Some of their popular titles include Age of the Gods Roulette, Premium Roulette Pro, and European Roulette.",
  PRAGMATIC_PLAY_TITLE: "Pragmatic Play Thailand",
  PRAGMATIC_PLAY_DESC: "Pragmatic Play is another top software provider in the online gaming industry and is known for its innovative and high-quality games. Their online roulette games feature modern graphics and user-friendly interfaces, making them a popular choice among players in Thailand. Some of their top roulette titles include Roulette Crystal, Roulette Azure, and Roulette Titanium.",
  MOBILE_LIVE_CASINO_TITLE: "Mobile Thailand Live Online Casino",
  MOBILE_LIVE_CASINO_DESC: "Mobile Thailand Live Online Casino are the most popular way to play slots online. It is incredibly convenient and easy to access on your mobile device, and there are many different types of mobile slot games available in Thailand. Some of the popular games include 918Kiss, Mega888, and Pussy888, to name a few.",
  EVOLUTION_GAMING_TITLE: "Evolution Gaming Live Casino Thailand",
  EVOLUTION_GAMING_DESC: "Online Evolution Gaming Live Casino Thailand are an excellent way to enjoy the excitement of a casino game from the comfort of your home. Casino Live Thailand offer players the chance to win big jackpots while enjoying the best graphics and gameplay available. With a vast selection of Live Casino Online Thailand games to choose from, players can easily find a slot game that fits their taste.",

  QA_QUESTION3: "Q: Is it legal to play online casino games in Thailand?",
  QA_ANSWER3: "A: Gambling is illegal in Thailand, but players can still access online casinos and slot games through offshore websites. Players should always make sure to gamble responsibly and only play with a trusted online casino.",
  QA_QUESTION4: "Q: How can I ensure the safety of my personal information while playing online casino games in Thailand?",
  QA_ANSWER4: "A: It is essential to choose a reputable online casino that is licensed and regulated. These online casinos ensure the safety and security of their players' personal and financial information.",
  QA_QUESTION5: "Q: What are the most popular mobile live casino games in Thailand?",
  QA_ANSWER5: "A: Some of the most popular mobile slot games in Thailand include 918Kiss, Mega888, and Pussy888. These games offer excellent graphics, exciting gameplay, and the chance to win big jackpots.",
  FOOTER_TAGS2: "Casino Online Mobile Thailand | Online Sports Betting Thailand | Online Casino Thailand Promotion | Online Slot Thailand | 6D Betting Thailand",

  SLOT_GAME_PAGE_TITLE: "Slot game page",
  SLOT_GAME_INTRO: "Slot Game Online for Mobile Thailand | Slot Judi Thailand | Thailand Online Casino Slot | Online Slot Thailand | Online Slots Games Thailand",
  SLOT_GAME_DESC: "If you're a fan of online gambling, you'll be pleased to know that we offer a wide range of Mobile Slot Game Thailand games that can be played on your mobile device. Our Slot Game Online for Mobile Thailand offers a seamless and user-friendly experience, making it easy for you to access your favorite Slots Game Thailand wherever you are.",
  SLOT_GAME_CONVENIENCE: "Convenience: You can play Online Slot Thailand games on your mobile device from the comfort of your own home or on the go. All you need is an internet connection, and you're ready to start playing.",
  SLOT_GAME_VARIETY: "Variety: We offer a wide range of Slots Game Thailand, from classic three-reel slots to modern video slots. With so many games to choose from, you're sure to find one that meets your preferences.",
  SLOT_GAME_BONUSES: "Bonuses and Promotions: We offer a range of Thailand Slots Casino bonuses and promotions for new and existing players, including welcome bonuses, free spins, and cashback offers. These promotions can help boost your bankroll and increase your chances of winning big.",

  SLOT_TOP_GAMES_TITLE: "Thailand Online Casino Slot with Top Games",
  SLOT_TOP_GAMES_DESC: "Our online Casino offers a variety of Online Slots Games Thailand games that are tailored to meet the preferences of Thailand players. From traditional Thailand-themed slots to modern video slots, we have something for everyone. (KISS) online slot games are designed to provide a fun and exciting experience, with high-quality graphics and immersive sound effects.",
  SLOT_MOBILE_PLAY_TITLE: "Play (KISS) online Mobile Slot Game Thailand",
  SLOT_MOBILE_PLAY_DESC: "Our mobile slot games are designed to be played on any mobile device, including smartphones and tablets. We use the latest technology to ensure that our games run smoothly on all Slot Judi Thailand devices, so you can enjoy a seamless gaming experience no matter where you are.",
  SLOT_KISS_ONLINE_TITLE: "Online Slot Thailand at (KISS) online",
  SLOT_KISS_ONLINE_DESC: "Our online slot games are easy to play and offer a range of betting options to suit all budgets. Whether you're a beginner or an experienced player, you're sure to find a slot game that meets your preferences. With a variety of themes and features, our Thailand Online Slot Casino slot games are sure to keep you entertained for hours.",
  FAQ_SLOT_FAIRNESS_Q: "Q: Are your slot games fair?",
  FAQ_SLOT_FAIRNESS_A: "A: Yes, our Slot Game Online for Mobile Thailand games are regularly audited for fairness to ensure that all outcomes are completely random. We use a Random Number Generator (RNG) to determine the outcome of each spin, which ensures that the results are completely unbiased.",
  FAQ_SLOT_DEPOSIT_Q: "Q: How do I deposit and withdraw funds?",
  FAQ_SLOT_DEPOSIT_A: "A: We offer a variety of payment methods, including credit cards, debit cards, and e-wallets. To make a deposit, simply select your preferred payment method and follow the on-screen instructions. Withdrawals are processed quickly and securely, and funds are usually credited to your account within a few working days.",
  SLOT_GAME_FOOTER_TAGS: "Online Casino Thailand | Live Casino Thailand | Online Sportsbook Thailand | Live Casino Thailand Promotion | 6D Betting Thailand",

  GAMING_PAGE_TITLE: "Gaming page",
  GAMING_PAGE_INTRO: "(KISS) - Trusted Place to Download Casino Games",
  GAMING_PAGE_DESC: "(KISS) is a website that offers online gaming services to users in Thailand. The website features several sections, including a live casino with games like baccarat, blackjack, and roulette, a slot games section with classic and video slots, a sportsbook for betting on sports events, a lottery section, and a promotions section with various bonuses and offers. Here's a brief summary of the different sections you can find on this website:",
  GAMING_LIVE_CASINO_TITLE: "Live Casino:",
  GAMING_LIVE_CASINO_DESC: "This section of the website offers the Best Online Gaming Website for Thailand games including baccarat, blackjack, roulette, and more. Users can play against real dealers and other players from around the world.",
  GAMING_SLOT_GAMES_TITLE: "Slot Games:",
  GAMING_SLOT_GAMES_DESC: "This section features a wide range of online slot games from different providers. Users can choose from classic slots, video slots, and more.",
  GAMING_SPORTSBOOK_TITLE: "Sportsbook:",
  GAMING_SPORTSBOOK_DESC: "Similar to the previous website, Download 918kiss Thailand allows users to place bets on a variety of sports events and games.",
  GAMING_LOTTERY_TITLE: "Lottery:",
  GAMING_LOTTERY_DESC: "This section offers users the chance to participate in lottery games such as 6D, more.",
  GAMING_PROMOTIONS_TITLE: "Promotions:",
  GAMING_PROMOTIONS_DESC: "The website features various bonuses and promotions for Download 918kaya Apk for Ios, including welcome bonuses, cashback offers, and more.",
  GAMING_SUPPORT_TITLE: "Support:",
  GAMING_SUPPORT_DESC: "Users can get help and support from the website's customer service team through live chat, email, and phone.",
  GAMING_FAQ_Q1: "Q1. Are we can play games online website easily?",
  GAMING_FAQ_A1: "Answer: Mega888 Login Thailand 2025 are very easy to create and play too, but it is generally believed that online gaming are amazing and easy to play as well.",
  GAMING_FAQ_Q2: "Q2. What kind of bonuses and promotions are offered on the website?",
  GAMING_FAQ_A2: "Answer: The website offers a variety of bonuses and promotions, including welcome bonuses, cashback offers, and other seasonal promotions. Users can visit the website's promotions section to view the current offers.",
  GAMING_PAGE_FOOTER_TAGS: "Casino Online Mobile Thailand | Thailand Live Casino Online | Online Sports Betting Thailand | Online Casino Thailand Promotion | Online Slot Thailand | 6D Betting Thailand",

  LOTTERY_PAGE_TITLE: "Lottery page",
  LOTTERY_PAGE_INTRO: "(KISS) : Play Popular 6D Betting Games in Thailand",
  LOTTERY_PAGE_DESC: "(KISS) Online is a popular online casino that offers a wide variety of betting games, including 6D betting games. Thailand Lottery games are based on the lottery format, where players select a 6-digit number and place a bet on it. If the number matches the winning number drawn, the player wins a cash prize. Here are the key headings to understand 6D Betting Thailand at (KISS) Online:",
  LOTTERY_HOW_TO_PLAY_TITLE: "How to Play 6D Betting Games:",
  LOTTERY_HOW_TO_PLAY_DESC: "To play 4D betting games at (KISS) Online Casino, players need to select a 6-digit number between 000000 to 999999. Once the number is selected, players can place their bets. The minimum bet amount varies depending on the game and the platform. The winning number is drawn on a specific date and time, and players can check the results online.",
  LOTTERY_TYPES_TITLE: "Types of 4D Betting Games:",
  LOTTERY_TYPES_DESC: "Online 6D Thai Lottery offers several types of 6D betting games, including 6D, 3D, 2D, 1D. Each game has its rules and regulations, and players should carefully read them before placing bets.",
  LOTTERY_ODDS_PAYOUTS_TITLE: "Odds and Payouts:",
  LOTTERY_ODDS_PAYOUTS_DESC: "Thai lottery 6D Thailand games depend on several factors, including the type of game, the number of digits matched, and the bet amount. The pay-out amount also varies based on these factors.",
  LOTTERY_FAQ_Q1: "Q1: What is a 6D betting game?",
  LOTTERY_FAQ_A1: "A1: Thai Lottery 6D Result Thailand is a type of lottery game that is popular in Thailand, and some other parts of Asia. In the game, players select a 6-digit number and place a bet on it. If their chosen number matches the winning number drawn by the game organizer, they win a prize.",
  LOTTERY_FAQ_Q2: "Q2: Is it possible to increase your chances of winning in a 6D betting game?",
  LOTTERY_FAQ_A2: "A2: While there is no guaranteed way to increase your chances of winning in a 4D betting game, some people try to use strategies like studying past winning numbers, looking for patterns, or using lucky numbers or other superstitions.",
  LOTTERY_PAGE_FOOTER_TAGS: "Online Casino Thailand | Live Casino Thailand | Online Sportsbook Thailand | Live Casino Thailand Promotion | Mobile Slot Game Thailand",

  HOME_PAGE_TITLE: "Home Page",
  HOME_PAGE_INTRO: "Win Highest Online Casino Thailand Promotion",
  HOME_PAGE_DESC: "Online gambling has gained immense popularity in Thailand, and the trend is not slowing down anytime soon. With the ever-increasing number of online casinos, it can be hard to find one that meets all your gambling needs and win Online Casino Thailand Promotion. However, at our online Casino, we offer a variety of exciting games and promotions to keep you entertained and help you win big. Our latest promotion includes the following:",
  LIVE_CASINO_PROMO_TITLE: "Live Casino Thailand Promotion",
  LIVE_CASINO_PROMO_DESC: "At our online Casino, we offer a thrilling live casino experience that allows you to interact with real dealers in real-time. Our Live Casino Thailand Promotion includes a 100% welcome bonus up to THB 300. This promotion is available for new players who make their first deposit of THB 50 or more.",
  DEPOSIT_PROMO_TITLE: "Deposit THB50 Get THB 88",
  DEPOSIT_PROMO_DESC: "Make a Deposit THB 50 Get THB 88 or more, and you will receive THB 88 bonus credits. This promotion is available for new and existing players, and the bonus credits can be used to play any of our games. Don't miss this opportunity to boost your bankroll and increase your chances of winning big.",
  SLOT_BONUS_TITLE: "100% Slot Welcome Bonus Thailand",
  SLOT_BONUS_DESC: "Our 100% Slot Welcome Bonus Thailand is available for new players who make their first deposit of THB 50 or more. You will receive a 100% bonus up to THB 600, which can be used to play any of our slot games. With a wide range of slot games available, you're sure to find one that meets your preferences.",
  FAQ_SAFE_GAMBLING_Q: "Q: Is it safe to gamble at your online Casino?",
  FAQ_SAFE_GAMBLING_A: "A: Yes, we take the safety and security of our players very seriously. We use the latest SSL encryption technology to ensure that all personal and financial information is protected. Additionally, our games are regularly audited for fairness, so you can be sure that you're playing in a fair and transparent environment.",
  FAQ_PAYMENT_METHODS_Q: "Q: What payment methods do you accept?",
  FAQ_PAYMENT_METHODS_A: "A: We accept a variety of payment methods, including credit cards, debit cards, and e-wallets. Some of the most popular payment methods.",
  HOME_PAGE_FOOTER_TAGS: "Online Casino Thailand | Live Casino Thailand | Online Sportsbook Thailand | Mobile Slot Game Thailand | Sports Toto Thailand",

  TRUSTED_CASINO_TITLE: "(KISS) Trusted Online Casino for Thailand",
  TRUSTED_CASINO_TITLE2: "VG Trusted Online Casino for Thailand: Enjoy Top Casino Games Anytime, Anywhere!",
  TRUSTED_CASINO_DESC: "Trusted Thailand Online Casino - Enjoy safe and secure gaming experience. Wide selection of slots games, sportsbook, live casino and casino bonus. Join Now!",
  TRUSTED_CASINO_PLAY_DESC: "Interested in playing popular Online Casino Thailand games that are both fun and convenient? If you are looking for Trusted Online Casino for Thailand, look no further then (KISS) Casino Online Mobile Thailand. Using your mobile device or at home, you can enjoy a variety of games from these sites, including live dealer games, table games, and slots.",
  TRUSTED_PLAYERS_TITLE: "Trusted Online Casino for Thailand Players",
  TRUSTED_PLAYERS_DESC: "There is no better Trusted and Top Online Casino Thailand 2025 than (KISS) if you're looking for a trusted casino. With a reputation for providing safe and fair gaming experiences, this Casino has been in business.",
  MOBILE_CASINO_TITLE: "Casino Online Mobile Thailand",
  MOBILE_CASINO_DESC: "You can play your favorite games on-the-go at many Thailand online casinos, which offer mobile versions of their websites and mobile apps. There are a number of mobile casinos available in Thailand, but (KISS) online is one of the best Thailand Online Casino. Among the games they offer numerous games and all optimized for mobile play.",

  EVOLUTION_GAMING: "A live casino game provider with high-quality streaming and professional dealers.",
  PLAYTECH: "A large provider of Mobile Casino Thailand software, offering a wide variety of games such as slots, table games, and live dealer games.",
  WBET: "An online casino offering casino games, sports betting, and live casino games in Thailand.",
  SPADE_GAMING: "Provides casino operators with a wide range of games and solutions.",
  KISS918: "This Casino Online has been a huge success in Thailand as well as other Asian countries. It offers slots, table games, as well as live dealer games.",
  MAXBET: "Maxbet is an Asian-based sports betting platform that offers a wide variety of sports betting options, slots, and live casino games, making it popular with sports betting fans in Asia.",
  ALLBET: "Allbet offers live casino games, including baccarat, blackjack, and roulette, with multiple viewing angles and interactive features, that can be played from anywhere in the world.",
  TOP_CASINO_2025: "Top Online Casino Thailand 2025",
  TOP_CASINO_DESC: "The best online casino experience in Thailand can be found at (KISS) online if you are looking for the best Thailand Casino Online experience. In addition to slot machines, table games, and live dealer games, they also offer a wide range of bonuses and promotions, and have a reputation for providing their customers with a safe and secure gaming experience. There are also generous bonuses and promotions available to all players, including a welcome bonus when they sign up for the first time.",
  EVOLUTION_GAMING_TITLE2: "Evolution Gaming:",
  PLAYTECH_TITLE2: "Playtech:",
  WBET_TITLE: "WBet:",
  SPADE_GAMING_TITLE: "Spade Gaming:",
  KISS918_TITLE: "918kiss:",
  MAXBET_TITLE: "Maxbet:",
  ALLBET_TITLE: "Allbet:",

  ONLINE_CASINO_ANDROID: 'Thailand Online Casino for Android',
  ONLINE_CASINO_ANDROID_DESC: "There are plenty of casino games you can play on your Android device even if you're an Android user. I have found (KISS) online to be one of the most popular Online Casino Thailand for Android users. In addition to slots and table games, they also offer live dealer games, and all of their games are optimized to work on Android devices.",

  MOBILE_CASINO_PLATFORM: 'Mobile Casino Thailand - Ios & Andriod Users!',
  MOBILE_CASINO_PLATFORM_DESC: 'You can play Online Casino Real Money Thailand at (KISS) online Casino. The Casino offers a wide array of mobile-optimized games, such as slots, table games, and live dealer games. Moreover, they offer generous bonuses and promotions, including new player welcome bonuses.',

  REAL_MONEY_CASINO: 'Online Casino Real Money Thailand',
  REAL_MONEY_CASINO_DESC: "Many online casino sites offer real money casino games if you're looking to play for real money in Thailand. Thailand can play real money games at (KISS) online Casino, one of the top online casinos in the country. They accept a variety of payment methods and have a wide range of games with top Thailand Casino Online rewards.",

  JUDI_ONLINE: 'Judi Online Thailand - Get 100% Rewards!',
  JUDI_ONLINE_DESC: 'For all those casino players who are looking to play online casino games in Thailand, Judi Online Thailand is a great option. They offer a wide range of games, including slots, table games, and live dealer games, and have a reputation for providing a safe and secure gaming experience. They also offer generous bonuses and promotions..',

  REAL_WIN_CASINO: 'Real Casino Online Thailand For Real Win',
  REAL_WIN_CASINO_DESC: "If you're looking for a Real Casino Online Thailand experience from the comfort of your own home, look no further than (KISS) online Casino. They offer a wide range of games, including slots, table games, and live dealer games, all with high-quality graphics and sound. They also offer generous bonuses and promotions.",

  COMPREHENSIVE_CASINO: 'A Comprehensive Casino Thailand Online',
  COMPREHENSIVE_CASINO_DESC: "For a comprehensive Casino Thailand Online experience in Thailand, Casino Thailand Online is a great option. They offer a wide range of games, including slots, table games, and live dealer games, and accept a variety of payment methods.",

  ONLINE_CASINO: 'Thailand Casino Online – Slot | Betting | Live games',
  ONLINE_CASINO_DESC: "For a safe and secure online casino experience in Thailand, Thailand Casino Online is a great option. They offer a wide range of games, including slots, table games, and live dealer games, and have a reputation.",


  LIVE_ROULETTE: 'Live Roulette Online Thailand',
  LIVE_ROULETTE_DESC2: 'Live roulette is one of the most popular live casino games in Thailand, and for good reason. The game is easy to learn, and the rules are simple. You place your bets on the roulette table and wait for the dealer to spin the wheel. With live roulette online in Thailand, you get to experience the thrill of playing roulette in real-time, with live dealers and other players.',

  PRAGMATIC_PLAY: 'Pragmatic Play Thailand',
  PRAGMATIC_PLAY_DESC2: 'Pragmatic Play in the Casino is one of the leading providers of online casino games in the world, and their live casino games are no exception. With Pragmatic Play Thailand, you can enjoy a wide range of live casino games, including live roulette, baccarat, and blackjack. The games are designed to be easy to play and come with stunning graphics and animations.',
  KISS_GAMBLING_CASINO_SITE_2025_TITLE: "(KISS) Gambling Casino Site 2025",
  KISS_GAMBLING_CASINO_SITE_2025: "Welcome to (KISS) Gambling Casino Site 2025, the premier online destination for casino gaming enthusiasts in Thailand. Our website offers a wide range of games, including slots, table games, and live dealer games, all designed to provide you with the most immersive and enjoyable gaming experience possible.",
  
  KISS_ONLINE_CASINO_LOGIN_TITLE: "(KISS) Online Casino Login",
  KISS_ONLINE_CASINO_LOGIN: "If you're an existing (KISS) member, you can easily access our (KISS) online casino login by logging in using your account details. Once you log in, you'll have access to all of our games, as well as our promotions and bonuses.",
  KISS_ONLINE_CASINO_LOGIN2: "If you're a new player, you can easily sign up for an account on our website and start playing your favorite casino games right away. Our registration process is quick and easy, and once you've created your account, you'll be able to make your first deposit and start playing for real money.",
  
  KISS_BEST_CASINO_WEBSITE_TITLE: "(KISS) Best Casino Website WIth Popular Game!",
  KISS_BEST_CASINO_WEBSITE: "At (KISS) best casino website, we pride ourselves on offering the best online casino experience in Thailand. Our website is designed with the user in mind, and our games are optimized for maximum enjoyment and convenience.",
  
  CASINO_SUPPORT_24_7: "24*7 Casino Support to Players We also offer a wide range of promotions and bonuses, including a generous welcome bonus for new players. Our customer support team is available 24/7 to answer any questions or concerns you may have, ensuring that you always have the best possible gaming experience.",
  CASINO_SUPPORT_24_7_2: "Whether you're a seasoned Online Live Casino Games Thailand gamer or a newcomer to the world of online gaming, (KISS) Gambling Casino Site 2025 has everything you need to enjoy the ultimate casino experience. So why wait? Sign up for an account today and start playing your favorite casino games on the best online casino website in Thailand!",
  
  Q_IS_IT_SAFE_TO_PLAY: "Is it safe to play at a real casino online in Thailand?",
  A_IS_IT_SAFE_TO_PLAY: "Yes, as long as you choose a reputable and licensed Online Live Casino Games Thailand. At our online Casino, we take the safety and security of our players very seriously. We use the latest encryption technology to ensure that all personal and financial information is protected. Additionally, our games are regularly audited for fairness, so you can be sure that you're playing in a fair and transparent environment.",
  
  Q_HOW_TO_LOGIN: "How do I login to my (KISS) online casino account?",
  A_HOW_TO_LOGIN: "To login to your (KISS) Online Live Casino Games Thailand account, simply go to the (KISS) website and click on the 'Login' button in the top right corner.",
  
  Q_IS_TRUSTED_BY_PLAYERS: "Is your online Casino trusted by Thailand players?",
  A_IS_TRUSTED_BY_PLAYERS: "Yes, our Thailand Slots Online Casino is trusted by thousands of Thailand players. We have a strong reputation for providing a safe and fair gaming experience, with a wide range of games and generous bonuses and promotions. Additionally, we are licensed and regulated by the relevant authorities, ensuring that we operate in a legal and ethical manner.",
  
  Q_CAN_PLAY_MOBILE: "Can I play mobile casino games in Thailand?",
  A_CAN_PLAY_MOBILE: "Yes, you can play mobile casino games in Thailand. Our Thailand Slots Online Casino offers a range of mobile-friendly games that can be played on any smartphone or tablet. We use the latest technology to ensure that our games run smoothly on all devices, so you can enjoy a seamless gaming experience no matter where you are.",
  
  Q_IS_COMPATIBLE_WITH_ANDROID: "Is your online Casino compatible with Android devices?",
  A_IS_COMPATIBLE_WITH_ANDROID: "Yes, our online Casino is compatible with Android devices. Our mobile casino games can be played on any Android smartphone or tablet, and we use the latest technology to ensure that our games run smoothly on all Android devices. Additionally, we offer a range of bonuses and promotions for Android users, so you can maximize your winnings while playing on your mobile device.",

  FOOTER_TAG3: "Live Casino Thailand | Online Sportsbook Thailand | Live Casino Thailand Promotion | Mobile Slot Game Thailand | Lottery Thailand"
}