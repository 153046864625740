import React, { useState } from "react";
import http from "service/http";
import { useTranslation } from "react-i18next";
import "./style.css";
import useLoading from "../loading/hook";
import store from "store";
import useDialog from "../dialog/hook";
import Resizer from "react-image-file-resizer";
import { Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { showNotification } from "../../utils/function";
import { ETypeNotification } from "../../constants/gameData";
import "react-tooltip/dist/react-tooltip.css";
import { PropagateLoader } from "react-spinners";
import MenuItemBoxLeftMobile from "../../component/layout/BoxLeft/MenuItemBoxLeftMobile";
import MenuItemBoxLeft from "../../component/layout/BoxLeft/MenuItemBoxLeft";
import config from "config";
import MainWallet from "../../component/layout/BoxLeft/MainWallet";

// const correctGameProps = ({
//   name,
//   id,
//   bank_account_name: bankAccountName,
//   bank_account_no: bankAccountNo,
// }) => ({
//   title: name,
//   value: {
//     name,
//     id,
//     bankAccountName,
//     bankAccountNo,
//   },
// });

const arrbanks = [
  {
    image: "images/icon/bankiconkrung.png",
    bank_account_name: "Leagend club",
    bank_account_no: "1234567890",
    code: "",
    id: 45,
    name: "Krungsri Bank",
    type: "Deposit",
  },
  {
    image: "images/icon/ThaibankRHB.png",
    bank_account_name: "Leagend club",
    bank_account_no: "1234567890",
    code: "",
    id: 45,
    name: "Rhb",
    type: "Deposit",
  },
  {
    image: "images/icon/ThaibankCIMB.png",
    bank_account_name: "Leagend club",
    bank_account_no: "1234567890",
    code: "",
    id: 45,
    name: "Cimb",
    type: "Deposit",
  },
  {
    image: "images/icon/bank_icon.png",
    bank_account_name: "Leagend club",
    bank_account_no: "1234567890",
    code: "",
    id: 45,
    name: "KrungThai Bank",
    type: "Deposit",
  },
  {
    image: "images/icon/bank_icon1.png",
    bank_account_name: "Leagend club",
    bank_account_no: "1234567890",
    code: "",
    id: 46,
    name: "Kasikorn Bank",
    type: "Deposit",
  },
  {
    image: "images/icon/bank_icon2.png",
    bank_account_name: "Leagend club",
    bank_account_no: "1234567890",
    code: "",
    id: 47,
    name: "SCB Bank",
    type: "Deposit",
  },
];

const bankOnline = [
  {
    value: "BKKB",
    title: "Bangkok Bank",
  },
  {
    value: "BAAC",
    title: "Bank for Agriculture and Agricultural Cooperatives	",
  },
  {
    value: "KSAB",
    title: "Bank of Ayudhya (Krungsri)",
  },
  {
    value: "CIMT",
    title: "CIMB Thai Bank",
  },
  {
    value: "GSBA",
    title: "Government Savings Bank	",
  },
  {
    value: "KSKB",
    title: "Kasikorn Bank	",
  },
  {
    value: "Kiatnakin Bank",
    title: "Kiatnakin Bank",
  },
  {
    value: "KTBA",
    title: "Krung Thai Bank	",
  },
  {
    value: "SCBB",
    title: "Siam Commercial Bank",
  },
  {
    value: "SCBT",
    title: "Standard Chartered Bank (Thai)",
  },
];

function NewDepositNewTest({ history }) {
  const { t } = useTranslation();
  const [banks, updateBanks] = React.useState([]);
  // const [userState, setUserState] = React.useState({});
  // const [checkedBank, setCheckedBank] = useState(1);
  // const [checkedBonus, setCheckedBonus] = useState(0);
  const [dataForm, setDataForm] = useState({});
  const user = store.get("user");
  // const [bonus, setBonus] = useState([]);
  const [kissvipBanks, setKissvipbanks] = useState([]);
  // const [bonusId, setBonusId] = useState(null);
  const [bankCode, setBankCode] = useState("");
  const [typeSubmit, setTypeSubmit] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [showBank, setShowBank] = useState(true);
  const [loading, withLoading] = useLoading(false);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [typeBank, setTypeBank] = useState("");
  const [Dialog, showDialogWithMessage] = useDialog({
    title: "Deposit Successful",
  });
  const [changeAmount, setChangeAmount] = useState("0");
  const [bonusValue, setBonusValue] = useState(-1);
  const [checkbank, setCheckbank] = useState("");
  // const [depositOption, setDepositOption] = useState(1);
  const handleChangeActive = (item, bank) => {
    setCheckbank(bank);
    handleCheck(item);
  };

  const [form] = Form.useForm();

  const handleChangeAmount = (amount) => {
    setChangeAmount(amount);
    form.setFieldValue("amount", amount);
  };
  const min = 0;
  const max = 3000000;

  const handleInputChange = (e) => {
    if (!e) {
      setChangeAmount("");
      return;
    }
    if (!Number.isNaN(+e)) {
      const val = String(Math.max(min, Math.min(max, Number(e))));
      setChangeAmount(val);
    }
  };

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: "Deposit Error",
    btnLabel: "Got it",
    type: "error",
  });

  const [gameBalances, updateGameBalances] = useState([]);

  const [userInfo, setUserInfo] = useState(user);

  const [balance, setBalance] = useState(0);

  const [activeTab, setActiveTab] = useState("BankTransfer");

  const [promotions, setPromotions] = useState([]);

  const [depositConfig, setDepositConfig] = useState([]);

  const fetchData = async () => {
    try {
      const [gamesResp, config] = await withLoading(() =>
        Promise.all([
          http.get({ path: "banks" }),
          http.get({ path: "config" })
        ])
      );
      const newBanks = gamesResp
        .filter((x) => x.type === "Deposit")
        .map((g) => {
          const found = arrbanks.find((x) => x.name === g.name);
          if (found) g.image = found.image;
          return g;
        });

      updateBanks(newBanks);
      const deposit = JSON.parse(config.deposit).filter(x => x.enabled);
      setDepositConfig(deposit);
      if(deposit && deposit.length > 0) {
        setActiveTab(deposit[0].id)
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchAccount = async () => {
    const { user_id: userId } = user;
    const [userResp, gameBalancesResp, promotions] = await withLoading(() =>
      Promise.all([
        http.get({ path: "me" }),
        http.get({ path: `users/${userId}/balance-games` }),
        http.get({ path: `user/promotion` }),
      ])
    );
    updateGameBalances(
      gameBalancesResp
        .filter((i) => i)
        .filter(({ game_code: gameCode }) =>
          config.gamesAvailable.includes(gameCode)
        )
    );
    setUserInfo(userResp);

    store.set("user", userResp);

    let bl = userResp.user_money || 0;
    if (bl === 0) {
      gameBalancesResp
        .filter((x) => x.balance.game_code !== "asiagaming")
        .forEach((r) => {
          bl += Number(r.balance.balance || 0);
        });
    }
    store.set("userBalance", bl);

    store.set("userBalanceTime", Date.now());

    setBalance(bl);

    setPromotions(
      promotions
    );
  };

  const onPullOut = async () => {
    const { user_id: userId } = store.get("user");
    const tasks = [];
    gameBalances.forEach(({ gameId, balance, game_code }) => {
      if (balance > 1 && game_code !== "asiagaming") {
        const providerCode = game_code === "ag_galaxy" ? "?ProviderId=AG" : "";
        tasks.push(
          http.post({
            path: `users/${userId}/game/${gameId}/withdraw${providerCode}`,
            payload: {
              amount: balance,
            },
          })
        );
      }
    });
    await withLoading(() => Promise.all(tasks));
    fetchAccount();
  };

  const fileChangedHandler = () => {
    const {
      files: [file],
    } = document.querySelector("input[type=file]");
    let fileInput = false;
    if (file) {
      fileInput = true;
    }
    return new Promise((resolve) => {
      if (fileInput) {
        Resizer.imageFileResizer(
          file,
          1900,
          900,
          "JPEG",
          50,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      }
    });
  };
  const onSubmit = async (payload) => {
    try {
      if (!payload.id) {
        showNotification(ETypeNotification.ERROR, "Please chooose bank.");
        return;
      }
      const { user_id: userId, user_account_no: username } = store.get("user");
      const {
        files: [file],
      } = document.querySelector("input[type=file]");
      let fileInput = false;
      if (file) {
        fileInput = true;
      }

      if (!fileInput) {
        showNotification(ETypeNotification.ERROR, "Please upload receipt.");
        return;
      }

      const proofOfDeposit = await fileChangedHandler();
      const submited = {
        amount: payload.amount,
        currency: "THB",
        to_bank_account_name: payload.bank_account_name,
        to_bank_account_number: payload.bank_account_no,
        to_bank: payload.name,
        to_bank_id: payload.id,
        from_bank: user.bankName,
        username,
        type: payload.type ? payload.type : "Online Transfer",
        receipt_image: proofOfDeposit,
        bonusId: payload.bonusId,
      };
      // if (bonusId) submited.bonusId = bonusId;
      // console.log(bonusId);
      await withLoading(() =>
        http.post({ path: `users/${userId}/deposit`, payload: submited })
      );
      // socket.emit('messages', {
      //   msgType: 'deposit.request',
      //   data: {
      //     message: `Request deposit from '${username}'`,
      //     title: 'Deposit Request'
      //   }
      // })
      showNotification(
        ETypeNotification.SUCCESS,
        "Your request submitted successfully"
      );
      setBonusValue(-1);
      // navigate('/account')
      // window.location.reload();
    } catch (error) {
      console.log("error.message", error);
      setBonusValue(-1);
      showNotification(ETypeNotification.ERROR, error.message);
    }
  };
  React.useEffect(() => {
    fetchData();
    fetchAccount();
    form.setFieldsValue({
      name: dataForm.name,
      bank_account_name: dataForm.bank_account_name,
      bank_account_no: dataForm.bank_account_no,
    });
  }, [form, dataForm]);

  const handleCheck = (item) => {
    // setCheckedBank(item.id);
    setDataForm(item);
  };

  // const handleCheckBonus = (id) => {
  //   // console.log(id)
  //   setBonusId(id);
  //   setCheckedBonus(checkedBonus === 1 ? 0 : 1);
  // };

  const handlerSelected = (item) => {
    // console.log(item)
    setBankCode(item);
    const f = kissvipBanks.find((x) => x.id === item);
    setSelectedBank(f.name);
    setCheckbank(f.name);
  };

  const handleSubmit = (values) => {
    if (["kissvippay", "kissvippayqr", "kissvippayqrtrue"].includes(typeBank)) {
      if (typeBank === "kissvippay" && !bankCode) {
        showNotification(ETypeNotification.ERROR, "Please chooose bank.");
        return;
      }
      onSubmitHandlerGetIdItemKissvip({
        ...values,
        bonusId: bonusValue,
        bankCode: bankCode,
        selectedBank: selectedBank,
      });
      // onSubmitVaderpay({...values})
    } else if (typeSubmit) {
      if (selectedBank) {
        showNotification(ETypeNotification.ERROR, "Please chooose bank.");
        return;
      }
      onSubmitHandlerGetIdItem({
        ...values,
        bonusId: bonusValue,
        bankCode: bankCode,
        selectedBank: selectedBank,
      });
      // onSubmitVaderpay({...values})
    } else {
      onSubmit({
        ...values,
        bonusId: bonusValue,
        id: dataForm.id,
        selectedBank: selectedBank,
        type: typeBank,
      });
    }
  };
  const onSubmitHandlerGetIdItemKissvip = async (payload) => {
    // console.log(payload)
    try {
      let submited = {
        bankCode: payload.bankCode,
        bankName: payload.selectedBank,
        amount: payload.amount,
        bonusId: payload.bonusId,
      };
      // if (bonusId) submited.bonusId = bonusId;
      let route = `payment-kissvip`;
      if (typeBank === "kissvippayqr") {
        submited = {
          amount: Number(payload.amount),
          postback_url: window.location.href,
        };
        route = "payment-kissvip/qr-deposit";
      } else if (typeBank === "kissvippayqrtrue") {
        submited = {
          amount: Number(payload.amount),
          postback_url: window.location.href,
        };
        route = "payment-kissvip/qr-true-deposit";
      }
      const response = await withLoading(() =>
        http.post({ path: route, payload: submited })
      );
      // console.log(response)
      if (!response.errors) {
        showDialogWithMessage("Please Process ...");
        window.location.href = response.redirect_path
          ? response.redirect_path
          : response.redirect_to;
      } else {
        showNotification(
          ETypeNotification.SUCCESS,
          "Your request submitted successfully"
        );
        const keys = Object.keys(response.errors);
        showDialogErrorWithMessage(response.errors[keys[0]]);
      }
      setBonusValue(-1);
      // window.location.reload()
    } catch (e) {
      console.log("e", e);
      setBonusValue(-1);
      showNotification(ETypeNotification.ERROR, e.message);
    }
  };
  const onSubmitHandlerGetIdItem = async (payload) => {
    try {
      const submited = {
        bankCode: payload.bankCode,
        amount: payload.amount,
        bonusId: payload.bonusId,
      };
      console.log(submited);
      // if (bonusId) submited.bonusId = bonusId;
      const response = await withLoading(() =>
        http.post({ path: `payment-vaderpay`, payload: submited })
      );

      showNotification(
        ETypeNotification.SUCCESS,
        "Your request submitted successfully"
      );

      if (response) {
        const linkRedirect = response.redirect_to;
        const link = linkRedirect.split("redirectlink=");
        window.open(link && link[1], "_blank");
      }
      setBonusValue(-1);
      window.location.reload();
    } catch (e) {
      console.log("e", e);
      setBonusValue(-1);
      showNotification(ETypeNotification.ERROR, e.message);
    }
  };

  // const handlerSubmitChangeUser = (values) => {
  //   onSubmitFormUpdateUser({ ...values });
  //   fetchAccount();
  //   setIsModalVisible(false);
  // };

  // const onSubmitFormUpdateUser = async (payload) => {
  //   try {
  //     const { user_id: userId } = store.get("user");
  //     const submited = {
  //       bankName: payload.bankName,
  //       bankAccount: payload.bankAccount,
  //       bankAccountNumber: payload.bankAccountNumber,
  //     };
  //     await withLoading(() =>
  //       http.put({ path: `users/${userId}`, payload: submited })
  //     );
  //     showNotification(
  //       ETypeNotification.SUCCESS,
  //       "Update Your Bank Detail Success"
  //     );
  //   } catch (error) {
  //     showNotification(ETypeNotification.ERROR, error.message);
  //   }
  // };

  const handlerShowBankSelected = (item) => {
    // setDepositOption(item);
    if (item === 4) {
      setShowBank(false);
      setTypeSubmit(false);
      setTypeBank(item === 4 ? "kissvippayqr" : "");
    } else if (item === 5) {
      setShowBank(false);
      setTypeSubmit(false);
      setTypeBank(item === 5 ? "kissvippayqrtrue" : "");
    } else if (item === 3) {
      setShowBank(false);
      setTypeSubmit(false);
      setTypeBank(item === 3 ? "kissvippay" : "");
    } else if (item === 2) {
      setShowBank(false);
      setTypeSubmit(true);
      setTypeBank(item === 2 ? "payment gateway" : "");
    } else {
      setShowBank(true);
      setTypeSubmit(false);
      setTypeBank(item === 1 ? "banking" : "");
    }
  };
  const handlerSelectedBankTHB = (item) => {
    // console.log('item', item);
    setBankCode(item);
  };
  const fetchBonus = async () => {
    const [b] = await withLoading(() =>
      Promise.all([
        // http.get({ path: `admin/promotion-new` }),
        http.get({ path: `payment-kissvip/banks` }),
      ])
    );
    // const activeBonus = bonus.filter((x) => x.active === 1);
    // setBonus(activeBonus);
    setKissvipbanks(b.accounts.filter((x) => x.status === "A"));
  };
  React.useEffect(() => {
    fetchBonus();
  }, []);

  return (
    <div className="content-body">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "180px",
            minHeight: "180px",
            textAlign: "center",
            marginTop: "80px",
          }}
          className="pro_title"
        >
          {t("LOADING")} ...
        </div>
      ) : (
        <div className="deposit-sec">
          <MenuItemBoxLeftMobile history={history} _pageName={"Deposit"} />
          <div className="af-container">
            <MenuItemBoxLeft history={history} _pageName={"Deposit"} promotion={userInfo.promotion} />
            <div className="afcolumn-right">
              <div className="afinner">
                <MainWallet
                  userInfo={userInfo}
                  balance={balance}
                  onPullOut={onPullOut}
                />
                {depositConfig && depositConfig.length > 0 ? (
                  <div className="frame-container">
                    <div
                      className="aform-wrapper"
                      style={{ padding: "15px 15px", overflow: "unset;" }}
                    >
                      <div className="af-submenu-wrap">
                        {depositConfig.map(item => {
                          let bankSelected = 1;
                          let src = 'images/icon/bank-transfer.png';
                          if(item.id.toLocaleLowerCase() === 'QuickPay'.toLocaleLowerCase()) {
                            src= 'images/icon/quickpay.png';
                            bankSelected = 2;
                          }
                          if(item.id.toLocaleLowerCase() === 'KissPay'.toLocaleLowerCase()) {
                            src= 'images/icon/bank-transfer.png';
                            bankSelected = 3;
                          }
                          if(item.id.toLocaleLowerCase() === 'kissPayQR'.toLocaleLowerCase()) {
                            src= 'images/icon/qrpay.png';
                            bankSelected = 4;
                          }
                          if(item.id.toLocaleLowerCase() === 'kissPayQRTrue'.toLocaleLowerCase()) {
                            src= 'images/icon/qrpay.png';
                            bankSelected = 5;
                          }
                          if(item.enabled) {
                            return (
                              <a
                                href="/"
                                className={
                                  activeTab.toLocaleLowerCase() === item.id.toLocaleLowerCase()
                                    ? "afsub-btn"
                                    : "afsub-btn active"
                                }
                                onClick={(e) => {
                                  setActiveTab(item.id.toLocaleLowerCase());
                                  handlerShowBankSelected(bankSelected);
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                <span className="aficon">
                                  <img
                                    className="w-auto"
                                    src={src}
                                    alt=""
                                  />
                                </span>
                                <span>{item.name}</span>
                              </a>
                            )} else {
                              return (
                                <></>
                              )
                            }
                          } 
                        )}
                        {/* <a
                          href="/"
                          className={
                            activeTab === "BankTransfer"
                              ? "afsub-btn"
                              : "afsub-btn active"
                          }
                          onClick={(e) => {
                            setActiveTab("BankTransfer");
                            handlerShowBankSelected(1);
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span className="aficon">
                            <img
                              className="w-auto"
                              src="images/icon/bank-transfer.png"
                              alt=""
                            />
                          </span>
                          <span>BANK TRANSFER</span>
                        </a>
                        <a
                          href="/"
                          className={
                            activeTab === "QuickPay"
                              ? "afsub-btn"
                              : "afsub-btn active"
                          }
                          onClick={(e) => {
                            setActiveTab("QuickPay");
                            handlerShowBankSelected(2);
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span className="aficon">
                            <img
                              className="w-auto"
                              src="images/icon/quickpay.png"
                              alt=""
                            />
                          </span>
                          <span>QUICK PAY</span>
                        </a>
                        <a
                          href="/"
                          className={
                            activeTab === "KissPay"
                              ? "afsub-btn"
                              : "afsub-btn active"
                          }
                          onClick={(e) => {
                            setActiveTab("KissPay");
                            handlerShowBankSelected(3);
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span className="aficon">
                            <img
                              className="w-auto"
                              src="images/icon/bank-transfer.png"
                              alt=""
                            />
                          </span>
                          <span>KISS pay</span>
                        </a>

                        <a
                          href="/"
                          className={
                            activeTab === "KISSPayQR"
                              ? "afsub-btn"
                              : "afsub-btn active"
                          }
                          onClick={(e) => {
                            setActiveTab("KISSPayQR");
                            handlerShowBankSelected(4);
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span className="aficon">
                            <img
                              className="w-auto"
                              src="images/icon/qrpay.png"
                              alt=""
                            />
                          </span>
                          <span>KISS Pay QR</span>
                        </a>

                        <a
                          href="/"
                          className={
                            activeTab === "KISSPayQRTrue"
                              ? "afsub-btn"
                              : "afsub-btn active"
                          }
                          onClick={(e) => {
                            setActiveTab("KISSPayQRTrue");
                            handlerShowBankSelected(5);
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <span className="aficon">
                            <img
                              className="w-auto"
                              src="images/icon/qrpay.png"
                              alt=""
                            />
                          </span>
                          <span>KISS Pay QR True</span>
                        </a> */}
                      </div>

                      <Form form={form} layout="vertical" onFinish={handleSubmit}>
                        <div className="depo-form-wrapper">
                          <div className="af-field-wrap">
                            {showBank && (
                              <div className="label-col">
                                Bank<span className="redtext">*</span>
                              </div>
                            )}
                            <PropagateLoader
                              sizeUnit="px"
                              size={20}
                              color="#f50057"
                              loading={loading}
                            />
                            {showBank && (
                              <div className="styled-group-banks">
                                <div className="styled-bank">
                                  <div
                                    className={
                                      checkbank === "kbank"
                                        ? "deposit-styled-item-bank"
                                        : "deposit-styled-item-bank-active"
                                    }
                                    onClick={() =>
                                      handleChangeActive(banks[0], "kbank")
                                    }
                                  >
                                    {checkbank === "kbank" ? (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/kbankactive.png"
                                        alt="bank"
                                      />
                                    ) : (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/kbankunactive.png"
                                        alt="bank"
                                      />
                                    )}
                                    <p className="withdraw-text white-text">
                                      Kbank
                                    </p>
                                  </div>
                                  <div
                                    className={
                                      checkbank === "krungthai"
                                        ? "deposit-styled-item-bank"
                                        : "deposit-styled-item-bank-active"
                                    }
                                    onClick={() =>
                                      handleChangeActive(!banks[3], "krungthai")
                                    }
                                  >
                                    {checkbank === "krungthai" ? (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/krungactive.png"
                                        alt="bank"
                                      />
                                    ) : (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/krungunactive.png"
                                        alt="bank"
                                      />
                                    )}
                                    <p className="withdraw-text white-text">
                                      Krungthai
                                    </p>
                                  </div>
                                  <div
                                    className={
                                      checkbank === "scb"
                                        ? "deposit-styled-item-bank"
                                        : "deposit-styled-item-bank-active"
                                    }
                                    onClick={() =>
                                      handleChangeActive(banks[2], "scb")
                                    }
                                  >
                                    {checkbank === "scb" ? (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/acbactive.png"
                                        alt="bank"
                                      />
                                    ) : (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/acbunactive.png"
                                        alt="bank"
                                      />
                                    )}
                                    <p className="withdraw-text white-text">
                                      SCB
                                    </p>
                                  </div>
                                  <div
                                    className={
                                      checkbank === "krungsri"
                                        ? "deposit-styled-item-bank"
                                        : "deposit-styled-item-bank-active"
                                    }
                                    onClick={() =>
                                      handleChangeActive(banks[1], "krungsri")
                                    }
                                  >
                                    {checkbank === "krungsri" ? (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/krungsriactive.png"
                                        alt="bank"
                                      />
                                    ) : (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/krungsriunactive.png"
                                        alt="bank"
                                      />
                                    )}
                                    <p className="withdraw-text white-text">
                                      Krungsri
                                    </p>
                                  </div>
                                  <div
                                    className={
                                      checkbank === "bangkok"
                                        ? "deposit-styled-item-bank"
                                        : "deposit-styled-item-bank-active"
                                    }
                                    onClick={() =>
                                      handleChangeActive(!banks[3], "bangkok")
                                    }
                                  >
                                    {checkbank === "bangkok" ? (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/bankokbank.png"
                                        alt="bank"
                                      />
                                    ) : (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/bankokbank.png"
                                        alt="bank"
                                      />
                                    )}
                                    <p className="withdraw-text white-text">
                                      Bangkok Bank
                                    </p>
                                  </div>
                                  <div
                                    className={
                                      checkbank === "ttb"
                                        ? "deposit-styled-item-bank"
                                        : "deposit-styled-item-bank-active"
                                    }
                                    onClick={() =>
                                      handleChangeActive(!banks[3], "ttb")
                                    }
                                  >
                                    {checkbank === "ttb" ? (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/ttbactive.png"
                                        alt="bank"
                                      />
                                    ) : (
                                      <img
                                        className="style-bank"
                                        src="./images/logo/ttbunactive.png"
                                        alt="bank"
                                      />
                                    )}
                                    <p className="withdraw-text white-text">
                                      TTB
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {showBank && <div className="ft-divider-row"></div>}
                          <div className="af-field-wrap">
                            {showBank && (
                              <>
                                <div className="label-col"></div>
                                <div className="input-col">
                                  <div className="label-col fs-16 fw-700">
                                    Bank Info
                                  </div>
                                </div>
                                <div className="label-col">
                                  Bank Name<span className="redtext">*</span>
                                </div>
                                <Form.Item name="name">
                                  <div className="input-col">
                                    <div className="af-field">
                                      <input
                                        type="text"
                                        name="name"
                                        value={dataForm ? dataForm.name : ""}
                                        id="name"
                                        className="afinput allownumericwithdecimal"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </Form.Item>
                                <div className="label-col">
                                  Account Name<span className="redtext">*</span>
                                </div>
                                <Form.Item name="bank_account_name">
                                  <div className="input-col">
                                    <div className="af-field">
                                      <input
                                        type="text"
                                        value={
                                          dataForm
                                            ? dataForm.bank_account_name
                                            : ""
                                        }
                                        name="bank_account_name"
                                        id="bank_account_name"
                                        className="afinput allownumericwithdecimal"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </Form.Item>
                                <div className="label-col">
                                  Account No.<span className="redtext">*</span>
                                </div>
                                <Form.Item name="bank_account_no">
                                  <div className="input-col">
                                    <div className="af-field">
                                      <input
                                        type="text"
                                        name="bank_account_no"
                                        id="bank_account_no"
                                        value={
                                          dataForm ? dataForm.bank_account_no : ""
                                        }
                                        className="afinput allownumericwithdecimal"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </Form.Item>
                              </>
                            )}
                          </div>
                          <div className="af-field-wrap">
                            {typeBank === "payment gateway" && (
                              <>
                                <div className="label-col">
                                  Bank 2<span className="redtext">*</span>
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <select
                                      className="afinput"
                                      name="depositselectbank"
                                      id="depositselectbank"
                                      onChange={(e) => handlerSelectedBankTHB(e.target.value)}
                                    >
                                      <option value="">{t("SELECT_BANK")}</option>
                                      {bankOnline &&
                                        bankOnline.map((item) => (
                                          <option
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.title}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}
                            {typeBank === "kissvippay" && (
                              <>
                                <div className="label-col">
                                  Bank <span className="redtext">*</span>
                                </div>

                                <div className="styled-group-banks">
                                  <div className="styled-bank">
                                    {kissvipBanks.map((bank, ix) => (
                                      <div
                                        key={ix}
                                        className={
                                          checkbank === bank.name
                                            ? "deposit-styled-item-bank"
                                            : "deposit-styled-item-bank-active"
                                        }
                                        onClick={() => handlerSelected(bank.id)}
                                      >
                                        <img
                                          className="style-bank"
                                          src={bank.logo_url}
                                          alt="bank"
                                        />
                                        <p className="withdraw-text white-text">
                                          {bank.name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                            {typeBank === "kissvippayqr" && (
                              <div className="input-deposit"></div>
                            )}
                            {typeBank === "kissvippayqrtrue" && (
                              <div className="input-deposit"></div>
                            )}
                          </div>

                          {showBank && <div className="ft-divider-row"></div>}
                          <div className="af-field-wrap">
                            {showBank && (
                              <>
                                <div className="label-col"></div>
                                <div className="input-col">
                                  <div className="label-col fs-16 fw-700">
                                    Account Info
                                  </div>
                                </div>
                                <div className="label-col">
                                  Bank Name<span className="redtext">*</span>
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <input
                                      type="text"
                                      name="bankName"
                                      id="bankName"
                                      className="afinput allownumericwithdecimal"
                                      placeholder="Min / Max Limit: 10 / 10,000"
                                      autocomplete="off"
                                      maxlength="8"
                                      disabled
                                      value={user.bankName}
                                    />
                                  </div>
                                </div>
                                <div className="label-col">
                                  Bank Account<span className="redtext">*</span>
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <input
                                      type="text"
                                      name="bankAccount"
                                      id="bankAccount"
                                      className="afinput allownumericwithdecimal"
                                      placeholder="Min / Max Limit: 10 / 10,000"
                                      autocomplete="off"
                                      maxlength="8"
                                      disabled
                                      value={user.bankAccount}
                                    />
                                  </div>
                                </div>
                                <div className="label-col">
                                  Bank Account Number
                                  <span className="redtext">*</span>
                                </div>
                                <div className="input-col">
                                  <div className="af-field">
                                    <input
                                      type="text"
                                      name="bankAccountNumber"
                                      id="bankAccountNumber"
                                      className="afinput allownumericwithdecimal"
                                      placeholder="Min / Max Limit: 10 / 10,000"
                                      autocomplete="off"
                                      maxlength="8"
                                      disabled
                                      value={user.bankAccountNumber}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          {showBank && <div className="ft-divider-row"></div>}
                          <div className="af-field-wrap">
                            <div className="label-col">
                              Amount<span className="redtext">*</span>
                            </div>
                            <div className="input-col">
                              <Form.Item
                                name="amount"
                                style={{ marginBottom: "0" }}
                              >
                                <div className="af-field">
                                  <input
                                    min={min}
                                    max={max}
                                    value={changeAmount}
                                    type="text"
                                    className="afinput allownumericwithdecimal"
                                    placeholder="Min / Max Limit: 10 / 10,000"
                                    autocomplete="off"
                                    maxlength="8"
                                    onChange={(e) =>
                                      handleInputChange(e.target.value)
                                    }
                                  />
                                </div>
                              </Form.Item>
                              <div className="quick-amt-wrap">
                                <button
                                  type="button"
                                  className="qamt-btm setamount cursor-pointer"
                                  onClick={() => handleChangeAmount("50")}
                                >
                                  50
                                </button>
                                <button
                                  type="button"
                                  className="qamt-btm setamount cursor-pointer"
                                  onClick={() => handleChangeAmount("100")}
                                >
                                  100
                                </button>
                                <button
                                  type="button"
                                  className="qamt-btm setamount cursor-pointer"
                                  onClick={() => handleChangeAmount("300")}
                                >
                                  300
                                </button>
                                <button
                                  type="button"
                                  className="qamt-btm setamount cursor-pointer"
                                  onClick={() => handleChangeAmount("500")}
                                >
                                  500
                                </button>
                                <button
                                  type="button"
                                  className="qamt-btm setamount cursor-pointer"
                                  onClick={() => handleChangeAmount("1000")}
                                >
                                  1000
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="af-field-wrap">
                            <div className="label-col">Deposit To</div>
                            <div className="input-col">
                              <div className="af-field">
                                <select
                                  className="afinput"
                                  name="depositspecialrequest"
                                  id="depositspecialrequest"
                                  disabled
                                >
                                  <option value="wallet">Main Wallet</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="af-field-wrap">
                            <div className="label-col">
                              Promo Bonus(T&C Apply)(Optional)
                            </div>
                            <div className="input-col">
                              <div className="af-field">
                                <select
                                  className="afinput"
                                  name="promosBonus"
                                  id="promosBonus"
                                  onChange={(e) => setBonusValue(e.target.value)}
                                >
                                  <option value="-1">
                                    Process withou promo bonus
                                  </option>
                                  {promotions.length > 0 &&
                                    promotions.map((p) => (
                                      <option
                                        value={p.id}
                                        key={p.id}
                                        selected={bonusValue === p.id}
                                      >
                                        {p.appear.title}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="af-field-submit">
                            {showBank && (
                              <>
                                <input
                                  style={{ display: "none" }}
                                  id="uploadBnb"
                                  type="file"
                                  className="input_block input_white"
                                />
                                <button
                                  type="button"
                                  onClick={() => {
                                    document.getElementById("uploadBnb").click();
                                  }}
                                  className="deposit-upload-btn  cursor-pointer"
                                >
                                  <UploadOutlined />
                                  Up Load Receipt
                                </button>
                              </>
                            )}
                            <button
                              type="submit"
                              className="join-reg-btn btndeposit cursor-pointer"
                              disabled={Number(changeAmount || 0) <= 0}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                ) : (
                  <div>
                    System maintenance
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog />
      <ErrorDialog />
    </div>
  );
}

export default NewDepositNewTest;
