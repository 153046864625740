import React, { useEffect, useState } from "react";
import http from "service/http";
import moment from "moment";
import store from "store";
import config from "config";
import { useTranslation } from "react-i18next";
import useLoading from "../loading/hook";
import "./style.css";
import MenuItemBoxLeftMobile from "../../component/layout/BoxLeft/MenuItemBoxLeftMobile";
import MenuItemBoxLeft from "../../component/layout/BoxLeft/MenuItemBoxLeft";
import MainWallet from "../../component/layout/BoxLeft/MainWallet";
import ChangePasswordModal from "../../component/Modal/ChangePasswordModal";

function Account({ history }) {
  const { t } = useTranslation();

  const user = store.get("user");

  const [loading, withLoading] = useLoading(false);

  const [gameBalances, updateGameBalances] = useState([]);

  const [userInfo, setUserInfo] = useState(user);

  const [balance, setBalance] = useState(0);

  const [showChangePwd, setShowChangePwd] = useState(false);

  const fetchAccount = async () => {
    const { user_id: userId } = user;
    const [userResp, gameBalancesResp] = await withLoading(() =>
      Promise.all([
        http.get({ path: "me" }),
        http.get({ path: `users/${userId}/balance-games` }),
      ])
    );
    updateGameBalances(
      gameBalancesResp
        .filter((i) => i)
        .filter(({ game_code: gameCode }) =>
          config.gamesAvailable.includes(gameCode)
        )
    );
    setUserInfo(userResp);

    store.set("user", userResp);

    let bl = userResp.user_money || 0;
    if (bl === 0) {
      gameBalancesResp
        .filter((x) => x.balance.game_code !== "asiagaming")
        .forEach((r) => {
          bl += Number(r.balance.balance || 0);
        });
    }

    store.set("userBalance", bl);

    store.set("userBalanceTime", Date.now());

    setBalance(bl);
  };

  const onPullOut = async () => {
    const { user_id: userId } = store.get("user");
    const tasks = [];
    gameBalances.forEach(({ gameId, balance, game_code }) => {
      if (balance > 1 && game_code !== "asiagaming") {
        const providerCode = game_code === "ag_galaxy" ? "?ProviderId=AG" : "";
        tasks.push(
          http.post({
            path: `users/${userId}/game/${gameId}/withdraw${providerCode}`,
            payload: {
              amount: balance,
            },
          })
        );
      }
    });
    await withLoading(() => Promise.all(tasks));
    fetchAccount();
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  const {
    username,
    fullname,
    mobile_phone: phone,
    email,
    bankName,
    bankAccountNumber,
    created_at: createdAt,
    updated_at: updatedAt,
    user_currency: currency,
  } = user;
  
  return (
    <div className="content-body">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "180px",
            minHeight: "180px",
            textAlign: "center",
            marginTop: "80px",
          }}
          className="pro_title"
        >
          {t("LOADING")} ...
        </div>
      ) : (
        <div className="deposit-sec">
          <MenuItemBoxLeftMobile history={history} _pageName={"Profile"} />
          <div className="af-container">
            <MenuItemBoxLeft history={history} _pageName={"Profile"} promotion={userInfo.promotion}/>
            <div className="afcolumn-right">
              <div className="afinner">
                <MainWallet
                  userInfo={userInfo}
                  balance={balance}
                  onPullOut={onPullOut}
                />
                <div className="frame-container">
                  <div className="aform-wrapper">
                    <div className="profile-top-wrapper">
                      <div className="pf-ftitle">Personal Details</div>
                      <div
                        className="pfrow"
                        style={{
                          display: "grid;grid-template-columns: repeat(2,1fr);",
                        }}
                      >
                        <input type="hidden" name="dest" value="" />
                        <input id="memberFlag" value="2" type="hidden" />
                        <div className="pfcol">
                          <div className="pf-label">User Name:</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={username || "-"}
                              disabled="disabled"
                            />
                          </div>

                          <div className="pf-label">Fullname*:</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={fullname || "-"}
                              disabled="disabled"
                            />
                          </div>

                          <div className="pf-label">{t("EMAIL_ADDRESS")}</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={email || "-"}
                              disabled="disabled"
                            />
                          </div>

                          <div className="pf-label">{t("PHONE")}</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={phone || "-"}
                              disabled="disabled"
                            />
                          </div>
                        </div>
                        <div className="pfcol">
                          <div className="pf-label">{t("MAIN_BALANCE")}</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={currency || "-"}
                              disabled="disabled"
                            />
                          </div>

                          <div className="pf-label">{t("BANK")}</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={bankName || "-"}
                              disabled="disabled"
                            />
                          </div>

                          <div className="pf-label">{t("BANK_NUMBER")}</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={bankAccountNumber || "-"}
                              disabled="disabled"
                            />
                          </div>

                          <div className="pf-label">{t("JOIN_DATE")}</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={moment(createdAt).format("DD/MM/YYYY")}
                              disabled="disabled"
                            />
                          </div>
                          <div className="pf-label">{t("LAST_EDIT")}</div>
                          <div className="af-field">
                            <input
                              type="text"
                              className="profile-input"
                              value={moment(updatedAt).format("DD/MM/YYYY")}
                              disabled="disabled"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pfrow-1">
                      <div className="pfcolumn">
                        <div className="pf-ftitle">Password</div>
                        <div className="pw-note">
                          Between 6 To 12 Alphanumeric (A-Z, a-z, 0-9) Character
                          Only , Password contain at least 1 uppercase, 1
                          lowercase and 1 number
                        </div>
                        <div className="pw-btn-wrap">
                          <button
                            className="update-btn showpasseditmodal cursor-pointer"
                            onClick={() => {
                              setShowChangePwd(true);
                            }}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showChangePwd && (
        <ChangePasswordModal
          isVisible
          onClose={() => setShowChangePwd(false)}
        />
      )}
    </div>
  );
}

export default Account;
