import React, { useState } from "react";
import { Modal, Input, Form } from "antd";
import {
  LockOutlined,
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import "./LoginModal.css";
import { useDispatch, useSelector } from "react-redux";
import useLoading from "../../feature/loading/hook";
import http from "service/http";
import store from "store";
import { useTranslation } from "react-i18next";
import { hideLoginModal } from "../../store/modalSlice";
import InputField from "../textInput/InputField";
import { showNotification } from "../../utils/function";
import { ETypeNotification } from "../../constants/gameData";

const ChangePasswordModal = ({ isVisible, onClose }) => {
  const [loading, withLoading] = useLoading(false);

  if (!isVisible) return null;

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,12}$/;

  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };

  const onSubmit = async (values) => {
    try {
      const { user_id: userId } = store.get("user");
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: { ...values } })
      );
      showNotification(ETypeNotification.SUCCESS, "Change password success!");
    } catch (error) {
      showNotification(ETypeNotification.ERROR, error.message);
    }
    onClose();
  };

  const handleSubmit = (values) => {
    onSubmit({ ...values });
  };

  const [form] = Form.useForm();

  return (
    <Modal
      title={<h2 className="text-white">Change Password</h2>}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      centered
      className="login-modal"
    >
      <Form
        form={form}
        layout="vertical"
        className="form-deposit changepass"
        onFinish={handleSubmit}
      >
        <div className="popup-edit-form login-form">
          <div className="af-field-wrap">
            <div className="label-col">
              Current Password<span className="redtext">*</span>
            </div>
            <div className="input-col">
              <div className="af-field">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  name="oldPassword"
                  style={{ marginBottom: "10px" }}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Current Password"
                    maxLength={"12"}
                    iconRender={(visible) =>
                      !visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="af-field-wrap">
            <div className="label-col">
              New Password<span className="redtext">*</span>
            </div>
            <div className="input-col">
              <div className="af-field">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && validatePassword(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password between 6 To 12 Alphanumeric (A-Z, a-z, 0-9) Character Only , Password contain at least 1uppercase, 1 lowercase and 1 number"
                          )
                        );
                      },
                    }),
                  ]}
                  name="password"
                  style={{ marginBottom: "10px" }}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="New Password"
                    maxLength={"12"}
                    iconRender={(visible) =>
                      !visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="af-field-wrap">
            <div className="label-col">
              Confirm Password<span className="redtext">*</span>
            </div>
            <div className="input-col">
              <div className="af-field">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  name="confirmPassword"
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirm Password"
                    maxLength={"12"}
                    iconRender={(visible) =>
                      !visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="af-field-submit">
            <button
              type="submit"
              className="join-reg-btn updateprofilepassword cursor-pointer"
              disabled={loading}
            >
              Change Password
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
